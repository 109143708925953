import axios from 'axios';
import Settings from 'env';
import { Methods, STATUS } from './http';
import { logout, refreshToken } from 'store/profile/actions';
import { getAuthorizedRoutesForExport as getAuthorizedRoutes, store } from 'client';
import { getToken, isRefreshInProgress, isRefreshNeeded, removeToken, setRefreshInProgress } from './refreshToken';
import Cookies from 'js-cookie';
import { ADD_NOTIFICATION } from 'store/common/types';
import { NotificationTypes } from 'constants/Constants';
import { isBrowser } from './browser';
import { detectLanguage } from './language';
import * as Constants from 'constants/Constants';

export const requestCreator = axios.create({});

requestCreator.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const { response, config } = error || {};

    if (response && config) {
      const { error, message, errors } = response.data;

      if (
        response.status !== STATUS.BAD_REQUEST &&
        response.status !== STATUS.UNAUTHORIZED &&
        message !== Constants.tokenExpired
      ) {
        store.dispatch({
          type: ADD_NOTIFICATION,
          payload: {
            translateKey: error || message || (errors && errors.length ? errors[0] : null),
            type: NotificationTypes.error,
          },
        });
      }

      if (response.status === STATUS.UNAUTHORIZED && isBrowser()) {
        if (!config.url.includes(Settings.LOGIN_RESOURCE)) {
          removeToken();
          window.location.href = window.location.origin + '/' + detectLanguage(window.location.pathname).toLowerCase();
        } else {
          store.dispatch({
            type: ADD_NOTIFICATION,
            payload: {
              translateKey: 'invalid_credentials',
              type: NotificationTypes.error,
            },
          });
        }
      }

      if (response.status === STATUS.NOT_FOUND && isBrowser()) {
        window.location.href =
          window.location.origin + '/' + detectLanguage(window.location.pathname).toLowerCase() + '/404';
      }
    }

    return Promise.reject(error);
  }
);

export default async ({ resource = '/', method = Methods.GET, data, headers = {}, language } = {}) => {
  const url = `${Settings.API_BASE_URL}${resource}`;
  let { token } = getToken();
  if (!token && headers && headers.Authorization) {
    token = headers.Authorization;
  }
  if (!language) {
    language = Cookies.get(Settings.I18NEXT) ? Cookies.get(Settings.I18NEXT).toUpperCase() : 'HU';
  }
  if (token) {
    if (isRefreshNeeded() && resource !== Settings.REFRESH_TOKEN_RESOURCE) {
      try {
        const waitUntilRefresh = async () => {
          await new Promise((resolve) => setTimeout(() => resolve(), 500));
          if (isRefreshInProgress()) {
            await waitUntilRefresh();
          }
        };
        if (isRefreshInProgress()) {
          await waitUntilRefresh();
        } else {
          setRefreshInProgress(true);
          await store.dispatch(refreshToken(token));
          token = getToken() ? getToken().token : null;
          setRefreshInProgress(false);
        }
      } catch (err) {
        setRefreshInProgress(false);
        await store.dispatch(logout(getAuthorizedRoutes));
        throw err;
      }
    }

    headers['Authorization'] = 'Bearer ' + token;
  }

  headers['Accept-Language'] = language;
  headers['platform'] = 'web';

  try {
    const { data: response } = await requestCreator.request({
      method,
      url,
      data,
      headers,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

import makeStyles from '@material-ui/core/styles/makeStyles';
import { flex, flexStart } from 'constants/Jss';

export const useMandatoryLinksStyles = makeStyles((theme) => ({
  MandatoryLinkBox: {
    display: flex,
    alignItems: flexStart,
    maxWidth: 500,
    '& label': {
      marginRight: 0,
    },
  },
  Link: {
    color: theme.palette.white,
  },
  Checkbox: {
    marginRight: theme.spacing(3),
    color: `${theme.palette.whiteHover} !important`,
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      '& svg': {
        fill: `${theme.palette.default.dark} !important`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
    },
  },
}));

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Box, Container, Grid } from '@material-ui/core';

import { useDesktopFilterStyles } from './styles';
import { TagFilter } from './TagFilter';
import { FiltersType, Tag } from 'types/types';
import { DurationFilter } from './DurationFilter';
import { ExamsFilter } from './ExamsFilter';
import { ArrowDownIcon, FilterIcon } from 'assets/icons';
import { SearchForm, FilterDialog } from 'components';

export const DesktopFilter = ({
  search,
  tags,
  filters,
  setFilters,
  filterClass,
  filterBoxClass,
  profileTags,
  isLoading,
  showFilters = ['tag', 'duration', 'exams'],
  setCloseExamFilter,
  wide,
}) => {
  const classes = useDesktopFilterStyles();
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState(filters);
  setCloseExamFilter && setCloseExamFilter(() => setOpenFilter(null));

  const filterAnchorRefs = {
    [t('filter_tag')]: React.useRef(null),
    [t('filter_duration')]: React.useRef(null),
    [t('filter_exams')]: React.useRef(null),
  };

  const handleFilterToggle = (label) => {
    setOpenFilter((prevOpenFilter) => (prevOpenFilter === label ? null : label));
  };

  const handleFilterClose = (event) => {
    if (
      filterAnchorRefs[openFilter] &&
      filterAnchorRefs[openFilter].current &&
      filterAnchorRefs[openFilter].current.contains(event.target)
    ) {
      return;
    }
    setOpenFilter(null);
    setFilterValues(filters);
  };

  const changeFilterValues = (filter) => {
    const nextFilter = { ...filterValues, ...filter };
    setFilterValues({ ...filterValues, ...filter });
    return nextFilter;
  };

  const triggerFilter = (filter = filterValues) => {
    setFilters(filter);
  };

  const changeFilterValuesAndTrigger = (filter) => {
    const nextFilter = changeFilterValues(filter);
    triggerFilter(nextFilter);
  };

  const onFilterButtonClick = (e) => {
    triggerFilter();
    setOpenFilter(null);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpenFilter = React.useRef(openFilter);
  React.useEffect(() => {
    if (!!prevOpenFilter.current && !openFilter) {
      filterAnchorRefs[openFilter] &&
        filterAnchorRefs[openFilter].current &&
        filterAnchorRefs[openFilter].current.focus();
    }

    prevOpenFilter.current = openFilter;
  }, [openFilter]);

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  return (
    <Container className={classNames(classes.Filters, filterClass)}>
      <Grid container alignItems="center">
        <Grid item sm={6}>
          {!search ? null : (
            <SearchForm
              isLoading={isLoading}
              search={search}
              onClearInput={() => search({ searchString: '' })}
              buttonLabel={t('get_started_search_submit')}
              inputLabel={t('home_page_what_to_learn')}
            />
          )}
        </Grid>
        <Grid item sm={6}>
          <Box className={classNames(classes.FilterBoxContainer, filterBoxClass, 'FilterBoxContainer')}>
            <Box ml="auto">{t('common_filter')}</Box>
            {showFilters.includes('tag') && (
              <Box ml={4} mr={2}>
                <FilterDialog
                  menuId={t('filter_tag')}
                  anchorRef={filterAnchorRefs[t('filter_tag')]}
                  icon={<FilterIcon />}
                  label={t('filter_tag')}
                  content={
                    <TagFilter
                      tags={tags}
                      filter={filterValues.tags}
                      setFilters={changeFilterValues}
                      profileTags={profileTags}
                    />
                  }
                  open={openFilter === t('filter_tag')}
                  handleToggle={handleFilterToggle}
                  handleClose={handleFilterClose}
                  onFilterButtonClick={onFilterButtonClick}
                  activeFilters={filters.tags.length}
                  tKey="tag"
                  wide={wide}
                />
              </Box>
            )}
            {showFilters.includes('duration') && (
              <Box ml={2} mr={2}>
                <FilterDialog
                  menuId={t('filter_duration')}
                  anchorRef={filterAnchorRefs[t('filter_duration')]}
                  icon={<ArrowDownIcon />}
                  label={t('filter_duration')}
                  content={<DurationFilter filter={filterValues.duration} setFilters={changeFilterValuesAndTrigger} />}
                  open={openFilter === t('filter_duration')}
                  handleToggle={handleFilterToggle}
                  handleClose={handleFilterClose}
                  activeFilters={filterValues.duration ? 1 : 0}
                  small
                  tKey="duration"
                />
              </Box>
            )}

            {showFilters.includes('exams') && (
              <Box ml={2} mr={1}>
                <FilterDialog
                  menuId={t('filter_exams')}
                  anchorRef={filterAnchorRefs[t('filter_exams')]}
                  icon={<ArrowDownIcon />}
                  label={t('filter_exams')}
                  content={<ExamsFilter filter={filterValues.examDone} setFilters={changeFilterValuesAndTrigger} />}
                  open={openFilter === t('filter_exams')}
                  handleToggle={handleFilterToggle}
                  handleClose={handleFilterClose}
                  activeFilters={filterValues.examDone !== null ? 1 : 0}
                  small
                  tKey="exams"
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

DesktopFilter.propTypes = {
  search: PropTypes.func,
  tags: PropTypes.arrayOf(Tag),
  filters: FiltersType,
  setFilters: PropTypes.func,
  filterBoxClass: PropTypes.string,
  profileTags: PropTypes.arrayOf(Tag),
  isLoading: PropTypes.bool,
  showFilters: PropTypes.arrayOf(PropTypes.oneOf(['tag', 'duration', 'exams'])),
  setCloseExamFilter: PropTypes.func,
  wide: PropTypes.bool,
};

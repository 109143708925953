import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

import { Course } from 'types/types';
import { getPath } from 'utils/getPath';
import { isBrowser } from 'utils/browser';
import { removeCommonLoader } from 'store/loader/actions';
import { getProfileDetails } from 'store/profile/actions';
import { getRandomQuote } from 'store/quotes/actions';
import HomeJumbotron from './HomeJumbotron/HomeJumbotron';
import SLTCardsContainer from './SLTCardsContainer/SLTCardsContainer';
import OnYourSchedule from './OnYourSchedule/OnYourSchedule';
import NewSkills from './NewSkills/NewSkills';
import Learning from './Learning/Learning';
import Quote from './Quote/Quote';
import LetsTalk from './LetsTalk/LetsTalk';
import PopularLearningTopics from './PopularLearningTopics/PopularLearningTopics';
import ImportantCoursesContainer from './ImportantCoursesContainer/ImportantCoursesContainer';
import SurpriseMeContainer from './SurpriseMeContainer/SurpriseMeContainer';
import { SLTTitle, withLoading } from 'components';
import Settings from 'env';

import { homePageStyles } from './styles';

class HomePage extends Component {
  static propTypes = {
    pages: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    fetchWithLoading: PropTypes.func.isRequired,
    popularCourses: PropTypes.arrayOf(Course),
    isLoggedIn: PropTypes.func.isRequired,
    getRandomQuote: PropTypes.func.isRequired,
    id: PropTypes.number,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
    logout: PropTypes.func,
  };

  static async getInitialData({ dispatch, language, token }) {
    if (token) {
      try {
        await dispatch(getRandomQuote());
      } catch (err) {}
    }
    if (isBrowser()) {
      dispatch(removeCommonLoader());
    }
  }

  scrollToSection(sectionId) {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: Settings.SCROLL_BEHAVIOR });
    }
  }

  scrollToSectionByHash() {
    const { history } = this.props;
    const hashValue = (history && history.location && history.location.hash) || '';
    this.scrollToSection(hashValue.substring(1));
    history.replace({ hash: null });
  }

  async componentDidMount() {
    const { addCommonLoader } = this.props;
    addCommonLoader();
  }

  async componentDidUpdate(prevProps) {
    const { id, getRandomQuote, history, fetchWithLoading } = this.props;
    if (history.location.hash) {
      this.scrollToSectionByHash();
    }

    if (!prevProps.id && id) {
      fetchWithLoading(async () => {
        await getRandomQuote();
      });
    }
  }

  render() {
    const { t, history, isLoggedIn } = this.props;
    const loggedIn = isLoggedIn();
    return (
      <>
        <HomeJumbotron search={this.search} isLoggedIn={loggedIn} />
        <Box mt={8}>
          <SLTCardsContainer />
        </Box>
        <Box id={Settings.MOBILE_APP_ID} pt={10}>
          <OnYourSchedule />
        </Box>
        <SLTTitle id={Settings.ENTERPRISE_SECTION_ID}>{t('slt_solution_title')}</SLTTitle>
        <Box mb={8}>
          <NewSkills isLoggedIn={loggedIn} onButtonClick={() => this.navigateToLetsTalk()} />
        </Box>
        <Box mb={8}>
          <Learning isLoggedIn={loggedIn} onButtonClick={() => this.navigateToLetsTalk()} />
        </Box>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(homePageStyles)(withLoading(HomePage))), HomePage);

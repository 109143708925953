import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { Typography } from 'components';
import classNames from 'classnames';

import { useSLTCardStyles } from './styles';

const SLTCard = ({ title, description, image }) => {
  const classes = useSLTCardStyles();
  return (
    <Box className={classes.SLTCardBox}>
      <img src={image} className={classes.Media} />
      <CardContent>
        <Typography variant="h5" component="h2" color="textSecondary">
          {title}
        </Typography>
        <Typography
          className={classNames(classes.Description, 'Grey')}
          variant="body1"
          component="p"
          lineHeight="medium"
        >
          {description}
        </Typography>
      </CardContent>
    </Box>
  );
};
export default SLTCard;

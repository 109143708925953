import { v4 as uuidv4 } from 'uuid';

import popularCourse1 from 'assets/images/popular_learning_topics_1.png';
import popularCourse2 from 'assets/images/popular_learning_topics_2.png';
import popularCourse3 from 'assets/images/popular_learning_topics_3.png';
import popularCourse4 from 'assets/images/popular_learning_topics_4.png';

export const popularCourses = [
  {
    id: 0,
    title:
      'Hosszú cím is lehet itt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    image: popularCourse1,
    lessonsDone: 50,
    lessonCount: 154,
    totalMinutesToComplete: 2,
    isBookmarked: true,
    tags: [
      { id: uuidv4(), title: 'Tag' },
      { id: uuidv4(), title: 'Tag' },
    ],
  },
  {
    id: 1,
    title:
      'Hosszú cím is lehet itt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    lessonCount: 154,
    totalMinutesToComplete: 2,
    image: popularCourse2,
    progress: 0.2,
    examSuccess: true,
    isBookmarked: true,
    tags: [
      { id: uuidv4(), title: 'Tag' },
      { id: uuidv4(), title: 'Tag' },
    ],
  },
  {
    id: 2,
    title:
      'Hosszú cím is lehet itt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    lessonCount: 154,
    image: popularCourse3,
    totalMinutesToComplete: 2,
    isBookmarked: true,
    tags: [
      { id: uuidv4(), title: 'Tag' },
      { id: uuidv4(), title: 'Tag' },
    ],
  },
  {
    id: 3,
    title:
      'Hosszú cím is lehet itt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    lessonCount: 154,
    totalMinutesToComplete: 2,
    image: popularCourse4,
    isBookmarked: true,
    tags: [
      { id: uuidv4(), title: 'Tag' },
      { id: uuidv4(), title: 'Tag' },
    ],
  },
];

export const ModalTypes = {
  delete: 'delete',
  user: 'user',
};

export const TableRowsPerPage = 5;

export const MaxSkeletonCard = 4;

export const Roles = {
  Admin: 'admin',
  User: 'user',
};

export const MaxTopics = 4;

export const NotificationTypes = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
};

export const TAG_STRING = 'tag';
export const DURATION_STRING = 'duration';
export const EXAM_DONE_STRING = 'examDone';

export const SearchTypes = {
  none: 0,
  tag: 1,
  organization: 2,
};

export const Regex = {
  Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,24}$/,
  PHONE_PATTERN: /^$|[+][(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/,
};

export const ScrollToTopVisible = 300;
export const StickyTimeVisibleAt = 110;

export const SocialLinks = {
  facebook: 'https://www.facebook.com/Sweet-Lemon-Training-993987847457331/',
  twitter: 'https://twitter.com/',
  instagram: 'https://www.instagram.com/sweetlemontraining/',
};

export const FooterLinks = {
  googlePlaySLT: 'https://play.google.com/store/apps/details?id=com.attrecto.boutiqbar',
  appStoreSLT: 'https://apps.apple.com/hu/app/sweet-lemon-training/id1487934616',
  slt: 'https://admin.sweetlemontraining.com/',
  addressMapLink:
    'https://www.google.com/maps/place/Budapest,+Kacsa+u.+15,+1027/@47.5100559,19.0347333,17z/data=!3m1!4b1!4m5!3m4!1s0x4741dc1b6247b86d:0x12a21d34087c8ef0!8m2!3d47.5100559!4d19.036922',
};

export const LessonContentTypes = {
  SHORTTEXT: 0,
  LONGTEXT: 1,
  VIDEO: 2,
  IMAGE: 3,
};

export const QuestionTypes = {
  SINGLE_CHOICE: 0,
  MULTIPLE_CHOICE: 1,
  PUZZLE: 2,
};

export const YoutubeUrlPrefix = 'https://www.youtube.com/embed/';

export const visiblePageLinks = 5;

export const ItemTypes = {
  Course: 'course',
  Lesson: 'lesson',
  Path: 'path',
};

export const SnackTime = 3000;

export const GroupTypes = {
  UNDEFINED: 0,
  KKV: 1,
};

export const SystemRoles = {
  ADMIN: 1,
  MANAGER: 2,
  CONTENT_MANAGER: 3,
  VALIDATOR: 4,
  USER: 5,
};

export const TagFilterSize = {
  Wide: 816,
  Narrow: 520,
};

export const MinDesktopWidth = 1139.98;

export const tokenExpired = 'Token has expired and can no longer be refreshed';

export const COURSE_ID_FOR_LESSON_WITHOUT_COURSES = -1;

export const TIME_TRACKING_SEND_INTERVAL_IN_SEC = 60;

export const ITEM_PER_PAGE = 15;

export const VISIBLE_PAGE_LINKS = 5;

export const ITEM_TYPES = {
  MARKER: 'marker',
  COURSE: 'course',
  LESSON: 'lesson',
};

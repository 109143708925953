import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import i18 from 'i18n';

import { useCheckboxFilterStyles } from '../styles';

export const DURATION_VALUES = {
  filter_max_10: { minutesMax: 10 },
  filter_max_15: { minutesMax: 15 },
  filter_max_20: { minutesMax: 20 },
  filter_min_21: { minutesMin: 21 },
};

export const getDurationLabel = (key) => {
  const value = DURATION_VALUES[key];

  return value.minutesMax
    ? i18.t('filter_max_minute', { minute: value.minutesMax })
    : i18.t('filter_min_minute', { minute: value.minutesMin });
};

export const DurationFilter = ({ filter, setFilters }) => {
  const classes = useCheckboxFilterStyles();
  const { t } = useTranslation();

  const onChange = (durationKey) => {
    setFilters({ duration: isEqual(filter, DURATION_VALUES[durationKey]) ? null : DURATION_VALUES[durationKey] });
  };

  return (
    <div className={classes.CheckboxFilterContainer}>
      {Object.keys(DURATION_VALUES).map((key) => (
        <FormControlLabel
          key={key}
          control={<Checkbox checked={isEqual(filter, DURATION_VALUES[key])} onChange={() => onChange(key)} />}
          label={getDurationLabel(key)}
          aria-label={t(`alt_${key}`)}
        />
      ))}
    </div>
  );
};

DurationFilter.propTypes = {
  filter: PropTypes.shape({
    minutesMin: PropTypes.number,
    minutesMax: PropTypes.number,
  }),
  setFilters: PropTypes.func,
};

import { GET_PROVINCES, GET_PROVINCES_FAILED, GET_PROVINCES_SUCCESS } from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  provinces: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROVINCES:
      return {
        ...state,
        loading: true,
      };
    case GET_PROVINCES_SUCCESS:
      return {
        ...state,
        loading: false,
        provinces: action.payload,
      };
    case GET_PROVINCES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';

import { LottieAnimation } from 'components';
import logoAnimationData from 'assets/animation/logoAnimation.json';
import { SizeProps } from './lottieSizeProps';

const LoginAnimation = ({ ...props }) => <LottieAnimation animationData={logoAnimationData} {...props} />;

LoginAnimation.propTypes = {
  size: SizeProps,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
};

LoginAnimation.defaultProps = {
  size: 'lg',
  loop: false,
  autoplay: true,
};

export default LoginAnimation;

import { getUrl } from './urlFromTitle';
import Page from 'components/Page/Page';
import TopicsPage from 'modules/TopicsPage';
import withSSR from './withSSR';

export const getTopicsRoute = (title) => ({
  path: `/hu/${getUrl(title)}`,
  component: withSSR(Page(TopicsPage, 'TopicsPage', title)),
  linkName: title,
  exact: true,
});

export const getTopicsRoutes = (topics) => {
  return topics.map((topic) => getTopicsRoute(topic.title));
};

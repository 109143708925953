export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_TAGS_FAILED = 'GET_TAGS_FAILED';

export const GET_PROFILE_TAGS = 'GET_PROFILE_TAGS';
export const GET_PROFILE_TAGS_SUCCESS = 'GET_PROFILE_TAGS_SUCCESS';
export const GET_PROFILE_TAGS_FAILED = 'GET_PROFILE_TAGS_FAILED';

export const ADD_PROFILE_TAGS = 'ADD_PROFILE_TAGS';
export const ADD_PROFILE_TAGS_FAILED = 'ADD_PROFILE_TAGS_FAILED';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import moment from 'moment';

import { BACKEND_ERRORS } from 'config/backendErrorMessages';
import LoginDialog from './LoginDialog';
import RegisterDialog from './RegisterDialog';
import { MobileHeader } from './MobileHeader';
import { DesktopHeader } from './DesktopHeader';
import { Document, Group, Profile, Settlement } from 'types/types';
import { getPath as getPathFromUtils } from 'utils/getPath';
import { NotificationTypes } from 'constants/Constants';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import ResetPasswordDialog from './ResetPasswordDialog';
import AcceptNewTermsDialog from 'components/AcceptNewTermsDialog/AcceptNewTermsDialog';
import { detectLanguage } from 'utils/language';
import { isBrowser } from 'utils/browser';
import Settings from 'env';
import SuccessRegistrationDialog from './SuccessRegistrationDialog';

export const Header = ({
  pages,
  history,
  login,
  logout,
  register,
  groupSearch,
  groups,
  getProvinces,
  provinces,
  isLoadingAuth,
  isLoadingProvinces,
  isLoggedIn,
  topics,
  profile,
  showNotification,
  removeNotification,
  forgotPassword,
  resetPassword,
  getAuthorizedRoutes,
  setFilters,
  newDocuments,
}) => {
  const { t } = useTranslation();

  const [openModalType, setOpenModalType] = React.useState('');
  const [isVerify, setVerify] = React.useState(false);
  const [isNewUser, setNewUser] = React.useState(false);
  const [token, setToken] = React.useState('');

  useEffect(() => {
    getProvinces();
  }, []);

  if (newDocuments && isLoggedIn()) {
    const getUpdatedDocuments = async () => {
      if (isBrowser() && window.location.search !== `?dialog=${t('url_new_documents')}`) {
        history.push(`${history.location.pathname}?dialog=${t('url_new_documents')}`);
      }
    };
    getUpdatedDocuments();
  }

  const params = queryString.parse(history.location.search);
  if (params.token && !token) {
    setToken(params.token);
  }
  if (params.dialog !== openModalType) {
    setOpenModalType(params.dialog);
  }
  if (params.verify && !isVerify) {
    setVerify(true);
  }
  if (params['new-user'] === 'true' && !isNewUser) {
    setNewUser(true);
  }

  const onLogoClick = () => {
    history.push(getPath(t('menu_item_home')));
  };

  const search = async ({ searchString, isClear }) => {
    if (searchString.length > 0) {
      history.push({
        pathname: getPath(t('common_search')),
        search: `?${t('url_search_term')}=${searchString}`,
      });
    }
    if (isClear && window && window.location && window.location.pathname.includes(getPath(t('common_search')))) {
      history.push({
        pathname: getPath(t('common_search')),
      });
      await setFilters({ searchString: null });
    }
  };

  const getPath = (linkName) => getPathFromUtils(pages, linkName);

  const getPathFromRoutes = (routes, linkName) => getPathFromUtils(routes, linkName);

  const postLogin = async ({ email, password }) => {
    const { firstLogin, routes } = await login({ email, password }, getAuthorizedRoutes);

    closeDialog();
    if (firstLogin) {
      history.push(getPathFromRoutes(routes, t('menu_item_profile')) + `?dialog=${t('url_first_login')}`);
    } else if (isLoggedIn()) {
      // in the case of first login, we already show a popup on the profile page
      // TODO: Not needed right now, but may be needed later
      // await checkDocuments();
    }
  };

  const handleGroupSearch = async (searchText) => {
    return await groupSearch(searchText);
  };

  const postRegistration = async ({ birthDate, province, ...values }) => {
    try {
      delete values.state;
      await register({
        ...values,
        birthDate: moment(birthDate).format(Settings.DATE_FORMAT),
        provinceId: province && province.id,
      });
      history.push(`${history.location.pathname}?dialog=${t('url_success_registration')}`);
    } catch (e) {
      if (e && e.response && e.response.data && Array.isArray(e.response.data.errors)) {
        if (e.response.data.errors.includes(BACKEND_ERRORS.email_taken)) {
          showNotification({ translateKey: 'error_unique_email_address', type: NotificationTypes.error });
        }
      }
    }
  };

  const postForgotPassword = async ({ email }) => {
    closeDialog();
    const success = await forgotPassword(email);
    if (success) {
      showNotification({ translateKey: 'forgot_password_confirm', type: NotificationTypes.success });
    }
  };

  const postResetPassword = async ({ token, password }) => {
    closeDialog();
    const success = await resetPassword({ token, password });
    if (success) {
      showNotification({ translateKey: 'reset_password_success', type: NotificationTypes.success });
      history.push(`${history.location.pathname}?dialog=${t('url_login')}`);
    }
  };

  // TODO uncomment if has docuemnts
  // const putDocuments = async () => {
  //   try {
  //     await agreeDocuments({
  //       termsAndConditionsId: documents.termsAndConditions.id,
  //       privacyPolicyId: documents.privacyPolicy.id,
  //     });
  //     closeDialog();
  //   } catch (error) {
  //     closeDialogAndLogout();
  //   }
  // };

  const closeDialog = () => {
    history.push(`${history.location.pathname}`);
  };

  const closeSuccessDialog = () => {
    history.push(`${history.location.pathname}#mobile-app`);
  };

  const closeDialogAndLogout = () => {
    closeDialog();
    history.push(`/${detectLanguage(window.location.pathname).toLowerCase()}`);
    logout(getAuthorizedRoutes);
  };

  return (
    <>
      {/* <MobileHeader
        onLogoClick={onLogoClick}
        getPath={getPath}
        isLoggedIn={isLoggedIn}
        search={search}
        history={history}
        topics={topics}
        profile={profile}
        logout={() => logout(getAuthorizedRoutes)}
      /> */}
      <DesktopHeader
        history={history}
        getPath={getPath}
        isLoggedIn={isLoggedIn}
        search={search}
        onLogoClick={onLogoClick}
        topics={topics}
        profile={profile}
        logout={() => logout(getAuthorizedRoutes)}
      />
      <RegisterDialog
        open={openModalType === t('url_register')}
        closeDialog={closeDialog}
        submit={postRegistration}
        groupSearch={handleGroupSearch}
        groups={groups}
        provinces={provinces}
        isLoadingAuth={isLoadingAuth}
        isLoadingProvinces={isLoadingProvinces}
        showNotification={showNotification}
        removeNotification={removeNotification}
      />
      <SuccessRegistrationDialog
         open={openModalType === t('url_success_registration')}
         closeDialog={closeSuccessDialog}
      />
    </>
  );
};

Header.propTypes = {
  pages: PropTypes.array.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  groupSearch: PropTypes.func,
  getProvinces: PropTypes.func,
  getProfileDetails: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
  isLoadingProvinces: PropTypes.bool,
  isLoadingAuth: PropTypes.bool,
  history: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  profile: Profile,
  groups: PropTypes.arrayOf(Group),
  provinces: PropTypes.arrayOf(Settlement),
  showNotification: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  getAuthorizedRoutes: PropTypes.func,
  checkDocuments: PropTypes.func,
  getDocuments: PropTypes.func,
  agreeDocuments: PropTypes.func,
  documents: PropTypes.shape({
    termsAndConditions: Document,
    privacyPolicy: Document,
  }),
  newDocuments: PropTypes.bool,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Box, Container, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { Button, Typography } from 'components';
import { TickWithCircleIcon } from 'assets/icons';
import ImageAndContentBox from '../ImageAndContentBox/ImageAndContentBox';
import learningImage from 'assets/images/couple-in-restaurant.jpg';

import { useLearningStyles } from './styles';

const Learning = ({ isLoggedIn, onButtonClick }) => {
  const { t } = useTranslation();
  const classes = useLearningStyles();

  const getLearningListItem = (textToBeTranslated) => (
    <ListItem disableGutters alignItems="flex-start">
      <ListItemIcon>
        <TickWithCircleIcon fontSize="large" color="secondary" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography className={classNames('Grey')} variant="body1" lineHeight="medium">
            {t(textToBeTranslated)}
          </Typography>
        }
        disableTypography
      />
    </ListItem>
  );
  return (
    <Container className={classes.LearningContainer} maxWidth="lg">
      <ImageAndContentBox
        background={`url("${learningImage}")`}
        contentComponent={
          <Box>
            <Typography className={classes.LearningTitle} variant="h2" color="textSecondary">
              {t('learning_box_title')}
            </Typography>
            <Box>
              <List>
                {getLearningListItem('learning_box_description_item1')}
                {getLearningListItem('learning_box_description_item2')}
                {getLearningListItem('learning_box_description_item3')}
                {getLearningListItem('learning_box_description_item4')}
              </List>
            </Box>
          </Box>
        }
      />
    </Container>
  );
};

Learning.propTypes = {
  isLoggedIn: PropTypes.bool,
  onButtonClick: PropTypes.func,
};

export default Learning;

import { combineReducers } from 'redux';

import auth from './auth/reducers';
import common from './common/reducers';
import courses from './courses/reducers';
import dashboard from './dashboard/reducers';
import documents from './documents/reducers';
import exams from './exams/reducers';
import groups from './groups/reducers';
import lessons from './lessons/reducers';
import loader from './loader/reducers';
import profile from './profile/reducers';
import provinces from './provinces/reducers';
import tags from './tags/reducers';
import tests from './tests/reducers';
import topics from './topics/reducers';
import topList from './topList/reducers';
import quote from './quotes/reducers';

export default combineReducers({
  auth,
  common,
  courses,
  dashboard,
  documents,
  exams,
  groups,
  lessons,
  loader,
  profile,
  provinces,
  tags,
  tests,
  topics,
  topList,
  quote,
});

import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/dashboard';

export const getDashboard = async (language) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}`,
    language,
  });
  return Promise.resolve([{ id: 1, title: '', description: '', languageId: '' }]);
};

import makeStyles from '@material-ui/core/styles/makeStyles';
export const useTextFieldStyles = makeStyles((theme) => {
  return {
    BaseTextField: {
      '& .MuiTextField-root': {
        '& .MuiInput-underline:before': {
          borderBottom: '1px solid',
          borderBottomColor: theme.palette.primary.main,
          opacity: '0.42',
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    fullWidth: {
      width: '100%',
    },
    EdgyTextField: {
      '& .MuiTextField-root': {
        borderRadius: 5,
      },

      '& .MuiOutlinedInput-root': {
        borderRadius: 5,
      },
    },
    WhiteTextField: {
      '& .MuiTextField-root': {
        backgroundColor: theme.palette.common.white,
        caretColor: theme.palette.common.black,
        color: theme.palette.grey70,
        '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus,': {
          caretColor: theme.palette.common.black,
          '-webkit-text-fill-color': theme.palette.grey70,
          '-webkit-box-shadow': `0 0 0px 1000px ${theme.palette.common.white} inset`,
        },
        '& :hover': {
          borderColor: theme.palette.common.black,
        },
      },
      '& .MuiInputBase-root': {
        color: theme.palette.grey70,
      },
      '& .MuiInputLabel-root': {
        color: theme.palette.grey70,
      },
      '& .MuiInputLabel-shrink': {
        color: theme.palette.grey70,
        backgroundColor: theme.palette.common.white,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        borderRadius: theme.spacing(1),
      },
    },
    WhiteErrorMessage: {
      '&.Mui-error': {
        color: theme.palette.common.white,
      },
    },
  };
});

import makeStyles from '@material-ui/core/styles/makeStyles';
import { column, flex, none, pointer, relative } from 'constants/Jss';

export const useCardStyles = makeStyles((theme) => ({
  BasicCard: {
    cursor: pointer,
    position: relative,
    borderRadius: 10,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    backgroundColor: theme.palette.white,
    height: '100%',
    display: flex,
    flexDirection: column,
  },
  DesktopCard: {
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileCard: {
    padding: theme.spacing(1),
    display: flex,
    flexDirection: column,
    cursor: pointer,
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  BasicInfoContainer: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1),
    display: flex,
    flexDirection: column,
    width: '100%',
    flexGrow: 1,
  },
  DesktopInfoContainer: {},
  MobileInfoContainer: {
    padding: theme.spacing(0.5),
  },
}));

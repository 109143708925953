import React from 'react';
import { useStopwatch } from 'hooks/useStopwatch';

function withStopwatch(Component) {
  return function WrappedComponent(props) {
    const { timer, start, pause, reset, isActive, isPaused } = useStopwatch();
    return (
      <Component
        {...props}
        stopwatchTimer={timer}
        startStopwatch={start}
        pauseStopwatch={pause}
        resetStopwatch={reset}
        isStopwatchActive={isActive}
        isStopwatchPaused={isPaused}
      />
    );
  };
}

export default withStopwatch;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/app';
import 'firebase/auth';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { useCommonStyles } from 'styles/common';
import { AppleIcon } from 'assets/icons';
import { GoogleIcon } from 'assets/icons';

const googleProvider = new firebase.auth.GoogleAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');

const providers = {
  google: 'google',
  apple: 'apple',
};

const useStyles = makeStyles((theme) => ({
  FirebaseLoginButton: {
    cursor: 'pointer',
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
  },
}));

const FirebaseLogin = ({ onSuccess, onFailure, provider }) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();

  const firebaseLogin = async () => {
    try {
      const loginProvider =
        provider === providers.google ? googleProvider : provider === providers.apple ? appleProvider : null;

      if (loginProvider) {
        const response = await firebase.auth().signInWithPopup(loginProvider);

        if (onSuccess) {
          onSuccess(response);
        }
      }
    } catch (error) {
      if (onFailure) {
        onFailure(error);
      }
    }
  };

  return (
    <Box onClick={firebaseLogin} className={classNames(commonClasses.SocialLoginButton, classes.FirebaseLoginButton)}>
      {provider === providers.google ? (
        <>
          <GoogleIcon useOriginalColors />
          {t('form_login_with_google')}
        </>
      ) : provider === providers.apple ? (
        <>
          <AppleIcon />
          {t('form_login_with_apple')}
        </>
      ) : null}
    </Box>
  );
};

FirebaseLogin.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  provider: PropTypes.oneOf([providers.google, providers.apple]),
};

export default FirebaseLogin;

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useAbstractPathDnDContainerStyles = makeStyles((theme) => ({
  PathItemsDroppableArea: {
    height: '100%',
    overflowY: 'auto',
    maxHeight: 930,
    '&::-webkit-scrollbar': {
      width: 10,
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'lightgray',
      borderRadius: 10,
    },
  },
  ShowError: {
    border: '1px solid red',
  },
}));

import * as DashboardService from 'services/dashboard';
import { GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILED } from './types';

export const getDashboard = (language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DASHBOARD });

      const dashboard = await DashboardService.getDashboard(language);

      dispatch({
        type: GET_DASHBOARD_SUCCESS,
        payload: dashboard,
      });

      return dashboard;
    } catch (error) {
      dispatch({ type: GET_DASHBOARD_FAILED });
      throw error;
    }
  };
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { MandatoryLinks } from 'components/MandatoryLinks/MandatoryLinks';
import FormHelperText from '@material-ui/core/FormHelperText';

const AcceptTermsField = ({
  name,
  value,
  onChange,
  setFieldTouched,
  errors,
  touched,
  showNotification,
  termsAndConditions,
  privacyPolicy,
}) => {
  const { t } = useTranslation();

  const fieldName = name || 'acceptTerms';
  const error = !!errors[fieldName] ? errors[fieldName] : '';
  const isTouched = !!(touched || {})[fieldName];

  return (
    <>
      <MandatoryLinks
        fieldName={fieldName}
        fieldValue={value}
        onChange={onChange}
        setFieldTouched={setFieldTouched}
        error={error}
        showNotification={showNotification}
        parts={[
          { text: t('form_accept_terms_part1') },
          { text: t('form_accept_terms_part2'), link: termsAndConditions.url },
          { text: t('form_accept_terms_part3') },
          { text: t('form_accept_terms_part4'), link: privacyPolicy.url },
          { text: '.' },
        ]}
      />
      {error && isTouched ? <FormHelperText error>{error}</FormHelperText> : ''}
    </>
  );
};

export default AcceptTermsField;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';

export default (WrappedComponent) => {
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
      {
        addCommonLoader,
        removeCommonLoader,
      },
      dispatch
    );
  };

  class WithLoading extends React.Component {
    static propTypes = {
      addCommonLoader: PropTypes.func,
      removeCommonLoader: PropTypes.func,
    };

    fetchWithLoading = async (request = async () => ({})) => {
      const { addCommonLoader, removeCommonLoader } = this.props;
      try {
        addCommonLoader();
        return await request();
      } catch (e) {
        throw e;
      } finally {
        removeCommonLoader();
      }
    };

    render() {
      return <WrappedComponent {...this.props} fetchWithLoading={this.fetchWithLoading} />;
    }
  }

  return connect(null, mapDispatchToProps)(WithLoading);
};

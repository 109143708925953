import makeStyles from '@material-ui/core/styles/makeStyles';

export const useNewSkillsStyles = makeStyles((theme) => ({
  NewSkillsContainer: { paddingTop: theme.spacing(3.5) },
  NewSkillsTitle: { paddingBottom: theme.spacing(3) },
  NewSkillsDescription: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
    },
  },
}));

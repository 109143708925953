import makeStyles from '@material-ui/core/styles/makeStyles';

export const useSLTSectionStyles = makeStyles((theme) => ({
  SectionBox: {
    height: '100%',
  },
  SectionChildrenBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  SectionContent: {
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  SectionContentCenter: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  SectionRightContent: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: theme.spacing(3),
    },
  },
}));

import moment from 'moment';

export default {
  API_BASE_URL: 'https://api.slt-dev.attrecto-dev.com/api',
  STORAGE_BASE_URL: 'https://app.slt-dev.attrecto-dev.com',
  AUTH_TOKEN_ID: 'id',
  API_TOKEN: 'MTU_TOKEN',
  API_TOKEN_EXPIRES_AT: 'MTU_TOKEN_EXPIRES_AT',
  TUTORIAL_COMPLETED: 'TUTORIAL_COMPLETED',
  REFRESH_TOKEN_IN_PROGRESS: 'refreshTokenInProgress',
  REFRESH_TOKEN_RESOURCE: '/auth/login/refresh',
  LOGIN_RESOURCE: '/auth/login/email',
  QUERY_FORMAT: { arrayFormat: 'bracket' },
  ITEMS_PER_PAGE: 20,
  I18NEXT: 'i18nextMTU',
  SORT_BY: {
    EMAIL: 'email',
  },
  FACEBOOK_APP_ID: '2755515844733730',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDrdJfUxNCHkkibfEacGdCGg-FVJ235W3g',
    authDomain: 'boutiqbar-8e725.firebaseapp.com',
    databaseURL: 'https://boutiqbar-8e725.firebaseio.com',
    projectId: 'boutiqbar-8e725',
    storageBucket: 'boutiqbar-8e725.appspot.com',
    messagingSenderId: '1096987892840',
    appId: '1:1096987892840:web:c5699f759390c6c3c86fdd',
    measurementId: 'G-P84TPGXB18',
  },
  DATE_FORMAT: 'yyyy-MM-DD',
  MAX_BIRTH_DATE: moment(new Date()).subtract(18, 'years'),
  IS_BROWSER: process && !process.release,
  SCROLL_BEHAVIOR: 'smooth',
};

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  bold,
  center,
  column,
  ellipsis,
  flex,
  hidden,
  pointer,
  relative,
  spaceBetween,
  uppercase,
  vertical,
} from 'constants/Jss';

export const useLessonBoxStyles = makeStyles((theme) => ({
  LessonContainer: {
    width: '100%',
    display: flex,
    flexDirection: column,
    justifyContent: spaceBetween,
    height: 307,
    borderRadius: 18,
    backgroundColor: theme.palette.greyF8,
    lineHeight: '30px',
    position: relative,
    paddingTop: theme.spacing(4),
    cursor: pointer,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  LessonBoxIconContainer: {
    left: 0,
    top: -50,
    width: '100%',
    display: flex,
    position: absolute,
    alignItems: center,
    borderRadius: '50%',
    justifyContent: center,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  LessonBoxIcon: {
    maxHeight: theme.mixins.lessonBoxIcon.height,
    borderRadius: 20,
    boxShadow: '0px 3px 6px #00000029',
  },
  LessonDescription: {
    overflow: hidden,
    color: theme.palette.grey70,
    width: '90%',
    marginLeft: auto,
    marginRight: auto,
    fontSize: 20,
    textAlign: center,
    fontWeight: bold,
    textOverflow: ellipsis,
    textTransform: uppercase,

    display: '-webkit-box',
    '-webkit-box-orient': vertical,
    '-webkit-line-clamp': 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  LessonInfoContainer: {
    display: flex,
    alignItems: center,
    justifyContent: spaceBetween,
    padding: theme.spacing(1),
  },
  LessonInfo: {
    backgroundColor: theme.palette.white,
    borderRadius: 20,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
  },
  LessonDisabled: {
    opacity: 0.5,
    cursor: 'default',
  },
}));

import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { Indicator } from 'components';
import { bold, center, flex, spaceBetween } from 'constants/Jss';
import { LessonIcon } from 'assets/icons';
import { PathCardType } from 'types/types';

export const useSymbolContainerStyles = makeStyles((theme) => ({
  SymbolContainer: {
    display: flex,
    fontFamily: 'WonderUnit',
    justifyContent: spaceBetween,
    alignItems: center,
    fontSize: 12,
    fontWeight: bold,
  },
  IconsBox: {
    display: flex,
    alignItems: center,
  },
  Text: {
    fontSize: 14,
  },
}));

const PathSymbolContainer = ({ path = {} }) => {
  const classes = useSymbolContainerStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.SymbolContainer}>
      <Box mr={2} className={classes.IconsBox}>
        <Indicator
          icon={LessonIcon}
          greyIcon
          text={`${path.lessonCount || 0} ${t('path_card_lesson')}`}
          textClassName={classes.Text}
        />
      </Box>
    </Box>
  );
};

PathSymbolContainer.propTypes = {
  path: PathCardType,
};

export default PathSymbolContainer;

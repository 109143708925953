import makeStyles from '@material-ui/core/styles/makeStyles';
import { center } from 'constants/Jss';

export const useAcceptNewTermsDialogStyles = makeStyles((theme) => ({
  PinkPatch: {
    '& .TitleBorder': {
      backgroundColor: theme.palette.default.dark,
    },
    [theme.breakpoints.down('xs')]: {
      '&.ChangeTitle > div': {
        fontSize: 24,
      },
    },
  },
  Checkbox: {
    color: `${theme.palette.whiteHover} !important`,
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      '& svg': {
        fill: `${theme.palette.default.dark} !important`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
    },
  },
  ErrorText: {
    color: theme.palette.errorColor,
    fontSize: '0.8571428571428571rem',
    marginTop: '8px 14px 0',
    lineHeight: '1em',
  },
  Description: {
    maxWidth: 700,
    textAlign: center,
  },
}));

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  block,
  bold,
  center,
  column,
  fixed,
  flex,
  flexEnd,
  inherit,
  inlineBlock,
  none,
  noRepeat,
  normal,
  pointer,
  relative,
  spaceAround,
  spaceBetween,
  table,
  transparent,
  visible,
} from 'constants/Jss';

export const useDesktopHeaderStyles = makeStyles((theme) => ({
  Header: {
    height: theme.mixins.header.height,
    [theme.breakpoints.down('sm')]: {
      display: flex,
      backgroundColor: theme.palette.default.dark,
      alignItems: center,
      padding: theme.spacing(2),
      position: fixed,
      top: 0,
      left: 0,
      right: 0,
      zIndex: 10,
      justifyContent: spaceBetween,
    },
    position: relative,
    backgroundColor: theme.palette.default.dark,
  },
  HeaderBg: {
    position: absolute,
    top: 0,
    left: 0,
    width: '100%',
    height: 60,
    backgroundColor: theme.palette.default.dark,
    padding: '0 94px',
  },
  HeaderGrid: {
    display: flex,
    alignItems: center,
    justifyContent: spaceBetween,
    height: theme.mixins.header.height,
  },
  LogoContainer: {
    display: flex,
    justifyContent: center,
    paddingLeft: 0,
  },
  Logo: {
    '&.MuiAvatar-root': {
      width: 250,
      [theme.breakpoints.down('sm')]: {
        width: 200,
      },
      height: auto,
      borderRadius: 0,
      backgroundColor: transparent,
    },
  },
  MenuContainer: {
    display: flex,
    flexGrow: 1,
    alignItems: center,
    justifyContent: flexEnd,
    height: theme.mixins.header.contentSize,
    paddingLeft: 0,
    paddingRight: 0,
  },
  List: {
    padding: 0,
    display: flex,
    whiteSpace: 'nowrap',
  },
  Button: {
    transition: theme.transitions.create(['border', 'color', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      borderColor: theme.palette.white,
    },
    '& a': {
      textDecoration: none,
    },
  },
  LoginButton: {
    color: theme.palette.white,
    backgroundColor: transparent,
    borderColor: theme.palette.white,
    '&:hover': {
      color: theme.palette.default.dark,
      borderColor: theme.palette.default.dark,
    },
  },
  RegisterButton: {
    backgroundColor: theme.palette.greyE9,
    color: theme.palette.grey64,
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: theme.palette.default.dark,
    },
  },
}));

export const useMobileHeaderStyles = makeStyles((theme) => ({
  MobileHeader: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    display: flex,
    height: theme.mixins.mobileHeader.height,
    backgroundColor: theme.palette.default.dark,
    alignItems: center,
    padding: theme.spacing(2),
    position: fixed,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    justifyContent: spaceBetween,
  },
  MobileLogoContainer: {
    border: 0,
    display: flex,
    justifyContent: center,
  },
  MobileLogo: {
    cursor: pointer,
    '&.MuiAvatar-root': {
      width: 250,
      height: auto,
      borderRadius: 0,
      backgroundColor: transparent,
    },
  },
  MobileActionContainer: {
    display: flex,
    justifyContent: flexEnd,
    flexGrow: 1,
    color: theme.palette.white,
  },
  MobileMenuButton: {
    color: inherit,
    width: 25,
    height: 25,
    cursor: pointer,
  },
  MobileSearchButton: {
    width: 25,
    height: 25,
    cursor: pointer,
  },
  MobileSearchIconButton: {
    padding: 0,
    '&:hover': {
      color: transparent,
      backgroundColor: transparent,
      '& svg': {
        fill: theme.palette.whiteHover,
      },
    },
    '&:focus': {
      color: transparent,
      backgroundColor: transparent,
      '& svg': {
        fill: theme.palette.whiteHover,
      },
    },
  },
  DrawerContainer: {
    backgroundColor: theme.palette.common.black,
    boxShadow: none,
    '&:focus': {
      outline: none,
    },
    '& ul': {
      padding: 0,
    },
  },
  MobileAvatarContainer: {
    display: flex,
    justifyContent: 'center !important',
    backgroundColor: theme.palette.common.black,
  },
  MobileAvatar: {
    width: '90px !important',
    height: '90px !important',
  },
  MobileTopicsButton: {
    color: theme.palette.white,
    padding: `${theme.spacing(2)}px !important`,
    '&:hover': {
      color: theme.palette.default.dark,
    },
  },
  MobileDivider: {
    backgroundColor: `${theme.palette.white} !important`,
  },
  Collapse: {
    padding: 0,
  },
  MobileAuthContainer: {
    display: flex,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  SearchIcon: {
    color: theme.palette.white,
  },
}));

export const useLoginDialogStyles = makeStyles((theme) => ({
  FormBox: {
    maxWidth: theme.mixins.loginForm.maxWidth,
  },
  Description: {
    textAlign: center,
  },
  LoginButtonContainer: {
    display: flex,
    justifyContent: center,
  },
  ForgotPasswordButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: spaceAround,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      display: table,
      width: '100%',
      textAlign: center,
    },
  },
  AlignCenter: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  TextAlignCenter: {
    textAlign: center,
  },
  Or: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  Link: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.hover,
    },
  },
  NewPasswordBox: {
    width: '100%',
  },
  NewPasswordLink: {
    display: flex,
    justifyContent: center,
    paddingBottom: theme.spacing(4),
    fontSize: 16,
    color: theme.palette.secondary.main,
  },
  RegisterBox: {
    display: flex,
    justifyContent: spaceBetween,
    maxWidth: theme.mixins.loginForm.maxWidth,
    marginLeft: auto,
    marginRight: auto,
    fontSize: 16,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: column,
      alignItems: center,
    },
  },
  RegisterLabel: {
    color: theme.palette.white,
    fontSize: 14,
  },
}));

export const useSocialLoginDialogStyles = makeStyles((theme) => ({
  ButtonBox: {
    width: '100%',
    maxWidth: theme.mixins.loginForm.maxWidth,
    paddingBottom: theme.spacing(4),
  },
  SocialLoginMenu: {
    '& .MuiPaper-root': {
      borderRadius: 37,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      width: theme.mixins.loginForm.maxWidth,
      marginLeft: -48,
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiPaper-root': {
        marginLeft: 0,
      },
    },
    '& .MuiIconButton-root': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  SocialLoginClose: {
    display: flex,
    justifyContent: flexEnd,
    alignItems: center,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  SocialLoginCloseLabel: { fontWeight: bold, color: theme.palette.common.black, marginRight: theme.spacing(1.5) },
  SocialLogin: {
    width: '100%',
    display: flex,
    justifyContent: center,
  },
  SocialLoginContainer: {
    display: flex,
    flexDirection: 'column',
    alignItems: center,
    justifyContent: center,
    fontSize: 16,
  },
  SocialLoginButton: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& button, & img': {
      width: '100%',
      cursor: 'pointer',
      fontFamily: 'Montserrat',
    },
    '& button span': {
      fontFamily: 'Montserrat',
    },
  },
  SocialLoginButtonText: {
    marginLeft: theme.spacing(1),
  },
}));

export const usePatches = makeStyles((theme) => ({
  YellowPatch: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-286px -250px',
    backgroundSize: '500px 445.066px',
    backgroundRepeat: `${noRepeat}`,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(17),
    },
  },
}));

export const useMenuItemStyles = makeStyles((theme) => ({
  MenuItem: {
    color: theme.palette.white,
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.whiteHover,
    },
    '&.Mobile': {
      color: theme.palette.whiteHover,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  SubmenuItem: {
    color: theme.palette.grey70,
    '&:hover': {
      color: theme.palette.grey70,
      backgroundColor: theme.palette.whiteHover,
    },
    '&:focus': {
      color: theme.palette.grey70,
      backgroundColor: theme.palette.whiteHover,
    },
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    '&:not(:last-child) > div': {
      borderBottom: `2px solid ${theme.palette.whiteHover}`,
    },
    '&.Mobile': {
      color: theme.palette.white,
      backgroundColor: theme.palette.blackOpacity,
      paddingTop: 0,
      paddingBottom: 0,
      '&:hover': {
        color: theme.palette.default.dark,
      },
      '&:focus': {
        color: theme.palette.default.dark,
      },
      '&:not(:last-child) > div': {
        border: 0,
      },
    },
  },
  SubmenuItemText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  Icon: {
    width: 35,
    height: 59,
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 8,
    borderBottom: `2px solid ${theme.palette.whiteHover}`,
  },
}));

export const useRegisterDialogStyles = makeStyles((theme) => ({
  FormBox: {
    maxWidth: theme.mixins.loginForm.maxWidth,
  },
  FieldBox: {
    position: relative,
  },
  FlexFieldBox: {
    display: flex,
    alignItems: center,
    color: theme.palette.greyB3,
    fontSize: 16,
    fontWeight: normal,
  },
  RegisterButtonContainer: {
    display: flex,
    justifyContent: center,
  },
  Title: {
    borderColor: theme.palette.green,
  },
  Checkbox: {
    marginRight: theme.spacing(3),
    color: `${theme.palette.whiteHover} !important`,
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      '& svg': {
        fill: `${theme.palette.default.dark} !important`,
      },
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
    },
  },
  CheckboxText: {
    fontSize: 12,
  },
  ErrorText: {
    color: theme.palette.errorColor,
    fontSize: '0.8571428571428571rem',
    marginTop: '8px 14px 0',
    lineHeight: '1em',
  },
  TooltipDialog: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(8),
      fontSize: 16,
      '& label': {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
  },
}));

export const useSearchStyles = makeStyles((theme) => ({
  Search: {
    display: flex,
    '&:focus-within': {
      backgroundColor: theme.palette.searchBg,
    },
  },
  MobileSearch: {
    borderRadius: 0,
    backgroundColor: theme.palette.whiteHover,
    '&:focus-within': {
      backgroundColor: theme.palette.whiteHover,
    },
  },
  SearchWithBg: {
    backgroundColor: theme.palette.searchBg,
  },
  Form: {
    height: theme.mixins.searchBar.height,
    position: relative,
    backgroundColor: theme.palette.white,
    display: flex,
    alignItems: center,
    '&.TooShort': {
      borderColor: theme.palette.errorColor,
    },
    '& input::-webkit-input-placeholder': {
      color: theme.palette.grey70,
    },
    '& input::-moz-placeholder': {
      color: theme.palette.grey70,
    },
    '& input::placeholder': {
      color: theme.palette.grey70,
    },
    borderRadius: 3,
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
    },
  },
  MobileForm: {
    width: '100%',
    height: theme.spacing(7),
    display: flex,
    alignItems: center,
  },
  SearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.searchBar.height + 2,
    height: theme.mixins.searchBar.height,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
    '& svg': {
      fill: `${theme.palette.default.dark} !important`,
    },
    borderTopLeftRadius: 0,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    borderBottomLeftRadius: 0,
  },
  MobileSearchIconContainer: {
    backgroundColor: transparent,
    '&:hover': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: '18.5px !important',
      height: '19px !important',
    },
    marginLeft: theme.spacing(1),
  },
  SearchField: {
    fontSize: 13,
    color: theme.palette.grey81,
  },
  MobileSearchField: {
    color: theme.palette.default.dark,
    flexGrow: 1,
  },
  InputField: {
    paddingLeft: theme.spacing(2),
    minWidth: 180,
    [theme.breakpoints.down(1280)]: {
      minWidth: 180,
    },
  },
  CloseSearch: {
    position: absolute,
    top: 14,
    right: 14,
    cursor: pointer,
    padding: 0,
    '&:hover': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: 16,
      height: 16,
      fill: theme.palette.secondary.main,
    },
  },
  MobileCloseSearch: {
    '& svg': {
      width: 18,
      height: 18,
      fill: theme.palette.white,
    },
  },
  SearchIcon: {
    fill: theme.palette.white,
  },
}));

export const useHamburgerStyles = makeStyles((theme) => ({
  Container: {
    display: inlineBlock,
    cursor: pointer,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    '&:hover': {
      color: transparent,
      backgroundColor: transparent,
      '& .Bar': {
        backgroundColor: theme.palette.whiteHover,
      },
    },
    '&:focus': {
      color: transparent,
      backgroundColor: transparent,
      '& .Bar': {
        backgroundColor: theme.palette.whiteHover,
      },
    },
    '& .MuiIconButton-label': {
      display: block,
    },
  },
  Bar: {
    height: 5,
    backgroundColor: theme.palette.white,
    marginLeft: 0,
    marginRight: 0,
    transition: '0.4s',
  },
  Bar1: {
    width: 24,
    marginBottom: 4,
  },
  Bar2: {
    width: 18,
  },
  Bar1Open: {
    width: 25,
    '-webkit-transform': 'rotate(-45deg) translate(-4px, 0)',
    transform: 'rotate(-45deg) translate(-4px, 0)',
    marginBottom: 0,
  },
  Bar2Open: {
    width: 25,
    '-webkit-transform': 'rotate(45deg) translate(-3px, 0)',
    transform: 'rotate(45deg) translate(-3px, 0)',
  },
}));

export const useSuccessRegistrationDialogStyles = makeStyles((theme) => ({
  Container: {
    maxWidth: 500,
  },
  CloseButtonContainer: {
    display: flex,
    justifyContent: center,
  },
  Description: {
    display: flex,
    textAlign: center,
  },
  Title: {
    borderColor: theme.palette.green,
  },
  Dialog: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(8),
      fontSize: 16,
      '& label': {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
  },
}));

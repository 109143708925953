import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { paginationStyles } from './styles';

class Pagination extends Component {
  static propTypes = {
    itemCount: PropTypes.number,
    itemPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    visiblePageLinks: PropTypes.number,
    setCurrentPage: PropTypes.func,
    className: PropTypes.string,
  };

  activePageLink = (page) => {
    const { currentPage } = this.props;
    return currentPage === page ? 'Active' : '';
  };

  invisiblePageLink = (page, maxPage) => {
    const { currentPage, visiblePageLinks } = this.props;
    return (
      currentPage > page + Math.max((visiblePageLinks - 1) / 2, visiblePageLinks - 1 - maxPage + currentPage) ||
      currentPage < page - Math.max((visiblePageLinks - 1) / 2, visiblePageLinks - currentPage)
    );
  };

  render() {
    const { itemCount, itemPerPage, currentPage, setCurrentPage, classes, className } = this.props;
    const pages = Array.from({ length: Math.ceil(itemCount / itemPerPage) }, (v, k) => k + 1);
    const maxPage = pages.length;
    return pages.length > 1 ? (
      <Box mt={2} className={classNames(classes.PaginationContainer, className)}>
        <a
          href="#"
          className={classNames('Prev', { [classes.Invisible]: currentPage === 1 })}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <ArrowBackIcon />
        </a>

        {pages.map((page, index) => (
          <a
            href="#"
            className={classNames(this.activePageLink(page), {
              Hidden: this.invisiblePageLink(page, maxPage),
            })}
            onClick={() => setCurrentPage(page)}
            key={index}
          >
            <span>{page}</span>
          </a>
        ))}

        <a
          href="#"
          className={classNames('Next', { [classes.Invisible]: currentPage === maxPage })}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <ArrowForwardIcon />
        </a>
      </Box>
    ) : null;
  }
}

export default withStyles(paginationStyles)(Pagination);

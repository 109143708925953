import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

import { EXAM_DONE_STRING } from 'constants/Constants';
import { MobileFilterHeader } from './MobileFilterHeader';

import { useMobileFilterDialogStyles } from './styles';

export const MobileExamsFilter = ({ openFilter, setOpenFilter, filterValues, handleFilterChange }) => {
  const { t } = useTranslation();
  const classes = useMobileFilterDialogStyles();

  const onChange = (done) => {
    handleFilterChange({
      examDone: filterValues.examDone === done ? null : done,
    });
  };

  return (
    <Box className={classes.Filter}>
      <MobileFilterHeader
        openType={EXAM_DONE_STRING}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        title={t('filter_exams')}
        altTitle={t('alt_exams_filter')}
      />
      <Box
        className={classNames(classes.Body, {
          Open: openFilter === EXAM_DONE_STRING,
        })}
      >
        <div className={classes.MobileRadioFilter}>
          <FormControlLabel
            control={
              <Checkbox
                className={classNames(classes.Checkbox, {
                  Checked: filterValues.examDone === true,
                })}
                checked={filterValues.examDone === true}
                onChange={() => onChange(true)}
                tabIndex={-1}
                aria-hidden={true}
              />
            }
            label={t('topics_page_exam_done')}
            aria-label={t('topics_page_exam_done')}
            role="checkbox"
            tabIndex={openFilter === EXAM_DONE_STRING ? 0 : -1}
            aria-checked={filterValues.examDone === true}
          />
        </div>
        <div className={classes.MobileRadioFilter}>
          <FormControlLabel
            control={
              <Checkbox
                className={classNames(classes.Checkbox, {
                  Checked: filterValues.examDone === false,
                })}
                checked={filterValues.examDone === false}
                onChange={() => onChange(false)}
                tabIndex={-1}
                aria-hidden={true}
              />
            }
            label={t('topics_page_exam_to_be_done')}
            aria-label={t('topics_page_exam_to_be_done')}
            role="checkbox"
            tabIndex={openFilter === EXAM_DONE_STRING ? 0 : -1}
            aria-checked={filterValues.examDone === false}
          />
        </div>
      </Box>
    </Box>
  );
};

MobileExamsFilter.propTypes = {
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
  filterValues: PropTypes.object,
  handleFilterChange: PropTypes.func,
};

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const LOGOUT = 'LOGOUT';

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';

export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const GET_PROFILE_DETAILS_SUCCESS = 'GET_PROFILE_DETAILS_SUCCESS';
export const GET_PROFILE_DETAILS_FAILED = 'GET_PROFILE_DETAILS_FAILED';

export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS';
export const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
export const UPDATE_PROFILE_DETAILS_FAILED = 'UPDATE_PROFILE_DETAILS_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const DELETE_PROFILE = 'DELETE_PROFILE';
export const DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS';
export const DELETE_PROFILE_FAILED = 'DELETE_PROFILE_FAILED';

export const GET_BADGES = 'GET_BADGES';
export const GET_BADGES_SUCCESS = 'GET_BADGES_SUCCESS';
export const GET_BADGES_FAILED = 'GET_BADGES_FAILED';

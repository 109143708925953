import React from 'react';
import PropTypes from 'prop-types';

import { CourseAndLessonCard, withPagination, DraggableWrapper } from 'components';

const CourseAndLessonCardList = ({ list }) => {
  return (list || []).map(({ id, isDragDisabled, ...props }) => {
    const commonProps = { ...props, minutes: props.examMinutes, imageUrl: props.image };
    return isDragDisabled ? (
      <CourseAndLessonCard key={id} disabled={isDragDisabled} {...commonProps} />
    ) : (
      <DraggableWrapper id={id}>
        {(isDragging) => <CourseAndLessonCard key={id} isDragging={isDragging} {...commonProps} />}
      </DraggableWrapper>
    );
  });
};

CourseAndLessonCardList.propTypes = {
  list: PropTypes.array,
};

export default withPagination(CourseAndLessonCardList);

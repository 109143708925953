import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { block, inlineBlock } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  CircularLoaderContainer: {
    display: inlineBlock,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  CircularLoader: {
    display: block,
    width: theme.spacing(8),
    height: theme.spacing(8),
    margin: theme.spacing(1),
    borderRadius: '50%',
    border: `6px solid ${theme.palette.primary.dark}`,
    borderColor: `${theme.palette.primary.dark} transparent ${theme.palette.primary.dark} transparent`,
    animationName: 'lds-dual-ring',
    animationDuration: '1.2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.CircularLoaderContainer}>
      <div className={classes.CircularLoader} />
    </div>
  );
};

export default Spinner;

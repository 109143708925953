import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';

import { socialLogin, SocalLoginProviders } from 'store/auth/actions';
import { Button, CloseIcon, FirebaseLogin } from 'components';
import { useSocialLoginDialogStyles } from './styles';
import { useCommonStyles } from 'styles/common';
import Settings from 'env';
import { FacebookWhiteIcon } from 'assets/icons';

const LoginWithSocial = ({ socialLogin, getAuthorizedRoutes }) => {
  const { t } = useTranslation();
  const classes = useSocialLoginDialogStyles();
  const commonClasses = useCommonStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const responseGoogle = (response) => {
    if (response && response.credential && response.additionalUserInfo && response.additionalUserInfo.profile) {
      socialLogin(SocalLoginProviders.google, getAuthorizedRoutes, {
        accessToken: response.credential.accessToken,
        firstName: response.additionalUserInfo.profile.family_name,
        lastName: response.additionalUserInfo.profile.given_name,
      });
    }
  };

  const responseFacebook = (response) => {
    socialLogin(SocalLoginProviders.facebook, getAuthorizedRoutes, {
      accessToken: response.accessToken,
      firstName: response.first_name,
      lastName: response.last_name,
    });
  };

  const responseApple = (response) => {
    const splittedDisplayName = response.user.displayName.split(' ');
    const firstName = splittedDisplayName ? splittedDisplayName[0] : '';
    const lastName =
      splittedDisplayName && splittedDisplayName.length > 1
        ? splittedDisplayName.filter((name, index) => index > 0).join(' ')
        : '';

    socialLogin(SocalLoginProviders.apple, getAuthorizedRoutes, {
      accessToken: response.credential.idToken,
      firstName,
      lastName,
    });
  };

  return (
    <Box className={classes.SocialLogin}>
      <Box className={classNames(classes.ButtonBox)}>
        <Button
          aria-label={t('form_login_with_social_button_text')}
          id="SocialLoginButton"
          onClick={handleClick}
          fullWidth
          large
        >
          {t('form_login_with_social_button_text')}
        </Button>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.SocialLoginMenu}
      >
        <Box className={classes.SocialLoginContainer}>
          <Box className={classes.SocialLoginClose}>
            <CloseIcon withText white onClick={handleClose} />
          </Box>
          <Box className={classes.SocialLoginButton}>
            <FirebaseLogin onSuccess={responseGoogle} provider="google" />
          </Box>
          <Box className={classes.SocialLoginButton}>
            <FacebookLogin
              appId={Settings.FACEBOOK_APP_ID}
              fields="first_name,last_name"
              size="small"
              callback={responseFacebook}
              textButton={t('form_login_with_facebook')}
              icon={<FacebookWhiteIcon />}
              cssClass={commonClasses.SocialLoginButton}
            />
          </Box>
          <Box className={classes.SocialLoginButton}>
            <FirebaseLogin onSuccess={responseApple} provider="apple" />
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

LoginWithSocial.propTypes = {
  socialLogin: PropTypes.func.isRequired,
  getAuthorizedRoutes: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      socialLogin,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(LoginWithSocial);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';

import { pageStyles } from './styles';
import { auto } from 'constants/Jss';

const Page = (WrappedComponent, ClassName, PageTitle, isInContainer) => {
  return class extends Component {
    static getInitialData = WrappedComponent.getInitialData;

    componentDidMount() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: auto,
      });
    }

    render() {
      const { classes, isLoggedIn, ...props } = this.props;
      const content = (
        <>
          {PageTitle ? (
            <Box mb={5} className="PageTitleContainer">
              <h1 className="Title" tabIndex={0}>
                {PageTitle}
              </h1>
              <div className="TitleBorder" />
            </Box>
          ) : null}
          <WrappedComponent isLoggedIn={isLoggedIn} {...props} />
        </>
      );
      return (
        <div className={classes[ClassName]}>
          {isInContainer ? (
            <Container maxWidth="lg" className={classNames('Page', classes.MainContainer)}>
              <Grid container spacing={4} className={classNames({ loggedIn: isLoggedIn() })}>
                {content}
              </Grid>
            </Container>
          ) : (
            <Box className={classNames('Page', classes.NoContainer, { loggedIn: isLoggedIn() })}>{content}</Box>
          )}
        </div>
      );
    }
  };
};

Page.propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
  isLoggedIn: PropTypes.func,
};

export default compose(withStyles(pageStyles), Page);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Box, ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button } from 'components';

import { useFilterStyles } from '../Filter/styles';

const FilterDialog = ({
  icon,
  label,
  content,
  open,
  anchorRef,
  menuId,
  handleClose,
  handleToggle,
  small,
  activeFilters,
  tKey,
  onFilterButtonClick,
  wide = true,
}) => {
  const classes = useFilterStyles();
  const { t } = useTranslation();

  const handleFilterButtonClick = (e) => {
    if (onFilterButtonClick) {
      onFilterButtonClick(e);
    } else if (handleClose) {
      handleClose(e);
    }
  };

  return (
    <div
      className={classNames(classes.FilterDialogContainer, {
        Small: small,
        Numbered: activeFilters,
        Wide: wide,
      })}
    >
      {activeFilters ? (
        <div className={classes.ActiveFiltersBox}>
          <Box className={classes.ActiveFilters}>{activeFilters}</Box>
        </div>
      ) : null}
      <Button
        ref={anchorRef}
        buttonClassName={classes.FilterButton}
        onClick={() => handleToggle(label)}
        aria-label={
          activeFilters
            ? t(`alt_${tKey}_filter_with_active_filters`, { numberOfFilters: activeFilters })
            : t(`alt_${tKey}_filter`)
        }
        aria-haspopup={true}
        aria-controls={open ? menuId : undefined}
      >
        <Box className={classes.FilterIcon} mr={1}>
          {icon}
        </Box>
        <Box>{label}</Box>
      </Button>

      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps, placement }) => {
          return (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id={menuId} className={classNames({ Top: placement === 'top' })}>
                    <Box
                      mt={1}
                      mr={1}
                      className={classNames(classes.CloseIconContainer, { Hidden: small })}
                      tabIndex={-1}
                    >
                      <IconButton
                        className={classes.CloseIconButton}
                        onClick={handleClose}
                        tabIndex={0}
                        aria-label={t(`alt_${tKey}_filter_close`)}
                      >
                        <CloseIcon className={classes.CloseIcon} />
                      </IconButton>
                    </Box>
                    {content}
                    <Box mt={2} className={classNames(classes.CloseButtonContainer, { Hidden: small })}>
                      <Button
                        buttonClassName={classes.CloseButton}
                        onClick={handleFilterButtonClick}
                        aria-label={t(`alt_${tKey}_filter_close`)}
                      >
                        {t('common_filtering')}
                      </Button>
                    </Box>
                    <div className={classNames(classes.Triangle, { Top: placement === 'top' })} />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </div>
  );
};

FilterDialog.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  content: PropTypes.node,
  open: PropTypes.bool,
  anchorRef: PropTypes.object,
  menuId: PropTypes.string,
  handleClose: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  handleToggle: PropTypes.func,
  small: PropTypes.bool,
  activeFilters: PropTypes.number,
  tKey: PropTypes.string,
  wide: PropTypes.bool,
};

export default FilterDialog;

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  bold,
  center,
  inlineFlex,
  flex,
  flexStart,
  none,
  pointer,
  relative,
  uppercase,
} from 'constants/Jss';

export const useRemainingTimeStyles = makeStyles((theme) => ({
  TimeStrip: {
    fontFamily: 'Montserrat',
    fontWeight: bold,
    fontSize: 21,
    textTransform: uppercase,
    width: '100%',
    display: flex,
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  TimeNumbers: {
    width: 70,
    display: inlineFlex,
    justifyContent: flexStart,
  },
}));

export const useProgressBarStyles = makeStyles((theme) => ({
  ProgressBarContainer: {
    width: 665,
    height: 12,
    borderRadius: 15,
    backgroundColor: theme.palette.greyF2,
    position: relative,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      borderRadius: 8,
      width: '100%',
    },
  },
  NumberIndicator: {
    position: absolute,
    top: 0,
    left: -80,
    height: 12,
    display: flex,
    alignItems: center,
    fontFamily: 'Montserrat',
    fontWeight: bold,
    fontSize: 21,
    color: theme.palette.grey64,
    [theme.breakpoints.down('sm')]: {
      top: -35,
      left: 0,
    },
  },
  ProgressStrip: {
    height: 12,
    borderRadius: 15,
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      borderRadius: 8,
    },
  },
}));

export const useFormStyles = makeStyles((theme) => ({
  FormBox: {
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
  },
  Checkbox: {
    alignSelf: flexStart,
    marginRight: theme.spacing(3),
    '& svg': {
      fill: theme.palette.checkboxBorder,
    },
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    '&.Mui-checked': {
      color: theme.palette.whiteHover,
      '&:hover': {
        backgroundColor: `${theme.palette.whiteHover} !important`,
      },
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  },
  AnswerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 85,
    border: `1px solid ${theme.palette.checkboxBorder}`,
    borderRadius: 20,
    padding: theme.spacing(3),
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    backgroundColor: theme.palette.white,
    '&.Checked': {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
    '&.Correct': {
      borderColor: theme.palette.greenSuccessBackground,
      backgroundColor: theme.palette.greenSuccessBackground,
      '&.Multi svg': {
        fill: theme.palette.common.white,
      },
      '&:not(.Multi) svg': {
        fill: theme.palette.greenSuccess,
      },
    },
    '&.WouldCorrect': {
      '& svg': {
        fill: theme.palette.secondary.main,
      },
    },
    '&.Wrong': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.secondary.main,
      '&.Multi svg': {
        fill: theme.palette.common.white,
      },
      '&:not(.Multi) svg': {
        fill: theme.palette.secondary.main,
      },
    },
    '&.TrueOrFalse': {
      border: 0,
      backgroundColor: `${theme.palette.white} !important`,
      boxShadow: none,
    },
    '&:not(.TrueOrFalse)': {
      cursor: pointer,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      '& svg': {
        width: 20,
        height: 20,
      },
    },
  },
  Answer: {
    color: theme.palette.grey70,
    fontSize: 18,
    lineHeight: '21px',
    '& .MuiFormControlLabel-label': {
      alignSelf: center,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 !important',
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        lineHeight: '18px',
      },
      '& > span': {
        padding: 0,
        margin: 0,
        marginRight: theme.spacing(1),
      },
    },
  },
  Flex: {
    display: flex,
    flexDirection: 'row !important',
    flexWrap: 'nowrap !important',
    justifyContent: center,
  },
}));

export const usePuzzleFormStyles = makeStyles((theme) => ({
  PuzzleWordContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    minHeight: 60,
  },
  PuzzleWordDivider: {
    marginBottom: theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.grey81,
  },
  PuzzleWord: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 0,
    minWidth: 50,
    height: 44,
    fontSize: 20,
    fontWeight: '600',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 8px 6px #00000029',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey81,
    borderRadius: 6,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    zIndex: 2,
  },
  EmptyWord: {
    borderColor: theme.palette.greyD6,
    backgroundColor: theme.palette.greyD6,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.greyD6,
    },
  },
  PuzzleWordCorrectAnswer: {
    height: 31,
  },
  PuzzleWordButtonContianer: {
    position: 'relative',
  },
  PuzzleWordButtonBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  PuzzleWordButtonBackgroundAnimation: {
    zIndex: 4,
  },
  Correct: {
    backgroundColor: theme.palette.greenSuccessBackground,
    borderColor: theme.palette.greenSuccessBackground,
    opacity: '1 !important',
    color: theme.palette.common.white,
  },
  Wrong: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    opacity: '1 !important',
    color: theme.palette.common.white,
  },
}));

export const usePuzzleWordAnimationStyles = (top, left, id, animationDuration) => {
  return makeStyles((theme) => ({
    PuzzleWordAnimation: {
      position: 'relative',
      animationName: `$flying-${id}`,
      animationDuration: `${animationDuration}ms`,
      zIndex: 3,
    },
    [`@keyframes flying-${id}`]: {
      from: { left: 0, top: 0 },
      to: { left: `${left}px`, top: `${top}px` },
    },
  }));
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Grid from '@material-ui/core/Grid';

import { useImageAndContentBoxStyles } from './styles';

const ImageAndContentBox = ({
  contentClass,
  imageClass,
  contentComponent,
  background,
  imageMinHeight = 300,
  imageMaxHeight = 378,
  imageMaxHeightOnMobile = 230,
  imageFirst,
  invertOrderWhenBroke,
  contentPadding = 38,
}) => {
  const classes = useImageAndContentBoxStyles({
    background,
    imageMinHeight,
    imageMaxHeight,
    imageMaxHeightOnMobile,
    imageFirst,
    contentPadding,
    invertOrderWhenBroke,
  });

  const imageComponentGrid = <Grid className={classnames(classes.ImageBox, imageClass)} item md={6} sm={12} />;
  const contentComponentGrid = (
    <Grid className={classnames(classes.ContentBox, contentClass)} item md={6} sm={12}>
      {contentComponent}
    </Grid>
  );
  return (
    <Grid className={classnames(classes.ImageAndContentContainer)} container>
      {imageFirst ? (
        <>
          {imageComponentGrid}
          {contentComponentGrid}
        </>
      ) : (
        <>
          {contentComponentGrid}
          {imageComponentGrid}
        </>
      )}
    </Grid>
  );
};

ImageAndContentBox.propTypes = {
  imageMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentComponent: PropTypes.node.isRequired,
  background: PropTypes.string.isRequired,
  contentClass: PropTypes.string,
  imageClass: PropTypes.string,
};

export default ImageAndContentBox;

import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  REGISTER,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  VERIFY_EMAIL,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_SUCCESS,
} from './types';
import { history } from 'store/store';
import * as AuthService from 'services/auth';
import { setToken } from 'utils/refreshToken';
import { parseJwt } from 'utils/jwt';
import { SET_PROFILE_DATA } from '../profile/types';
import { getProfileDetails } from 'store/profile/actions';

export const SocalLoginProviders = {
  google: 'google',
  facebook: 'facebook',
  apple: 'apple',
};

const loginSuccess = async (dispatch, getAuthorizedRoutes, { access_token, expires_in }) => {
  const routes = await getAuthorizedRoutes(access_token);

  setToken({ token: access_token, expiresAt: expires_in });
  dispatch({
    type: LOGIN_SUCCESS,
  });

  history.push(`${history.location.pathname}`);

  const { sub, firstLogin } = parseJwt(access_token);

  dispatch({
    type: SET_PROFILE_DATA,
    payload: {
      id: sub,
    },
  });

  await dispatch(getProfileDetails());

  return { firstLogin, routes };
};

export const login = (credentials, getAuthorizedRoutes) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN,
    });

    try {
      const response = await AuthService.login(credentials);
      return loginSuccess(dispatch, getAuthorizedRoutes, response);
    } catch (error) {
      dispatch({
        type: LOGIN_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const socialLogin = (provider, getAuthorizedRoutes, data) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN,
    });

    try {
      const response = await AuthService.socialLogin(provider, data);
      return loginSuccess(dispatch, getAuthorizedRoutes, response);
    } catch (error) {
      dispatch({
        type: LOGIN_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const register = (data) => {
  return async (dispatch) => {
    dispatch({
      type: REGISTER,
    });

    try {
      const { success } = await AuthService.register(data);

      dispatch({
        type: REGISTER_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({
        type: REGISTER_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const verifyEmail = ({ language, token }) => {
  return async (dispatch) => {
    dispatch({
      type: VERIFY_EMAIL,
    });

    try {
      const result = await AuthService.verifyEmail({ language, token });

      dispatch({
        type: VERIFY_EMAIL_SUCCESS,
      });

      return result;
    } catch (error) {
      dispatch({
        type: VERIFY_EMAIL_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const forgotPassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: FORGOT_PASSWORD,
    });

    try {
      const { success } = await AuthService.forgotPassword(email);

      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const resetPassword = ({ token, password }) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_PASSWORD,
    });

    try {
      const { success } = await AuthService.resetPassword({ token, password });

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

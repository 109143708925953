import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';

import HomePage from './HomePage';
import { getDashboard } from 'store/dashboard/actions';
import { logout } from 'store/profile/actions';
import { getRandomQuote } from 'store/quotes/actions';
import { addCommonLoader, removeCommonLoader } from 'store/loader/actions';

const mapStateToProps = (state) => {
  return {
    id: state.profile.details.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRandomQuote,
      getDashboard,
      addCommonLoader,
      removeCommonLoader,
      logout,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(HomePage);

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';

import { useCheckboxFilterStyles } from '../styles';

export const ExamsFilter = ({ filter, setFilters }) => {
  const classes = useCheckboxFilterStyles();
  const { t } = useTranslation();

  const onChange = (examDone) => {
    setFilters({ examDone: filter === examDone ? null : examDone });
  };

  return (
    <div className={classes.CheckboxFilterContainer}>
      <FormControlLabel
        control={<Checkbox checked={filter === true} onChange={() => onChange(true)} />}
        label={t('topics_page_exam_done')}
      />
      <FormControlLabel
        control={<Checkbox checked={filter === false} onChange={() => onChange(false)} />}
        label={t('topics_page_exam_to_be_done')}
      />
    </div>
  );
};

ExamsFilter.propTypes = {
  filter: PropTypes.bool,
  setFilters: PropTypes.func,
};

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useCourseInfoStyles = makeStyles((theme) => ({
  LargeContainer: {
    width: 300,
  },
  XLargeWidthContainer: {
    width: '55%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  WithDetailsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  CourseDetailProgressBar: {
    [theme.breakpoints.up('sm')]: {
      '&.MuiLinearProgress-root': {
        maxWidth: 300,
        margin: 'auto',
      },
    },
  },
}));

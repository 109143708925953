import * as CoursesService from 'services/courses';
import {
  BOOKMARK_COURSE,
  BOOKMARK_COURSE_FAILED,
  BOOKMARK_COURSE_SUCCESS,
  GET_BOOKMARKED_COURSES,
  GET_BOOKMARKED_COURSES_FAILED,
  GET_BOOKMARKED_COURSES_SUCCESS,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_FAILED,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSES,
  GET_COURSES_FAILED,
  GET_COURSES_BY_FILTER,
  GET_COURSES_BY_FILTER_SUCCESS,
  GET_COURSES_BY_FILTER_FAILED,
  GET_COURSES_FOR_TOPIC,
  GET_COURSES_FOR_TOPIC_FAILED,
  GET_COURSES_FOR_TOPIC_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_COURSES_WITH_EXAMS,
  GET_COURSES_WITH_EXAMS_SUCCESS,
  GET_ONGOING_COURSES,
  GET_ONGOING_COURSES_FAILED,
  GET_ONGOING_COURSES_SUCCESS,
  GET_COMPANY_COURSES,
  GET_COMPANY_COURSES_FAILED,
  GET_COMPANY_COURSES_SUCCESS,
  GET_COMPLETED_COURSES,
  GET_COMPLETED_COURSES_FAILED,
  GET_COMPLETED_COURSES_SUCCESS,
  LOAD_MORE_BOOKMARKED_COURSES,
  LOAD_MORE_BOOKMARKED_COURSES_FAILED,
  LOAD_MORE_BOOKMARKED_COURSES_SUCCESS,
  LOAD_MORE_COURSES_BY_FILTER,
  LOAD_MORE_COURSES_BY_FILTER_FAILED,
  LOAD_MORE_COURSES_BY_FILTER_SUCCESS,
  LOAD_MORE_COURSES_WITH_EXAMS,
  LOAD_MORE_COURSES_WITH_EXAMS_FAILED,
  LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS,
  LOAD_MORE_ONGOING_COURSES,
  LOAD_MORE_ONGOING_COURSES_FAILED,
  LOAD_MORE_ONGOING_COURSES_SUCCESS,
  LOAD_MORE_COMPANY_COURSES,
  LOAD_MORE_COMPANY_COURSES_FAILED,
  LOAD_MORE_COMPANY_COURSES_SUCCESS,
  LOAD_MORE_COMPLETED_COURSES,
  LOAD_MORE_COMPLETED_COURSES_FAILED,
  LOAD_MORE_COMPLETED_COURSES_SUCCESS,
  RESET_BOOKMARKED_COURSES,
  RESET_COURSES,
  RESET_FILTERED_COURSES,
  RESET_COURSES_WITH_EXAMS,
  RESET_ONGOING_COURSES,
  RESET_COMPANY_COURSES,
  RESET_COMPLETED_COURSES,
  SET_FILTERS,
  SET_SORT_BY,
  RESET_COURSE,
} from './types';
import { GET_TOP_LIST_OF_GROUPS_FAILED } from '../topList/types';
import { LessonStatus } from 'utils/lessonStatus';

export const getCourses = (params, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES });

      const courses = await CoursesService.getCoursesByFilter({ language, ...params });

      dispatch({
        type: GET_COURSES_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: GET_COURSES_FAILED });
      throw error;
    }
  };
};

export const resetCourses = () => {
  return { type: RESET_COURSES };
};

export const resetFilteredCourses = () => {
  return { type: RESET_FILTERED_COURSES };
};

export const getCoursesByFilter = ({ tags, ...params }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES_BY_FILTER });

      const tagIds = tags || [];
      const courses = await CoursesService.getCoursesByFilter({ tagIds, ...params });

      dispatch({
        type: GET_COURSES_BY_FILTER_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: GET_COURSES_BY_FILTER_FAILED });
      throw error;
    }
  };
};

export const getCoursesForTopic = (params, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES_FOR_TOPIC });

      const courses = await CoursesService.getCoursesForTopic({ language, ...params });

      dispatch({
        type: GET_COURSES_FOR_TOPIC_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: GET_COURSES_FOR_TOPIC_FAILED });
      throw error;
    }
  };
};

export const loadMoreCoursesByFilter = ({ tags, ...params }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COURSES_BY_FILTER });

      const tagIds = tags || [];
      const courses = await CoursesService.getCoursesByFilter({ tagIds, ...params });

      dispatch({
        type: LOAD_MORE_COURSES_BY_FILTER_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: LOAD_MORE_COURSES_BY_FILTER_FAILED });
      throw error;
    }
  };
};

export const loadMoreCourses = (params) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COURSES });

      const courses = await CoursesService.getCourses(params);

      dispatch({
        type: LOAD_MORE_COURSES_SUCCESS,
        payload: courses,
      });

      return courses;
    } catch (error) {
      dispatch({ type: LOAD_MORE_COURSES_FAILED });
      throw error;
    }
  };
};

export const setFilters = ({ tags, duration, examDone, searchString }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_FILTERS,
      payload: {
        tags,
        duration,
        examDone,
        searchString,
      },
    });
  };
};

export const setSortBy = (sortBy) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SORT_BY,
      payload: { sortBy },
    });
  };
};

// lessons statuses are 0, 1, 2 -> NOT_STARTED, STARTED, PASSED
const getCourseStatusesCountByLessons = (course) => {
  const statuses = [0, 0, 0];
  course.lessons.forEach(({ status }) => {
    statuses[status] = statuses[status] + 1;
  });

  return statuses;
};

export const getCourseById = (id, language) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_BY_ID });

      const course = await CoursesService.getCourseById(id, language);
      const statuses = getCourseStatusesCountByLessons(course);

      dispatch({
        type: GET_COURSE_BY_ID_SUCCESS,
        payload: {
          ...course,
          visited: statuses[LessonStatus.NOT_STARTED],
          failed: statuses[LessonStatus.STARTED],
          success: statuses[LessonStatus.PASSED],
        },
      });
    } catch (error) {
      dispatch({ type: GET_COURSE_BY_ID_FAILED });
      throw error;
    }
  };
};

export const resetCourse = () => {
  return { type: RESET_COURSE };
};

export const getCoursesWithExams = ({ page, language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSES_WITH_EXAMS });

      const courses = await CoursesService.getCoursesWithExams(page, language, token);

      dispatch({
        type: GET_COURSES_WITH_EXAMS_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_TOP_LIST_OF_GROUPS_FAILED });

      throw error;
    }
  };
};

export const resetCoursesWithExams = () => {
  return { type: RESET_COURSES_WITH_EXAMS };
};

export const loadMoreCoursesWithExams = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COURSES_WITH_EXAMS });

      const courses = await CoursesService.getCoursesWithExams(page);

      dispatch({
        type: LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_COURSES_WITH_EXAMS_FAILED });

      throw error;
    }
  };
};

export const getOngoingCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ONGOING_COURSES });

      const courses = await CoursesService.getOngoingCourses(page);

      dispatch({
        type: GET_ONGOING_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_ONGOING_COURSES_FAILED });
      throw error;
    }
  };
};

export const getCompanyCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMPANY_COURSES });

      const courses = await CoursesService.getCompanyCourses(page);

      dispatch({
        type: GET_COMPANY_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_COMPANY_COURSES_FAILED });
      throw error;
    }
  };
};

export const getCompletedCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COMPLETED_COURSES });

      const courses = await CoursesService.getCompletedCourses(page);

      dispatch({
        type: GET_COMPLETED_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_COMPLETED_COURSES_FAILED });
      throw error;
    }
  };
};

export const resetOngoingCourses = () => {
  return { type: RESET_ONGOING_COURSES };
};

export const resetCompanyCourses = () => {
  return { type: RESET_COMPANY_COURSES };
};

export const resetCompletedCourses = () => {
  return { type: RESET_COMPLETED_COURSES };
};

export const loadMoreOngoingCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_ONGOING_COURSES });

      const courses = await CoursesService.getOngoingCourses(page);

      dispatch({
        type: LOAD_MORE_ONGOING_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_ONGOING_COURSES_FAILED });
      throw error;
    }
  };
};

export const loadMoreCompanyCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COMPANY_COURSES });

      const courses = await CoursesService.getCompanyCourses(page);

      dispatch({
        type: LOAD_MORE_COMPANY_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_COMPANY_COURSES_FAILED });
      throw error;
    }
  };
};

export const loadMoreCompletedCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_COMPLETED_COURSES });

      const courses = await CoursesService.getCompletedCourses(page);

      dispatch({
        type: LOAD_MORE_COMPLETED_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_COMPLETED_COURSES_FAILED });
      throw error;
    }
  };
};

export const getBookmarkedCourses = ({ page, language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BOOKMARKED_COURSES });

      const courses = await CoursesService.getBookmarkedCourses(page, language, token);

      dispatch({
        type: GET_BOOKMARKED_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: GET_BOOKMARKED_COURSES_FAILED });
      throw error;
    }
  };
};

export const resetBookmarkedCourses = () => {
  return { type: RESET_BOOKMARKED_COURSES };
};

export const loadMoreBookmarkedCourses = ({ page }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOAD_MORE_BOOKMARKED_COURSES });

      const courses = await CoursesService.getBookmarkedCourses(page);

      dispatch({
        type: LOAD_MORE_BOOKMARKED_COURSES_SUCCESS,
        payload: courses,
      });
    } catch (error) {
      dispatch({ type: LOAD_MORE_BOOKMARKED_COURSES_FAILED });
      throw error;
    }
  };
};

export const bookmarkCourse = ({ id, isBookmarked }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BOOKMARK_COURSE });

      await CoursesService.bookmarkCourse(id, isBookmarked);

      dispatch({
        type: BOOKMARK_COURSE_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: BOOKMARK_COURSE_FAILED });
      throw error;
    }
  };
};

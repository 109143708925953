import React from 'react';

import { Box, Container, Grid } from '@material-ui/core';

import OnYourScheduleContent from './OnYourScheduleContent';

import { useOnYourScheduleStyles } from './styles';

const OnYourSchedule = () => {
  const classes = useOnYourScheduleStyles();

  return (
    <Box className={classes.GetAppBox}>
      <Container maxWidth="lg">
        <Grid className={classes.GetAppContainer} container>
          <Grid className={classes.ContentBox} item md={6} sm={12}>
            <OnYourScheduleContent />
          </Grid>
          <Grid className={classes.ImageBox} item md={6} sm={12} />
        </Grid>
      </Container>
    </Box>
  );
};
export default OnYourSchedule;

import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, flex, grid } from 'constants/Jss';

export const useCardContainerStyles = makeStyles((theme) => ({
  ResultContainer: {
    display: flex,
    justifyContent: center,
    [theme.breakpoints.down('xl')]: {
      display: grid,
      '&.Course': {
        'grid-template-columns': 'repeat(auto-fill, minmax(270px, 1fr))',
      },
      '&.Lesson': {
        'grid-template-columns': 'repeat(auto-fill, minmax(340px, 1fr))',
      },
    },
  },

  LessonCardResultBox: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },

  LessonOrderNumber: {
    backgroundColor: theme.palette.greyF5,
    borderRadius: '11px',
    color: theme.palette.grey70,
    padding: '3px 10px',
    alignSelf: 'center',
  },
}));

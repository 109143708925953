import React from 'react';
import { useTranslation } from 'react-i18next';

import FormHelperText from '@material-ui/core/FormHelperText';

import get from 'lodash/get';

const ErrorMessage = ({ variant, form, field, className }) => {
  const { t } = useTranslation();

  const error = get(form.errors, field.name) || '';
  const touched = get(form.touched, field.name);

  return touched && error ? (
    <FormHelperText error variant={variant} className={className}>
      {error}
    </FormHelperText>
  ) : null;
};

export default ErrorMessage;

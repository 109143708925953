import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import { Box } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ErrorMessage from './ErrorMessage';
import { TextField } from '..';
import { bold, visible } from 'constants/Jss';

const useStyles = makeStyles((theme) => {
  return {
    MuiAutocompleteLoading: {
      color: theme.palette.common.white,
    },
    MuiAutocompletePaper: {
      backgroundColor: theme.palette.black,
      '& .MuiAutocomplete-option': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
      '& .MuiAutocomplete-option[data-focus="true"], & .MuiAutocomplete-option[aria-selected="true"]': {
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
      },
    },
    PopupIndicator: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      '&.White': {
        backgroundColor: theme.palette.grey70,
      },
    },
    Tag: {
      backgroundColor: theme.palette.primary.main,
      fontWeight: bold,
      '& svg': {
        color: theme.palette.common.black,
      },
    },
    ClearIndicator: {
      '&:hover': {
        backgroundColor: theme.palette.white,
      },
      '&.Filled': {
        visibility: visible,
      },
      '& svg': {
        fill: theme.palette.grey70,
      },
    },
  };
});

const AutoCompleteField = ({
  field,
  form,
  label,
  edgy,
  white,
  fullWidth,
  variant,
  showError = true,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box>
      <MUIAutocomplete
        value={field.value}
        id={field.name}
        classes={{
          clearIndicator: classNames(classes.ClearIndicator, { Filled: !!field.value }),
          popupIndicator: classNames(classes.PopupIndicator, { White: white }),
          tag: classNames(classes.Tag),
          paper: classes.MuiAutocompletePaper,
          loading: classes.MuiAutocompleteLoading,
        }}
        onChange={async (event, newValue) => {
          if (onChange) {
            onChange();
          }
          await form.setFieldValue(field.name, newValue);
          form.setFieldTouched(field.name, true);
        }}
        onInputChange={(event, value, reason) => {
          if (reason.toString() === 'clear') {
            form.setFieldValue(field.name, '');
          }
        }}
        name="provinceId"
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant={variant}
              name={field.name}
              label={label}
              edgy={edgy}
              white={white}
              fullWidth={fullWidth}
            />
          );
        }}
        {...props}
      />
      {showError && form && field ? (
        <ErrorMessage field={field} form={form} variant={variant} className={classes.error} />
      ) : null}
    </Box>
  );
};

AutoCompleteField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  onChange: PropTypes.func,
};

export default AutoCompleteField;

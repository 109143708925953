import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

import { absolute, relative } from 'constants/Jss';

export const useResponsivePlayerStyles = makeStyles((theme) => ({
  PlayerWrapper: { position: relative, paddingTop: '56.25%' },
  ReactPlayer: {
    position: absolute,
    top: 0,
    left: 0,
  },
}));

const VideoPlayer = ({ url, controls = true }) => {
  const classes = useResponsivePlayerStyles();

  return (
    <Box className={classes.PlayerWrapper}>
      <ReactPlayer className={classes.ReactPlayer} controls={controls} url={url} width={'100%'} height={'100%'} />
    </Box>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  controls: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default VideoPlayer;

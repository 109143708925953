import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'types/types';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

import { Switch } from 'components';
import { TagFilterSize } from 'constants/Constants';

import { useTagFilterStyles } from '../styles';

export const TagFilter = ({ tags, filter, setFilters, profileTags }) => {
  const { t } = useTranslation();
  const classes = useTagFilterStyles();

  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    if (
      filter.length &&
      profileTags &&
      isEqual(
        filter,
        profileTags.map((tag) => tag.id)
      )
    ) {
      setChecked(true);
    }
  }, []);

  const onCheckboxesChange = async (id) => {
    const checkedTagIds = [...filter];
    await setFilters({
      tags: checkedTagIds.includes(id) ? checkedTagIds.filter((checkedId) => checkedId !== id) : [...checkedTagIds, id],
    });
    setChecked(false);
  };

  const clearAllTags = () => {
    setFilters({ tags: [] });
    setChecked(false);
  };

  const showMyInterest = () => {
    setFilters({ tags: !checked ? profileTags.map((tag) => tag.id) : [] });
    setChecked(!checked);
  };

  return (
    <div className={classes.TagFilterWrapper}>
      <Box className={classes.QuickFilterContainer}>
        <Box className={classes.ClearBox}>
          <div className={classes.ActionText} onClick={clearAllTags} tabIndex={0} role="button">
            {t('topics_page_clear_filter')}
          </div>
        </Box>
        {profileTags && profileTags.length ? (
          <Box className={classes.InterestBox}>
            <div className={classes.ActionText} onClick={showMyInterest}>
              {t('topics_page_my_interest')}
            </div>
            <Box ml={2}>
              <Switch mini checked={checked} onClick={showMyInterest} ariaLabel={t('alt_show_my_interest')} />
            </Box>
          </Box>
        ) : null}
      </Box>
      <div
        className={classes.TagFilterContainer}
        style={{
          minWidth: Math.min(tags.length * 50, TagFilterSize.Narrow - 16),
        }}
      >
        {tags.map((tag) => (
          <FormControlLabel
            key={tag.id}
            control={
              <Checkbox
                color="primary"
                className={classes.Tag}
                checked={filter ? filter.includes(tag.id) : false}
                onChange={() => onCheckboxesChange(tag.id)}
              />
            }
            label={tag.title}
          />
        ))}
      </div>
    </div>
  );
};

TagFilter.propTypes = {
  tags: PropTypes.arrayOf(Tag),
  filter: PropTypes.arrayOf(PropTypes.number),
  setFilters: PropTypes.func,
  profileTags: PropTypes.arrayOf(Tag),
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import { Button, Dialog } from 'components';
import { FiltersType, Tag } from 'types/types';
import { MobileDurationFilter } from './MobileDurationFilter';
import { MobileTagFilter } from './MobileTagFilter';
import { MobileExamsFilter } from './MobileExamsFilter';

import { useTagFilterStyles } from '../styles';
import { useMobileFilterDialogStyles } from './styles';

export const MobileFilterDialog = ({ open, closeDialog, tags, filters, setFilters, profileTags, showFilters }) => {
  const { t } = useTranslation();
  const classes = useMobileFilterDialogStyles();
  const tagFilterClasses = useTagFilterStyles();

  const [openFilter, setOpenFilter] = React.useState('');
  const [filterValues, setFilterValues] = React.useState(filters);
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  const handleFilterChange = (filters) => {
    setFilterValues({ ...filterValues, ...filters });
  };

  const onTagsChange = (id) => {
    const checkedTagIds = [...filterValues.tags];
    handleFilterChange({
      tags: checkedTagIds.includes(id) ? checkedTagIds.filter((checkedId) => checkedId !== id) : [...checkedTagIds, id],
    });
    setChecked(false);
  };

  const clearAllTags = () => {
    handleFilterChange({ tags: [] });
    setChecked(false);
  };

  const showMyInterest = () => {
    handleFilterChange({ tags: !checked ? profileTags.map((tag) => tag.id) : [] });
    setChecked(!checked);
  };

  const onFilter = () => {
    setFilters(filterValues);
    closeDialog();
  };

  const onClose = () => {
    setFilterValues(filters);
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('common_filter')}
      titleClass={classes.Title}
      titleBoxClass={classes.TitleBox}
      contentClass={classes.Content}
      content={
        <div>
          {showFilters.includes('tag') && (
            <MobileTagFilter
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filterValues={filterValues}
              checked={checked}
              onTagsChange={onTagsChange}
              clearAllTags={clearAllTags}
              showMyInterest={showMyInterest}
              tags={tags}
              profileTags={profileTags}
            ></MobileTagFilter>
          )}

          {showFilters.includes('duration') && (
            <MobileDurationFilter
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filterValues={filterValues}
              handleFilterChange={handleFilterChange}
            ></MobileDurationFilter>
          )}

          {showFilters.includes('exams') && (
            <MobileExamsFilter
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              filterValues={filterValues}
              handleFilterChange={handleFilterChange}
            ></MobileExamsFilter>
          )}
          <Box mt={3} className={classes.FilterButtonContainer}>
            <Button onClick={onFilter}>{t('common_filtering')}</Button>
          </Box>
        </div>
      }
    />
  );
};

MobileFilterDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  tags: PropTypes.arrayOf(Tag),
  filters: FiltersType,
  setFilters: PropTypes.func,
  profileTags: PropTypes.arrayOf(Tag),
  showFilters: PropTypes.arrayOf(PropTypes.oneOf(['tag', 'duration', 'exams'])),
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { detectLanguage } from 'utils/language';
import { Course } from 'types/types';
import SymbolContainer from './SymbolContainer/SymbolContainer';
import { BasicCard } from 'components';

export const CourseCard = ({ course, className, history, onClick, skeleton = false }) => {
  const { t } = useTranslation();
  const { title = '', id, image, lessonProgress } = course || {};
  const tags = (course || {}).lessonTags || [];

  const redirectToCourseDetails = () => {
    if (!skeleton) {
      const language = detectLanguage(history.location.pathname).toLowerCase();
      history.push(`/${language}/${t('url_courses')}/${id}`);
    }
  };

  const onCourseCardClick = onClick ? onClick : redirectToCourseDetails;
  const ariaLabel = `${title} ${t('common_course')}`;

  return (
    <BasicCard
      className={className}
      title={title}
      ariaLabel={ariaLabel}
      onCardPress={onCourseCardClick}
      skeleton={skeleton}
      imageUrl={image}
      tags={tags}
      progress={lessonProgress}
      symbolContainer={<SymbolContainer course={course} />}
    />
  );
};

CourseCard.propTypes = {
  course: Course,
  className: PropTypes.string,
  history: PropTypes.object,
  skeleton: PropTypes.bool,
  onClick: PropTypes.func,
};

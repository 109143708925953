import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components';
import { useHomeTitleStyles } from './styles';

const HomeTitle = ({ isLoggedIn }) => {
  const classes = useHomeTitleStyles();
  const { t } = useTranslation();
  return (
    <Typography variant={'h1'} className={classnames(classes.BasicHomeTitle)}>
      {isLoggedIn ? t('home_page_logged_in_title') : t('home_page_not_logged_in_title')}
    </Typography>
  );
};

HomeTitle.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default HomeTitle;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { useFooterStyles } from './styles';
import { getPath } from 'utils/getPath';
import useScrollListener from 'utils/useScrollListener';
import { FooterLinks, ScrollToTopVisible, SocialLinks } from 'constants/Constants';
import { ExternalLink, LoginAnimation } from 'components';
import { Document } from 'types/types';
import { FacebookIcon, InstagramIcon, TwitterIcon } from 'assets/icons';
import Settings from 'env';

export const Footer = ({ pages, termsAndConditions, privacyPolicy, history }) => {
  const classes = useFooterStyles();
  const { t } = useTranslation();
  const [scrollToTopVisible, setScrollToTopVisible] = React.useState(false);

  const homePath = getPath(pages, t('menu_item_home'));

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: Settings.SCROLL_BEHAVIOR,
    });
  };

  const handleScrollToTop = () => {
    setScrollToTopVisible(
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0) > ScrollToTopVisible
    );
  };

  const handleHomeClick = (e) => {
    e.preventDefault(e);
    if (history.location.pathname === homePath) {
      scrollToTop();
    } else {
      history.push(homePath);
    }
  };
  useScrollListener(handleScrollToTop);

  return (
    <footer className={classes.Footer}>
      <Box className={classNames(classes.ScrollToTop, { Visible: scrollToTopVisible })} onClick={scrollToTop}>
        <ExpandLessIcon />
      </Box>
      <Box className={classes.MainContainer}>
        <Grid className={classes.MainGrid} container justify="space-around" alignItems="flex-start" xs={12}>
          <Grid className={classes.SocialBoxContainer} direction="row" container item xs={12}>
            <Grid
              item
              container
              className={classes.CopyRightContainer}
              xs={6}
              direction="column"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Box pl={3} mr={1} className={classNames(classes.CopyRightItem, 'NoPadding')}>
                <span>{`${t('footer_copyright', { year: new Date().getFullYear() })}`}</span>
              </Box>

              <Box pl={3} className={classes.CopyRightItem}>
                <ExternalLink
                  href={Settings.TERMS_AND_CONDITIONS}
                  target="_blank"
                  role="link"
                  aria-label={t('footer_terms_of_use')}
                >
                  {`${t('footer_terms_of_use')}`}
                </ExternalLink>
              </Box>

              <Box pl={3} className={classes.CopyRightItem}>
                <ExternalLink
                  href={Settings.PRIVACY_POLICY}
                  target="_blank"
                  role="link"
                  aria-label={t('footer_privacy_policy')}
                >
                  {t('footer_privacy_policy')}
                </ExternalLink>
              </Box>
            </Grid>
            <Grid
              item
              container
              className={classes.CopyRightContainer}
              xs={6}
              direction="column"
              justify="center"
              alignItems="flex-start"
            >
              <Box mr={1} className={classNames(classes.CopyRightItem, 'NoPadding')}>
                <span>{t('contact')}</span>
              </Box>

              <Box className={classes.CopyRightItem}>
                <span>{t('contact_phone')}</span>
              </Box>

              <Box className={classes.CopyRightItem}>
                <span className={classes.ContactEmail}>{t('contact_email')}</span>
              </Box>

              <Box className={classes.SocialBox}>
                <ExternalLink
                  href={SocialLinks.facebook}
                  className={classes.Logo}
                  target="_blank"
                  role="link"
                  aria-label={t('alt_slt_facebook')}
                >
                  <FacebookIcon useOriginalColors />
                </ExternalLink>
                <ExternalLink
                  href={SocialLinks.instagram}
                  className={classes.Logo}
                  target="_blank"
                  role="link"
                  aria-label={t('alt_slt_instagram')}
                >
                  <InstagramIcon useOriginalColors />
                </ExternalLink>
              </Box>
            </Grid>
          </Grid>
          <Grid className={classes.SLTLogoContainer} item xs={12} sm={1}>
            <Box className={classes.SLTLogo}>
              <LoginAnimation size="xs" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
};

Footer.propTypes = {
  isLogged: PropTypes.func,
  history: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  termsAndConditions: Document,
  privacyPolicy: Document,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { ClockCircularOutlineIcon } from 'assets/icons';
import { Indicator } from 'components';

export const useTimeIndicatorStyles = makeStyles((theme) => ({
  SmallIcon: {
    fontSize: 14,
  },
}));

const TimeIndicator = ({ minutes, smallIcon, ...props }) => {
  const { t } = useTranslation();
  const classes = useTimeIndicatorStyles();
  const text = minutes ? `${minutes} ${t('common_minutes')}` : t('not_provided_value');

  return (
    <Indicator
      {...props}
      iconClassName={classNames({ [classes.SmallIcon]: smallIcon })}
      icon={ClockCircularOutlineIcon}
      greyIcon
      text={text}
    />
  );
};

TimeIndicator.propTypes = { minutes: PropTypes.number, smallIcon: PropTypes.bool };

export default TimeIndicator;

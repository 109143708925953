import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Box } from '@material-ui/core';

import { ArrowDownIcon } from 'assets/icons';

import { useMobileFilterDialogStyles } from './styles';

export const MobileFilterHeader = ({ openFilter, setOpenFilter, altTitle, title, openType }) => {
  const classes = useMobileFilterDialogStyles();

  return (
    <Box
      className={classNames(classes.Header, {
        Open: openFilter === openType,
      })}
      onClick={() => (openFilter === openType ? setOpenFilter('') : setOpenFilter(openType))}
    >
      <div className={classes.HeaderTitle} tabIndex={0} role="button" aria-label={altTitle} aria-haspopup={true}>
        {title}
      </div>
      <ArrowDownIcon
        className={classNames(classes.ArrowIcon, {
          Open: openFilter === openType,
        })}
      />
    </Box>
  );
};

MobileFilterHeader.propTypes = {
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
  altTitle: PropTypes.string,
  title: PropTypes.string,
  openType: PropTypes.string,
};

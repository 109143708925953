import sltCardThematic from '../assets/images/slt_card_thematic.png';
import sltCardExpert from '../assets/images/slt_card_expert.png';
import sltCardSchedule from '../assets/images/slt_card_schedule.png';

const getSLTCardData = () => [
  {
    title: 'slt_cards_thematic_courses_title',
    description: 'slt_cards_thematic_courses_description',
    image: sltCardThematic,
  },
  {
    title: 'slt_cards_expert_instructions_title',
    description: 'slt_cards_expert_instructions_description',
    image: sltCardExpert,
  },
  {
    title: 'slt_cards_on_your_schedule_title',
    description: 'slt_cards_on_your_schedule_description',
    image: sltCardSchedule,
  },
];
export default getSLTCardData;

import { findCountry, findState } from 'utils/countryUtil';
import { getDateDifference } from 'utils/date';

export const isLegalDrinkingAge = (
  countries,
  birthDate,
  { country, state },
  queryAttributes = { country: 'country', state: 'state' }
) => {
  if (!country) {
    return false;
  }

  const age = getDateDifference('years')(new Date())(new Date(birthDate));
  const countryOfResidence = findCountry(countries, country.country, queryAttributes.country);

  const stateOfResidence = state
    ? countryOfResidence && countryOfResidence.states
      ? findState(countryOfResidence.states, state.state, queryAttributes.state)
      : null
    : null;
  return stateOfResidence ? age >= stateOfResidence.ageLimit : countryOfResidence && age >= countryOfResidence.ageLimit;
};

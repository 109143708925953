import moment from 'moment';

export const getDateString = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  return date.getFullYear() + '. ' + month + '. ' + day + '.';
};
export const getDateDifference = (unitofmeasuerement) => {
  return (date1) => (date2) => moment(date1).diff(moment(date2), unitofmeasuerement);
};

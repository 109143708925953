import {
  BOOKMARK_COURSE,
  BOOKMARK_COURSE_FAILED,
  BOOKMARK_COURSE_SUCCESS,
  GET_BOOKMARKED_COURSES,
  GET_BOOKMARKED_COURSES_FAILED,
  GET_BOOKMARKED_COURSES_SUCCESS,
  GET_COURSE_BY_ID,
  GET_COURSE_BY_ID_FAILED,
  GET_COURSE_BY_ID_SUCCESS,
  GET_COURSES,
  GET_COURSES_FAILED,
  GET_COURSES_BY_FILTER,
  GET_COURSES_BY_FILTER_SUCCESS,
  GET_COURSES_BY_FILTER_FAILED,
  GET_COURSES_FOR_TOPIC,
  GET_COURSES_FOR_TOPIC_FAILED,
  GET_COURSES_FOR_TOPIC_SUCCESS,
  GET_COURSES_SUCCESS,
  GET_COURSES_WITH_EXAMS,
  GET_COURSES_WITH_EXAMS_FAILED,
  GET_COURSES_WITH_EXAMS_SUCCESS,
  GET_ONGOING_COURSES,
  GET_ONGOING_COURSES_FAILED,
  GET_ONGOING_COURSES_SUCCESS,
  GET_COMPANY_COURSES,
  GET_COMPANY_COURSES_FAILED,
  GET_COMPANY_COURSES_SUCCESS,
  GET_COMPLETED_COURSES,
  GET_COMPLETED_COURSES_FAILED,
  GET_COMPLETED_COURSES_SUCCESS,
  LOAD_MORE_BOOKMARKED_COURSES,
  LOAD_MORE_BOOKMARKED_COURSES_FAILED,
  LOAD_MORE_BOOKMARKED_COURSES_SUCCESS,
  LOAD_MORE_COURSES,
  LOAD_MORE_COURSES_FAILED,
  LOAD_MORE_COURSES_SUCCESS,
  LOAD_MORE_COURSES_BY_FILTER,
  LOAD_MORE_COURSES_BY_FILTER_FAILED,
  LOAD_MORE_COURSES_BY_FILTER_SUCCESS,
  LOAD_MORE_COURSES_WITH_EXAMS,
  LOAD_MORE_COURSES_WITH_EXAMS_FAILED,
  LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS,
  LOAD_MORE_ONGOING_COURSES,
  LOAD_MORE_ONGOING_COURSES_FAILED,
  LOAD_MORE_ONGOING_COURSES_SUCCESS,
  LOAD_MORE_COMPANY_COURSES,
  LOAD_MORE_COMPANY_COURSES_FAILED,
  LOAD_MORE_COMPANY_COURSES_SUCCESS,
  LOAD_MORE_COMPLETED_COURSES,
  LOAD_MORE_COMPLETED_COURSES_FAILED,
  LOAD_MORE_COMPLETED_COURSES_SUCCESS,
  RESET_BOOKMARKED_COURSES,
  RESET_COURSES,
  RESET_FILTERED_COURSES,
  RESET_COURSES_WITH_EXAMS,
  RESET_ONGOING_COURSES,
  RESET_COMPANY_COURSES,
  RESET_COMPLETED_COURSES,
  SET_FILTERS,
  SET_SORT_BY,
  RESET_COURSE,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  courses: {
    count: 0,
    data: [],
  },

  filters: {
    tags: [],
    examDone: null,
    duration: null,
    searchString: null,
  },
  sortBy: 'title',
  course: null,
  coursesWithExams: {
    count: 0,
    data: [],
  },
  ongoingCourses: {
    count: 0,
    data: [],
  },
  companyCourses: { count: 0, data: [] },
  completedCourses: { count: 0, data: [] },
  popularCourses: { count: 0, data: [] },
  bookmarkedCourses: {
    count: 0,
    data: [],
  },
  filteredCourses: { count: 0, data: [] },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    case GET_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSES_BY_FILTER:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredCourses: action.payload,
      };
    case GET_COURSES_BY_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COURSES_FOR_TOPIC:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_FOR_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: action.payload,
      };
    case GET_COURSES_FOR_TOPIC_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_MORE_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        courses: {
          count: action.payload.count,
          data: [...state.courses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_MORE_COURSES_BY_FILTER:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COURSES_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredCourses: {
          count: action.payload.count,
          data: [...state.filteredCourses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COURSES_BY_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case RESET_COURSES:
      return {
        ...state,
        courses: {
          count: 0,
          data: [],
        },
        filteredCourses: {
          count: 0,
          data: [],
        },
      };

    case RESET_FILTERED_COURSES:
      return {
        ...state,
        filteredCourses: {
          count: 0,
          data: [],
        },
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          tags: action.payload.tags || state.filters.tags,
          examDone:
            action.payload.examDone === true || action.payload.examDone === false || action.payload.examDone === null
              ? action.payload.examDone
              : state.filters.examDone,
          duration:
            action.payload.duration || action.payload.duration === null
              ? action.payload.duration
              : state.filters.duration,
          searchString:
            action.payload.searchString || action.payload.searchString === null
              ? action.payload.searchString
              : state.filters.searchString,
        },
      };
    case SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case GET_COURSE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        course: action.payload,
      };
    case GET_COURSE_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_COURSE:
      return {
        ...state,
        course: null,
      };
    case GET_COURSES_WITH_EXAMS:
      return {
        ...state,
        loading: true,
      };
    case GET_COURSES_WITH_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        coursesWithExams: action.payload,
      };
    case GET_COURSES_WITH_EXAMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_COURSES_WITH_EXAMS:
      return {
        ...state,
        coursesWithExams: {
          count: 0,
          data: [],
        },
      };
    case LOAD_MORE_COURSES_WITH_EXAMS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COURSES_WITH_EXAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        coursesWithExams: {
          count: action.payload.count,
          data: [...state.coursesWithExams.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COURSES_WITH_EXAMS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_ONGOING_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_ONGOING_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        ongoingCourses: action.payload,
      };
    case GET_ONGOING_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COMPANY_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        companyCourses: action.payload,
      };
    case GET_COMPANY_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_COMPLETED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPLETED_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        completedCourses: action.payload,
      };
    case GET_COMPLETED_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_ONGOING_COURSES:
      return {
        ...state,
        ongoingCourses: {
          count: 0,
          data: [],
        },
      };
    case RESET_COMPANY_COURSES:
      return {
        ...state,
        companyCourses: {
          count: 0,
          data: [],
        },
      };
    case RESET_COMPLETED_COURSES:
      return {
        ...state,
        completedCourses: {
          count: 0,
          data: [],
        },
      };
    case LOAD_MORE_ONGOING_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_ONGOING_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        ongoingCourses: {
          count: action.payload.count,
          data: [...state.ongoingCourses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_ONGOING_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_MORE_COMPANY_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COMPANY_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        companyCourses: {
          count: action.payload.count,
          data: [...state.companyCourses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COMPANY_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_MORE_COMPLETED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_COMPLETED_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        completedCourses: {
          count: action.payload.count,
          data: [...state.completedCourses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_COMPLETED_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BOOKMARK_COURSE:
      return {
        ...state,
        loading: true,
      };
    case BOOKMARK_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BOOKMARK_COURSE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_BOOKMARKED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKMARKED_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        bookmarkedCourses: action.payload,
      };
    case GET_BOOKMARKED_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_BOOKMARKED_COURSES:
      return {
        ...state,
        bookmarkedCourses: {
          count: 0,
          data: [],
        },
      };
    case LOAD_MORE_BOOKMARKED_COURSES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_BOOKMARKED_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        bookmarkedCourses: {
          count: action.payload.count,
          data: [...state.bookmarkedCourses.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_BOOKMARKED_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import React from 'react';
import { useDraggable } from '@dnd-kit/core';

const DraggableWrapper = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      {children(isDragging)}
    </div>
  );
};

export default DraggableWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Box } from '@material-ui/core';

import { Tag } from 'types/types';
import ImageContainer from './ImageContainer/ImageContainer';
import TitleContainer from './TitleContainer/TitleContainer';
import TagContainer from './TagContainer/TagContainer';
import ProgressBar from './ProgressBar/ProgressBar';

import { useCardStyles } from './styles';

const BasicCard = ({
  className,
  title,
  ariaLabel,
  onCardPress,
  progress,
  imageUrl,
  skeleton,
  tags,
  symbolContainer,
}) => {
  const classes = useCardStyles();
  const isRegular = !skeleton;

  return (
    <>
      <div
        className={classNames(classes.BasicCard, classes.DesktopCard, className)}
        onClick={onCardPress}
        tabIndex={0}
        aria-label={ariaLabel}
        role="link"
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onCardPress();
          }
        }}
      >
        <ImageContainer isRegular={isRegular} imageUrl={imageUrl} />
        <div className={classNames(classes.BasicInfoContainer, classes.InfoContainer)}>
          <Box mb={1}>{symbolContainer}</Box>
          <Box mb={1}>
            <ProgressBar progress={progress} />
          </Box>
          <TitleContainer isRegular={isRegular} title={title} />
          <TagContainer isRegular={isRegular} tags={tags} skeleton={skeleton} />
        </div>
      </div>
      <div
        className={classNames(classes.BasicCard, classes.MobileCard, className)}
        onClick={onCardPress}
        tabIndex={0}
        aria-label={ariaLabel}
        role="link"
      >
        <div className={classNames(classes.BasicInfoContainer, classes.MobileInfoContainer)}>
          <Box mb={1}>{symbolContainer}</Box>
          <Box mb={1}>
            <ProgressBar progress={progress} />
          </Box>
          <TitleContainer isRegular={isRegular} title={title} imageUrl={imageUrl} />
          <TagContainer mobile isRegular={isRegular} tags={tags} skeleton={skeleton} />
        </div>
      </div>
    </>
  );
};

BasicCard.propType = {
  className: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
  onCardPress: PropTypes.func,
  skeleton: PropTypes.bool,
  progress: PropTypes.number,
  imageUrl: PropTypes.string,
  tags: PropTypes.arrayOf(Tag),
  symbolContainer: PropTypes.node,
};

export default BasicCard;

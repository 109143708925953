import PropTypes from 'prop-types';
import { LessonContentTypes, QuestionTypes } from 'constants/Constants';
import { LessonStatus } from 'utils/lessonStatus';

export const Tag = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
});

export const CheckListItem = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
});

export const ImageObject = PropTypes.shape({
  id: PropTypes.number.isRequired,
  data: PropTypes.string,
});

export const Lesson = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  images: PropTypes.arrayOf(ImageObject),
  status: PropTypes.oneOf(Object.values(LessonStatus)),
  result: PropTypes.number,
  tags: PropTypes.arrayOf(Tag),
  testMinutes: PropTypes.number,
});

export const TopListUser = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  avatar: PropTypes.string,
  group: PropTypes.string,
  score: PropTypes.number.isRequired,
  userPosition: PropTypes.number.isRequired,
});

//TODO: Need to adjust after specification
export const TopListGroup = PropTypes.shape({
  name: PropTypes.string,
  membersCount: PropTypes.number,
  userPosition: PropTypes.number,
  score: PropTypes.number,
});

export const Course = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isBookmark: PropTypes.bool,
  isImportant: PropTypes.bool,
  lessonsCount: PropTypes.number,
  examResult: PropTypes.number,
  examSuccess: PropTypes.bool,
  lessonTags: PropTypes.arrayOf(Tag),
  examMinutes: PropTypes.number,
  lessonProgress: PropTypes.number,
  description: PropTypes.string,
  requirement: PropTypes.string,
  earnedXp: PropTypes.number,
  isExamAvailable: PropTypes.bool,
  isKnowledgeTestAvailable: PropTypes.bool,
  lessons: PropTypes.arrayOf(Lesson),
  level: PropTypes.number,
  public: PropTypes.bool,
});

export const LessonWithCourses = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  testSuccess: PropTypes.bool,
  testResult: PropTypes.number,
  lessonStarted: PropTypes.bool,
  minutesToComplete: PropTypes.number,
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    })
  ),
});

export const FiltersType = PropTypes.shape({
  tags: PropTypes.arrayOf(PropTypes.number),
  examDone: PropTypes.bool,
  duration: PropTypes.shape({
    minutesMin: PropTypes.number,
    minutesMax: PropTypes.number,
  }),
});

export const Topic = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const Profile = PropTypes.shape({
  name: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string,
});

export const Group = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const Settlement = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

export const Notification = PropTypes.shape({
  translateKey: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
});

export const ProfileDetails = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  group: Group,
  avatar: PropTypes.string,
  avatarFull: PropTypes.string,
  role: PropTypes.number,
  score: PropTypes.number,
  settlement: Settlement,
  tags: PropTypes.arrayOf(Tag),
  firstLoginDate: PropTypes.string,
});

export const LessonSpecific = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.oneOf(Object.values(LessonContentTypes)),
});

export const LessonDetails = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  languageId: PropTypes.string,
  specifics: PropTypes.arrayOf(LessonSpecific),
  hasTest: PropTypes.bool,
});

export const Answer = PropTypes.shape({
  id: PropTypes.number.isRequired,
  answer: PropTypes.string.isRequired,
  isGood: PropTypes.bool,
});

export const Exercise = PropTypes.shape({
  id: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(Answer),
  image: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  sec: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(QuestionTypes)),
});

export const TestOrExam = PropTypes.shape({
  id: PropTypes.number,
  courseId: PropTypes.number.isRequired,
  minutes: PropTypes.number.isRequired,
  exercises: PropTypes.arrayOf(Exercise),
});

export const State = PropTypes.shape({
  id: PropTypes.number,
  state: PropTypes.string.isRequired,
  ageLimit: PropTypes.number.isRequired,
});

export const Country = PropTypes.shape({
  id: PropTypes.number,
  country: PropTypes.string.isRequired,
  ageLimit: PropTypes.number.isRequired,
  states: PropTypes.arrayOf(State),
});

export const Tab = PropTypes.shape({
  name: PropTypes.string.isRequired,
  component: PropTypes.node,
});

export const Badge = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
});

export const Document = PropTypes.shape({
  id: PropTypes.number,
  fileName: PropTypes.string,
  type: PropTypes.number,
  url: PropTypes.string,
  createdAt: PropTypes.string,
});

export const PathCardType = PropTypes.shape({
  id: PropTypes.number,
  image: PropTypes.string,
  title: PropTypes.string,
  owner: PropTypes.string,
  lessonCount: PropTypes.number,
  pathProgress: PropTypes.number,
});

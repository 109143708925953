import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

const CardWrapper = ({ children, className }) => {
  return (
    <Box mb={6} ml={1.5} mr={1.5} className={className}>
      {children}
    </Box>
  );
};

CardWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default CardWrapper;

import makeStyles from '@material-ui/core/styles/makeStyles';
import { absolute, center, flex, none, pointer, relative, transparent } from 'constants/Jss';

export const useDesktopFilterStyles = makeStyles((theme) => ({
  Filters: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  Form: {
    height: theme.mixins.header.contentSize,
    position: relative,
    display: flex,
    flexGrow: 1,
  },
  SearchIconContainer: {
    cursor: pointer,
    width: theme.mixins.header.contentSize,
    height: theme.mixins.header.contentSize,
    '&:hover': {
      backgroundColor: theme.palette.whiteHover,
    },
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& svg': {
      fill: theme.palette.grey70,
    },
  },
  SearchField: {
    fontSize: 16,
    color: theme.palette.grey70,
    flexGrow: 1,
  },
  InputField: {
    paddingRight: theme.spacing(5),
    minWidth: 235,
  },
  CloseSearch: {
    position: absolute,
    top: 12,
    right: 14,
    cursor: pointer,
    padding: 0,
    color: transparent,
    '&:hover': {
      backgroundColor: transparent,
    },
    '&:focus': {
      backgroundColor: transparent,
    },
    '& svg': {
      width: 20,
      height: 20,
      fill: theme.palette.grey70,
    },
  },
  FilterBoxContainer: {
    borderLeftWidth: 0,
    display: flex,
    alignItems: center,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  ArrowIcon: {
    color: theme.palette.white,
  },
}));

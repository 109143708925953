import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/users';

export const getProfileDetails = async () => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/me`,
  };
  return await request(requestData);
};

export const updateProfile = async (userId, data) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${userId}/profile`,
    data,
  });
};

export const changePassword = async (userId, { oldPassword, newPassword }) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/${userId}/profile/change-password`,
    data: {
      oldPassword,
      newPassword,
    },
  });
};

export const refreshToken = async (token) => {
  return await request({
    method: Methods.POST,
    resource: '/auth/login/refresh',
    data: {
      token,
    },
  });
};

export const getProvinces = async () => {
  return await request({
    method: Methods.GET,
    resource: `/provinces`,
  });
};

export const deleteProfile = async () => {
  return await request({
    method: Methods.DELETE,
    resource: BASE_URL,
  });
};

export const getBadges = async (language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/user-badges`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

import { auto, emptyString } from 'constants/Jss';

const useCarouselStyles = makeStyles((theme) => ({
  WrapperBox: {
    paddingBottom: theme.spacing(10),
  },
  BaseSlider: {
    '& .slick-list': {
      paddingBottom: theme.spacing(10),
      '& .slick-track': {
        marginLeft: auto,
        marginRight: auto,
      },
    },
    '& .slick-dots li button:before': {
      border: `1px solid ${theme.palette.grey70}`,
      backgroundColor: theme.palette.grey70,
      borderRadius: '50%',
      color: theme.palette.grey70,
      fontSize: 28,
      content: emptyString,
    },
    '& .slick-dots li.slick-active button:before': {
      backgroundColor: theme.palette.common.white,
      fontSize: 28,
      content: emptyString,
    },
  },
}));

const Carousel = ({ itemList, itemWrapperClassName, settings = {} }) => {
  const classes = useCarouselStyles();
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const mergedSettings = { ...defaultSettings, ...settings };
  return (
    <Slider className={classes.BaseSlider} {...mergedSettings}>
      {itemList.map((item) => (
        <Box key={uuidv4()} className={itemWrapperClassName}>
          {item}
        </Box>
      ))}
    </Slider>
  );
};

Carousel.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.node).isRequired,
  itemWrapperClassName: PropTypes.string,
  settings: PropTypes.object,
};

export default Carousel;

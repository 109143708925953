import { get } from 'lodash';

const findItemByAttribute = (array = [], queriedValue, attributeName) =>
  array ? array.find((item) => get(item, attributeName) === queriedValue) : null;

export const findCountry = (countries, query, countryAttributeName = 'country') =>
  findItemByAttribute(countries, query, countryAttributeName);

export const findState = (states, query, stateAttributeName = 'state') =>
  findItemByAttribute(states, query, stateAttributeName);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useProgressBarStyles = makeStyles((theme) => ({
  ProgressBar: {
    '&.MuiLinearProgress-root': {
      height: '7px',
      borderRadius: '3.5px',
    },
  },
}));

const ProgressBar = ({ progress, className, ...props }) => {
  const classes = useProgressBarStyles();
  return (
    <LinearProgress
      className={classNames(classes.ProgressBar, className)}
      variant="determinate"
      value={progress}
      color="secondary"
      {...props}
    />
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number,
  className: PropTypes.string,
};

export default ProgressBar;

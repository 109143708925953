import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Field, Formik, Form } from 'formik';

import Box from '@material-ui/core/Box';

import {
  Button,
  Dialog,
  CountryAndStateChooser,
  TextField,
  PasswordTextField,
  DatePickerField,
  AcceptTermsField,
} from 'components';
import { auto } from 'constants/Jss';
import { Regex } from 'constants/Constants';
import Settings from 'env';
import { isLegalDrinkingAge } from 'utils/validation/legalDrinkingAge';

import { useRegisterDialogStyles } from './styles';

const RegisterDialog = ({
  open,
  closeDialog,
  submit,
  provinces,
  isLoadingProvinces,
  isLoadingAuth,
  removeNotification,
  showNotification,
}) => {
  const { t } = useTranslation();
  const classes = useRegisterDialogStyles();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('form_invalid_email')).required(t('form_required')),
    firstName: Yup.string()
      .required(t('form_required'))
      .test('name-length', t('form_name_length'), (value) => !!value && value.length > 2 && value.length < 256),
    lastName: Yup.string()
      .required(t('form_required'))
      .test('name-length', t('form_name_length'), (value) => !!value && value.length > 2 && value.length < 256),
    password: Yup.string().required(t('form_required')).matches(Regex.Password, t('form_password_strength')),
    birthDate: Yup.object()
      .required(t('form_required'))
      .nullable()
      .test('country-already-chosen', t('form_country_required_for_legal_drinking_age'), function () {
        return this.parent && this.parent.province;
      })
      .test('legal-drinking-age', t('form_legal_drinking_age'), function (value) {
        return isLegalDrinkingAge(provinces, value, {
          country: this.parent.province,
          state: this.parent.state,
        });
      }),
    province: Yup.object().nullable().required(t('form_required')),
    state: Yup.object()
      .nullable()
      .when('province', { is: (value) => !!(value && value.states), then: Yup.object().required(t('form_required')) }),
    acceptTerms: Yup.mixed().oneOf([true], t('form_required_terms_and_privacy_policy')),
  });

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        removeNotification();
        closeDialog(e);
      }}
      title={t('menu_item_register')}
      contentClass={classes.TooltipDialog}
      aria-modal={true}
      role="dialog"
      aria-labelledby="RegisterButton"
      primaryColor
      closeWithText
      scroll="paper"
      content={
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            birthDate: null,
            province: null,
            state: null,
            acceptTerms: false,
          }}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnMount={false}
          onSubmit={submit}
        >
          {({ handleSubmit, errors, values, handleChange, setFieldTouched, setFieldValue, touched }) => (
            <Form>
              <Box className={classes.FormBox} ml={auto} mr={auto}>
                <Box mb={3}>
                  <Field
                    component={TextField}
                    name="email"
                    label={t('form_email')}
                    autoComplete="off"
                    fullWidth
                    InputProps={{ 'aria-label': t('form_email') }}
                  />
                </Box>
                <Box mb={3}>
                  <Field component={PasswordTextField} name="password" />
                </Box>
                <Box mb={3}>
                  <Field
                    component={TextField}
                    name="firstName"
                    label={t('form_first_name')}
                    autoComplete="off"
                    fullWidth
                    InputProps={{ 'aria-label': t('form_first_name') }}
                  />
                </Box>
                <Box mb={3}>
                  <Field
                    component={TextField}
                    name="lastName"
                    label={t('form_last_name')}
                    autoComplete="off"
                    fullWidth
                    InputProps={{ 'aria-label': t('form_last_name') }}
                  />
                </Box>
                <Box mb={3} className={classes.FieldBox}>
                  <CountryAndStateChooser
                    isLoadingCountries={isLoadingProvinces}
                    countryFieldName="province"
                    stateFieldName="state"
                    countries={provinces}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Box>
                <Box mb={3} className={classes.FieldBox}>
                  <Field fullWidth component={DatePickerField} name="birthDate" label={t('form_birthDate')} />
                </Box>

                <Box mb={3}>
                  <AcceptTermsField
                    name="acceptTerms"
                    value={values.acceptTerms}
                    onChange={handleChange}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    touched={touched}
                    showNotification={showNotification}
                    termsAndConditions={{ url: Settings.PRIVACY_POLICY }}
                    privacyPolicy={{ url: Settings.TERMS_AND_CONDITIONS }}
                  />
                </Box>
                <Box mb={1}>
                  <Button
                    loading={isLoadingAuth}
                    className={classes.RegisterButtonContainer}
                    buttonClassName="Pink"
                    onClick={handleSubmit}
                    type="submit"
                    id="RegisterButton"
                    role="button"
                    aria-label={t('form_register_button_text')}
                    fullWidth
                    large
                  >
                    {t('form_register_button_text')}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

RegisterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  provinces: PropTypes.array.isRequired,
  isLoadingProvinces: PropTypes.bool,
  isLoadingAuth: PropTypes.bool,
};

export default RegisterDialog;

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { Button, Dialog, PasswordTextField, AcceptTermsField, TextField } from 'components';
import { auto } from 'constants/Jss.js';
import { useLoginDialogStyles } from './styles';
import { Regex } from 'constants/Constants';
import Settings from 'env';

const ResetPasswordDialog = ({ open, closeDialog, submit, t, isNewUser, token, showNotification }) => {
  const schema = {
    password: Yup.string().required(t('form_required')).matches(Regex.Password, t('form_password_strength')),
    passwordConfirmation: Yup.string()
      .required(t('form_required'))
      .oneOf([Yup.ref('password'), null], t('form_passwords_must_match')),
  };

  if (isNewUser) {
    schema.acceptTerms = Yup.mixed().oneOf([true], t('form_required'));
  }

  const classes = useLoginDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      title={t('form_title_reset_password')}
      primaryColor
      content={
        <Formik
          initialValues={{ password: '', passwordConfirmation: '', acceptTerms: false }}
          validationSchema={Yup.object().shape(schema)}
          validateOnMount={false}
          onSubmit={(values) => submit({ password: values.password, token })}
        >
          {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
            <form onSubmit={() => {}}>
              <Box className={classes.FormBox} ml={auto} mr={auto}>
                <Box mb={3}>
                  <Field component={PasswordTextField} name="password" />
                </Box>
                <Box mb={3}>
                  <Field component={PasswordTextField} name="passwordConfirmation" label={t('form_confirm_password')} />
                </Box>
                {isNewUser ? (
                  <Box mb={3}>
                    <AcceptTermsField
                      name="acceptTerms"
                      value={values.acceptTerms}
                      onChange={handleChange}
                      setFieldTouched={setFieldTouched}
                      errors={errors}
                      touched={touched}
                      showNotification={showNotification}
                      termsAndConditions={{ url: Settings.PRIVACY_POLICY }}
                      privacyPolicy={{ url: Settings.TERMS_AND_CONDITIONS }}
                    />
                  </Box>
                ) : null}
                <Box mb={4} className={classes.AlignCenter}>
                  <Button
                    buttonClassName="Pink"
                    onClick={handleSubmit}
                    type="submit"
                    role="button"
                    aria-label={t('common_post')}
                    fullWidth
                    large
                  >
                    {t('common_post')}
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      }
    />
  );
};

ResetPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  isNewUser: PropTypes.bool,
  showNotification: PropTypes.func.isRequired,
};

export default withTranslation()(ResetPasswordDialog);

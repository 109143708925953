import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout } from 'store/profile/actions';
import App from './App';
import { bindActionCreators } from 'redux';
import { removeNotification } from 'store/common/actions';
import { getProfileDetails } from 'store/profile/actions';

const mapStateToProps = (state) => {
  return {
    profileDetails: state.profile.details,
    loading: state.loader.loading,
    notification: state.common.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getProfileDetails, logout, removeNotification }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

import React from 'react';
import classNames from 'classnames';

import MuiTextField from '@material-ui/core/TextField';
import get from 'lodash/get';

import ErrorMessage from './ErrorMessage';
import { useTextFieldStyles } from './styles';

const TextField = ({
  field,
  edgy,
  form,
  fullWidth,
  showError = true,
  readOnly,
  variant = 'outlined',
  white,
  whiteError,
  value,
  ...props
}) => {
  const classes = useTextFieldStyles();

  return (
    <div
      className={classNames(classes.BaseTextField, {
        [classes.fullWidth]: fullWidth,
        [classes.WhiteTextField]: white,
        [classes.EdgyTextField]: edgy,
      })}
    >
      <MuiTextField
        {...(field || {})}
        {...props}
        variant={variant}
        InputProps={{
          ...(props.InputProps || {}),
          readOnly: (props.InputProps || {}).readOnly || readOnly || false,
        }}
        value={field && field.value ? field.value : value || ''}
        fullWidth={fullWidth}
        error={Boolean(form && field && get(form.errors, field.name) && get(form.touched, field.name))}
      />
      {showError && form && field ? (
        <ErrorMessage
          field={field}
          form={form}
          variant={variant}
          className={classNames(classes.error, { [classes.WhiteErrorMessage]: whiteError })}
        />
      ) : null}
    </div>
  );
};

export default TextField;

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useConfirmModalStyles = makeStyles((theme) => ({
  DeleteModalText: {
    textAlign: 'center',
    marginBottom: 50,
  },
  DeleteModalButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

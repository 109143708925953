import React from 'react';
import PropTypes from 'prop-types';
import MuiTypography from '@material-ui/core/Typography';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

const NORMAL_LINE_SPACING_NAME = 'normal';
const MEDIUM_LINE_SPACING_NAME = 'medium';
const LARGE_LINE_SPACING_NAME = 'large';

const FONT_WEIGHT_BOLD = 'Bold';
const FONT_WEIGHT_EXTRA_BOLD = 'ExtraBold';

const useTypographyStyles = makeStyles((theme) => ({
  Typography: {
    '&.Grey': {
      color: theme.palette.grey1A,
    },
    '&.Grey70': {
      color: theme.palette.grey70,
    },
    '&.Yellow': {
      color: theme.palette.yellow,
    },
    '&.Green': {
      color: theme.palette.green,
    },
  },
  [NORMAL_LINE_SPACING_NAME]: {
    lineHeight: '100%',
  },
  [MEDIUM_LINE_SPACING_NAME]: {
    lineHeight: '160%',
  },
  [LARGE_LINE_SPACING_NAME]: {
    lineHeight: '180%',
  },
  [FONT_WEIGHT_BOLD]: {
    fontWeight: 600,
  },
  [FONT_WEIGHT_EXTRA_BOLD]: {
    fontWeight: 900,
  },
}));

const Typography = ({ lineHeight, fontWeight, className, ...props }) => {
  const classes = useTypographyStyles();
  return (
    <MuiTypography
      className={classNames(classes.Typography, classes[lineHeight], classes[fontWeight], className)}
      {...props}
    />
  );
};

Typography.propTypes = {
  lineHeight: PropTypes.oneOf([NORMAL_LINE_SPACING_NAME, MEDIUM_LINE_SPACING_NAME, LARGE_LINE_SPACING_NAME]),
  fontWeight: PropTypes.oneOf([FONT_WEIGHT_BOLD, FONT_WEIGHT_EXTRA_BOLD]),
};

export default Typography;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Box, Container } from '@material-ui/core';

import { Button, Typography } from 'components';
import ImageAndContentBox from '../ImageAndContentBox/ImageAndContentBox';
import newSkillsImage from 'assets/images/group-working.jpg';

import { useNewSkillsStyles } from './styles';

const NewSkills = ({ isLoggedIn, onButtonClick }) => {
  const { t } = useTranslation();
  const classes = useNewSkillsStyles();

  return (
    <Container className={classes.NewSkillsContainer} maxWidth="lg">
      <ImageAndContentBox
        background={`url("${newSkillsImage}")`}
        imageFirst
        contentComponent={
          <Box>
            <Typography className={classes.NewSkillsTitle} variant="h2" color="textSecondary">
              {t('new_skills_box_title')}
            </Typography>
            <Typography
              className={classnames(classes.NewSkillsDescription, 'Grey')}
              variant="body1"
              lineHeight="medium"
            >
              {t('new_skills_box_description')}
            </Typography>
          </Box>
        }
      />
    </Container>
  );
};

NewSkills.propTypes = {
  isLoggedIn: PropTypes.bool,
  onButtonClick: PropTypes.func,
};

export default NewSkills;

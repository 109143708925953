import React from 'react';
import PropTypes from 'prop-types';

import { NoItemsFound } from 'components';

const withNoItems = (Component) => {
  const wrappedWithNoItem = ({ isLoading, isEmpty, emptyMessage, ...remainingProps }) => {
    return isLoading || !isEmpty ? (
      <Component isLoading={isLoading} {...remainingProps} />
    ) : (
      <NoItemsFound message={emptyMessage} />
    );
  };

  wrappedWithNoItem.propTypes = { isLoading: PropTypes.bool, isEmpty: PropTypes.bool, emptyMessage: PropTypes.string };
  return wrappedWithNoItem;
};

export default withNoItems;

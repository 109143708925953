import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Cookies from 'js-cookie';
import MomentUtils from '@date-io/moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import renderRoutes from 'utils/renderRoutes';
import { column, flex, relative } from 'constants/Jss';
import Header from 'modules/Header';
import { Footer } from 'modules/Footer/Footer';
import Settings from 'env';
import { Document } from 'types/types';

const styles = (theme) => ({
  Main: {
    display: flex,
    flexDirection: column,
    minHeight: '100vh',
  },
  mainContent: {
    width: '100%',
    flexGrow: 1,
    display: flex,
    flexDirection: column,
  },
  transitionGroup: {
    position: relative,
    display: flex,
    flexDirection: column,
    flexGrow: 1,
  },
  routeSection: {
    width: '100%',
    display: flex,
    flexDirection: column,
    flex: 1,
  },
});

class Main extends Component {
  static propTypes = {
    routes: PropTypes.array.isRequired,
    tokenExpiresAt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
    getAuthorizedRoutes: PropTypes.func,
    termsAndConditions: Document,
    privacyPolicy: Document,
  };

  isLoggedIn = () => !!(Cookies.get(Settings.API_TOKEN_EXPIRES_AT) || this.props.tokenExpiresAt);

  render() {
    const {
      classes,
      routes,
      location,
      history,
      topics,
      getAuthorizedRoutes,
      termsAndConditions,
      privacyPolicy,
    } = this.props;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.Main}>
          <Header
            pages={routes}
            history={history}
            isLoggedIn={this.isLoggedIn}
            getAuthorizedRoutes={getAuthorizedRoutes}
          />
          {routes ? (
            <div className={classNames(classes.mainContent)}>
              {renderRoutes(routes, classes, location, { isLoggedIn: this.isLoggedIn, getAuthorizedRoutes })}
            </div>
          ) : null}
          <Footer
            isLoggedIn={this.isLoggedIn}
            history={history}
            pages={routes}
            topics={topics}
            termsAndConditions={termsAndConditions}
            privacyPolicy={privacyPolicy}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(Main);

import React from 'react';
import classNames from 'classnames';

import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      userSelect: 'none',
      width: '1em',
      height: '1em',
      display: 'inline-block',

      flexShrink: 0,
      fontSize: theme.typography.pxToRem(18),
      transition: theme.transitions.create('fill', {
        duration: theme.transitions.duration.shorter,
      }),
    },
    colorOverride: {
      fill: 'currentColor',
      '& *': {
        fill: 'currentColor !important',
      },
    },
    colorPrimary: {
      color: theme.palette.primary.main,
    },
    colorSecondary: {
      color: theme.palette.secondary.main,
    },
    colorAction: {
      color: theme.palette.action.active,
    },
    colorError: {
      color: theme.palette.error.main,
    },
    colorDisabled: {
      color: theme.palette.action.disabled,
    },
    fontSizeInherit: {
      fontSize: 'inherit',
    },
    fontSizeSmall: {
      fontSize: theme.typography.pxToRem(16),
    },
    fontSizeLarge: {
      fontSize: theme.typography.pxToRem(24),
    },
  })
);

const capitalize = (s) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const SvgIcon = React.forwardRef(
  ({ component: Component, className, useOriginalColors, fontSize = 'default', color = 'inherit', ...props }, ref) => {
    const classes = useStyles();
    return (
      <Component
        focusable="false"
        className={classNames(
          classes.root,
          {
            [classes.colorOverride]: !useOriginalColors,
            [classes[`color${capitalize(color)}`]]: color !== 'inherit',
            [classes[`fontSize${capitalize(fontSize)}`]]: fontSize !== 'default',
          },
          className
        )}
        {...props}
        ref={ref}
      />
    );
  }
);

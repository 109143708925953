import * as TagService from 'services/tag';
import {
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILED,
  GET_PROFILE_TAGS_FAILED,
  GET_PROFILE_TAGS,
  GET_PROFILE_TAGS_SUCCESS,
  ADD_PROFILE_TAGS_FAILED,
  ADD_PROFILE_TAGS,
} from './types';

export const getTags = (searchText, page) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_TAGS });

      const tags = await TagService.getTags({ searchText, page });

      dispatch({
        type: GET_TAGS_SUCCESS,
        payload: tags,
      });

      return tags;
    } catch (error) {
      dispatch({ type: GET_TAGS_FAILED });
      throw error;
    }
  };
};

export const getProfileTags = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROFILE_TAGS });

      const tags = await TagService.getProfileTags();

      dispatch({
        type: GET_PROFILE_TAGS_SUCCESS,
        payload: tags,
      });

      return tags;
    } catch (error) {
      dispatch({ type: GET_PROFILE_TAGS_FAILED });
      throw error;
    }
  };
};

export const addProfileTags = (tagIdList) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_PROFILE_TAGS });

      await TagService.addProfileTags(tagIdList);

      await dispatch(getProfileTags());
    } catch (error) {
      dispatch({ type: ADD_PROFILE_TAGS_FAILED });
      throw error;
    }
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from 'components';
import SymbolContainer from './SymbolContainer';

import { useCardStyles } from './styles';

const CourseAndLessonCard = ({ title, imageUrl, lessonCount, minutes, bookMark, disabled, isDragging }) => {
  const classes = useCardStyles();

  return (
    <div className={classNames(classes.BasicCard, { [classes.Disabled]: disabled, [classes.IsDragging]: isDragging })}>
      <div className={classes.ImageContainer}>
        <Image src={imageUrl} className={classes.Image} square alt="" aria-label="" aria-hidden />
      </div>
      <div className={classes.InfoContainer}>
        <SymbolContainer lessonCount={lessonCount} minutes={minutes} bookMark={bookMark} />
        <div className={classes.Title}>{title}</div>
      </div>
    </div>
  );
};

CourseAndLessonCard.propType = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  lessonCount: PropTypes.number,
  minutes: PropTypes.number,
  bookMark: PropTypes.bool,
  disabled: PropTypes.bool,
  isDragging: PropTypes.bool,
};

export default CourseAndLessonCard;

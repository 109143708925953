import {
  AGREE_DOCUMENTS,
  AGREE_DOCUMENTS_FAILED,
  AGREE_DOCUMENTS_SUCCESS,
  CHECK_DOCUMENTS,
  CHECK_DOCUMENTS_FAILED,
  CHECK_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENTS_SUCCESS,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  documents: { termsAndConditions: {}, privacyPolicy: {} },
  newDocuments: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
      };
    case GET_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CHECK_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case CHECK_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        newDocuments: action.payload,
      };
    case CHECK_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AGREE_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case AGREE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        newDocuments: false,
      };
    case AGREE_DOCUMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, cover, noRepeat } from 'constants/Jss';

export const useImageAndContentBoxStyles = makeStyles((theme) => ({
  ImageAndContentContainer: (props) => ({
    [theme.breakpoints.up('sm')]: {
      [props.imageFirst ? '& > :last-child' : '& > :first-child']: {
        [props.imageFirst ? 'paddingLeft' : 'paddingRight']: props.contentPadding || 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > :first-child': {
        order: props.invertOrderWhenBroke ? 2 : 1,
        [`margin${props.invertOrderWhenBroke ? 'Top' : 'Bottom'}`]: props.contentPadding,
      },
      '& > :last-child': {
        order: props.invertOrderWhenBroke ? 1 : 2,
        paddingLeft: theme.spacing(0),
      },
    },
  }),
  ImageBox: (props) => ({
    backgroundSize: cover,
    backgroundPosition: 'center center',
    backgroundImage: props.background,
    backgroundRepeat: noRepeat,
    width: '100%',
    maxHeight: props.imageMaxHeight,
    [theme.breakpoints.up('md')]: {
      minHeight: props.imageMinHeight,
    },
    [theme.breakpoints.down('sm')]: {
      height: props.imageMaxHeightOnMobile,
    },
  }),
  ContentBox: {
    [theme.breakpoints.down('sm')]: {
      textAlign: center,
    },
  },
}));

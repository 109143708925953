import makeStyles from '@material-ui/core/styles/makeStyles';
import background from 'assets/images/mobile.png';

import { auto, block, center, cover, none, noRepeat, pointer, inlineBlock, spaceAround } from 'constants/Jss';

export const useOnYourScheduleStyles = makeStyles((theme) => ({
  GetAppBox: {
    backgroundImage: `linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%), url(${background})`,
    backgroundPosition: 'right center',
    backgroundRepeat: noRepeat,
    backgroundSize: 'contain',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: none,
    },
  },
  ImageBox: {
    backgroundImage: none,
    height: theme.mixins.homeGetAppImage.height,
    backgroundRepeat: noRepeat,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      backgroundImage: `url(${background})`,
      backgroundSize: cover,
      backgroundPosition: 'center center',
      backgroundRepeat: noRepeat,
    },
    [theme.breakpoints.down('xs')]: { height: theme.mixins.homeGetAppImage.height / 2 },
  },
  ContentBox: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      textAlign: center,
    },
  },
}));
export const useOnYourScheduleDescriptionStyles = makeStyles((theme) => ({
  OnYourScheduleTitle: { paddingBottom: theme.spacing(3) },
  OnYourScheduleDownload: {
    paddingTop: theme.spacing(3),
  },
  AppLogosWrapper: {
    display: block,
    justifyItems: spaceAround,
    paddingTop: theme.spacing(3),
  },
  AppLogoBox: { marginRight: theme.spacing(2), marginLeft: theme.spacing(2) },
  AppLogo: {
    cursor: pointer,
    display: inlineBlock,
    '&.MuiAvatar-root': {
      width: theme.spacing(25),
      height: auto,
      borderRadius: 0,
    },
  },
}));

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  center,
  column,
  fixed,
  flex,
  flexEnd,
  hidden,
  inlineBlock,
  none,
  pointer,
  relative,
  visible,
  wrap,
} from 'constants/Jss';

export const useFooterStyles = makeStyles((theme) => ({
  Footer: {
    background: theme.palette.default.dark,
    width: '100%',
    color: theme.palette.white,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: relative,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1.5),
    },
    '& a:hover': {
      color: theme.palette.whiteHover,
    },
    '& a:focus': {
      color: theme.palette.whiteHover,
    },
  },
  ScrollToTop: {
    display: flex,
    visibility: hidden,
    opacity: 0,
    position: fixed,
    bottom: 200,
    right: theme.spacing(3),
    width: theme.mixins.scrollToTop.size,
    height: theme.mixins.scrollToTop.size,
    borderRadius: theme.mixins.scrollToTop.size / 2,
    color: theme.palette.white,
    backgroundColor: theme.palette.secondary.main,
    justifyContent: center,
    alignItems: center,
    cursor: pointer,
    '& svg': {
      width: (theme.mixins.scrollToTop.size / 3) * 2,
      height: (theme.mixins.scrollToTop.size / 3) * 2,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
      right: theme.spacing(1),
      width: theme.mixins.scrollToTop.mobileSize,
      height: theme.mixins.scrollToTop.mobileSize,
      borderRadius: theme.mixins.scrollToTop.mobileSize / 2,
      '& svg': {
        width: (theme.mixins.scrollToTop.mobileSize / 3) * 2,
        height: (theme.mixins.scrollToTop.mobileSize / 3) * 2,
      },
    },
    '&.Visible': {
      opacity: 1,
      visibility: visible,
    },
    transition: 'visibility 0.3s ease-in-out, opacity 0.3s ease-in-out',
    zIndex: 10,
  },
  MainContainer: {
    padding: 0,
    display: flex,
    flexDirection: column,
    alignItems: center,
  },
  MainGrid: {
    maxWidth: '1140px',
  },
  SLTLogoContainer: {
    position: 'absolute',
    right: theme.spacing(3),
    justifyContent: flexEnd,
    [theme.breakpoints.down('xs')]: {
      position: 'static',
      paddingTop: theme.spacing(2),
      justifyContent: center,
    },
  },
  SLTLogo: {
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(3),
      paddingRight: theme.spacing(0),
    },
  },
  CopyRightContainer: {
    display: flex,
    gap: '8px',
    paddingTop: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& a': {
      textDecoration: none,
      color: theme.palette.white,
    },
  },
  GridContainer: {
    justifyContent: center,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingBottom: theme.spacing(4),
    },
  },
  FooterTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontSize: 16,
    textAlign: center,
    display: inlineBlock,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '& a': {
      textDecoration: none,
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.whiteHover,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  SocialBoxContainer: {
    justifyContent: center,
  },
  SocialBox: {
    display: flex,
    justifyContent: center,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  Logo: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  DesktopOnly: {
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  CopyRight: {
    marginTop: theme.spacing(0),
    fontSize: 12,
    width: '100%',
    display: flex,
    justifyContent: center,
    flexWrap: wrap,
    '& a': {
      textDecoration: none,
      color: theme.palette.white,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  CopyRightItem: {
    [theme.breakpoints.down('xs')]: {
      '&:not(.NoPadding)': {
        marginTop: theme.spacing(2),
      },
      marginLeft: 0,
      marginRight: theme.spacing(3),
    },
  },
  PreFormatted: {
    whiteSpace: 'pre',
  },
  ContactEmail: {
    wordBreak: 'break-all',
    wordWrap: 'break-word',
  },
}));

import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';

import { Typography, ExternalLink } from 'components';
import { useOnYourScheduleDescriptionStyles } from './styles';
import { FooterLinks } from 'constants/Constants';
import appStoreButtonImg from 'assets/images/appstore_button.png';
import googleAppButtonImg from 'assets/images/googleplay_button.png';

const OnYourScheduleContent = () => {
  const classes = useOnYourScheduleDescriptionStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Typography className={classNames(classes.OnYourScheduleTitle)} color="textSecondary" variant="h2">
        {t('on_your_schedule_title')}
      </Typography>
      <Typography className={classNames('Grey')} variant="body1" lineHeight="medium">
        {t('on_your_schedule_description')}
      </Typography>
      <Typography className={classNames(classes.OnYourScheduleDownload)} color="secondary" variant="h4">
        {t('on_your_schedule_download')}
      </Typography>
      <Box className={classes.AppLogosWrapper}>
        <ExternalLink
          className={classes.AppLogoBox}
          href={FooterLinks.appStoreSLT}
          target="_blank"
          role="link"
          aria-label={t('alt_link_to_app_store')}
        >
          <Avatar className={classes.AppLogo} src={appStoreButtonImg} />
        </ExternalLink>
        <ExternalLink
          className={classes.AppLogoBox}
          href={FooterLinks.googlePlaySLT}
          target="_blank"
          role="link"
          aria-label={t('alt_slt_web_page')}
        >
          <Avatar className={classes.AppLogo} src={googleAppButtonImg} />
        </ExternalLink>
      </Box>
    </Box>
  );
};
export default OnYourScheduleContent;

import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

import { DURATION_STRING } from 'constants/Constants';
import { DURATION_VALUES, getDurationLabel } from '../DesktopFilter/DurationFilter';
import { MobileFilterHeader } from './MobileFilterHeader';

import { useMobileFilterDialogStyles } from './styles';

export const MobileDurationFilter = ({ openFilter, setOpenFilter, filterValues, handleFilterChange }) => {
  const { t } = useTranslation();
  const classes = useMobileFilterDialogStyles();

  const onChange = (key) => {
    handleFilterChange({
      duration: isEqual(filterValues.duration, DURATION_VALUES[key]) ? null : DURATION_VALUES[key],
    });
  };

  return (
    <Box className={classes.Filter}>
      <MobileFilterHeader
        openType={DURATION_STRING}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        title={t('filter_duration')}
        altTitle={t('alt_duration_filter')}
      />
      <Box
        className={classNames(classes.Body, {
          Open: openFilter === DURATION_STRING,
        })}
      >
        {Object.keys(DURATION_VALUES).map((key) => (
          <div key={key} className={classes.MobileRadioFilter}>
            <FormControlLabel
              control={
                <Checkbox
                  className={classNames(classes.Checkbox, {
                    Checked: filterValues.duration === DURATION_VALUES[key],
                  })}
                  checked={isEqual(filterValues.duration, DURATION_VALUES[key])}
                  onChange={() => onChange(key)}
                  tabIndex={-1}
                  aria-hidden={true}
                />
              }
              classes={{
                root: classes.MobileFilterLabel,
              }}
              label={getDurationLabel(key)}
              aria-label={t(`alt_${key}`)}
              role="checkbox"
              tabIndex={openFilter === DURATION_STRING ? 0 : -1}
              aria-checked={isEqual(filterValues.duration, DURATION_VALUES[key])}
            />
          </div>
        ))}
      </Box>
    </Box>
  );
};

MobileDurationFilter.propTypes = {
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
  filterValues: PropTypes.object,
  handleFilterChange: PropTypes.func,
};

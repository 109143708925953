import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';

import { Button } from 'components';
import Settings from 'env';

import { useDesktopHeaderStyles } from './styles';

export const AdminLoginButton = () => {
  const classes = useDesktopHeaderStyles();
  const { t } = useTranslation();

  return (
    <Box marginLeft={2} marginRight={2}>
      <a className="Link" href={Settings.ADMIN_URL} target="_blank">
        <Button buttonClassName={classNames(classes.Button)} color="secondary" variant="contained">
          {t('menu_item_login')}
        </Button>
      </a>
    </Box>
  );
};

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import { BookmarkIcon, Indicator, TimeIndicator } from 'components';
import { LessonIcon, ClockCircularOutlineIcon } from 'assets/icons';

import { useCardStyles } from './styles';

const SymbolContainer = ({ lessonCount, minutes, bookMark }) => {
  const classes = useCardStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.SymbolContainer}>
      {lessonCount && (
        <Box mr={3} className={classes.LessonCount}>
          <Indicator
            icon={LessonIcon}
            greyIcon
            text={`${lessonCount} ${t('course_card_lesson')}`}
            textClassName={classes.Text}
          />
        </Box>
      )}
      {minutes && (
        <Box mr={3}>
          <TimeIndicator
            smallIcon
            icon={ClockCircularOutlineIcon}
            greyIcon
            minutes={minutes}
            textClassName={classes.Text}
          />
        </Box>
      )}
      <BookmarkIcon
        useOriginalColors
        className={classNames(classes.BookmarkIcon, { [classes.Hidden]: !bookMark })}
        filled
      />
    </Box>
  );
};

SymbolContainer.propTypes = {
  lessonCount: PropTypes.number,
  minutes: PropTypes.number,
  bookMark: PropTypes.bool,
};

export default SymbolContainer;

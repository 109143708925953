import React from 'react';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import SvgIcon from '@material-ui/core/SvgIcon';

import { useExpandableBoxStyles } from './styles';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { unset } from 'constants/Jss';

export const ExpandableBox = ({ text, minHeight, textBoxClass, alwaysExpanded }) => {
  const classes = useExpandableBoxStyles();
  const [expanded, setExpanded] = React.useState(alwaysExpanded || false);
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.Wrapper}>
        <Box
          id="Box"
          className={classNames(classes.Text, textBoxClass, { Expanded: expanded })}
          style={{ maxHeight: expanded ? unset : minHeight }}
          tabIndex={0}
        >
          {text}
          <Box className={classNames(classes.ShadowBox, { Expanded: expanded })} />
        </Box>
        <div className={classNames(classes.Expander, { Expanded: expanded })} onClick={() => setExpanded(!expanded)}>
          <SvgIcon viewBox={`0 0 ${expanded ? '20.617 9.309' : '16.617 12.309'}`}>
            <path d="M0,0,8.894,8.894,17.789,0" />
          </SvgIcon>
        </div>
      </div>

      <Box
        mt={4}
        mb={4}
        className={classNames(classes.ReadMoreButtonContainer, { Expanded: expanded })}
        onClick={() => setExpanded(!expanded)}
      >
        <Button buttonClassName="Pink">{t('common_read_more')}</Button>
      </Box>
    </>
  );
};

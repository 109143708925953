import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import {
  Montserrat,
  MontserratBold,
  MontserratSemiBold,
  MontserratExtraBold,
  WonderUnit,
  WonderUnitBold,
} from './fonts';
import { auto, none, pointer, transparent, visible, flexStart, noRepeat } from 'constants/Jss';
import { isBrowser } from 'utils/browser';

import ChannelZesterImg from 'assets/images/channel_zester.png';
import LemonAndLimeImg from 'assets/images/lemon_and_lime.png';
import JiggerImg from 'assets/images/jigger.png';
import HawthorneStrainerImg from 'assets/images/hawthorne_strainer.png';
import MuddlerImg from 'assets/images/muddler.png';
import ShakerImg from 'assets/images/shaker.png';
import ShakerLeftImg from 'assets/images/shaker_left.png';
import CherryAndLemonImg from 'assets/images/cherry_and_lemon.png';

const imageScaling = 30;
const commonBackgroundImageOffSet = '550px';
const commonBackgroundImageSize = '300px';
const imageScalingPercent = imageScaling + '%';
const imageXPositionOnXL = '130px';

const defaultTheme = createMuiTheme();

const textFieldRadius = '28px';

const theme = createMuiTheme({
  mixins: {
    page: {
      mainPadding: '8%',
      topSpace: defaultTheme.spacing(15),
      sideSpace: defaultTheme.spacing(5),
    },
    sideBar: {
      width: 300,
      mobileBreakpoint: 'md',
    },
    xpBubble: {
      size: 140,
    },
    filePicker: {
      width: 40,
      height: 40,
    },
    searchBar: {
      height: 32,
    },
    header: {
      height: 80,
      contentSize: 44,
    },
    mobileHeader: {
      height: 80,
    },
    scrollToTop: {
      size: 60,
      mobileSize: 42,
    },
    homeSearch: {
      height: 66,
    },
    homePageAboutUsImage: {
      size: 120,
    },
    homeGetAppImage: {
      height: 400,
    },
    pagination: {
      size: 44,
    },
    twoColumnForm: {
      spacing: 8,
    },
    lessonBoxIcon: {
      width: 136,
      height: 200,
      padding: 13,
    },
    lessonSpecificMedia: { width: 700 },
    loginForm: {
      maxWidth: 380,
    },
    topList: {
      cardIconSize: 94,
      cardIconPadding: 6,
      searchHeight: 66,
    },
    testPage: {
      footerHeight: 104,
      mobileFooterHeight: 66,
    },
    switch: {
      width: 74,
      widthWithLabel: 82,
      height: 37,
      mobileWidth: 62,
      mobileHeight: 31,
      mini: {
        width: 48,
        widthWithLabel: 71,
        height: 24,
      },
    },
    filePreview: {
      size: 200,
      mobileSize: 127,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        overflow: visible,
      },
    },
    MuiButton: {
      root: {
        fontWeight: 400,
        fontSize: 14,
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        '&$disabled': {
          opacity: 0.5,
          color: '',
        },
        '&:focus': {
          outline: none,
        },
        color: defaultTheme.palette.common.black,
      },
      contained: {
        '&$disabled': {
          backgroundColor: '',
          color: '',
        },
        boxShadow: none,
        '&:hover': {
          boxShadow: none,
        },
      },
      outlined: {
        '&$disabled': {
          border: '1px solid',
          borderColor: '',
        },
      },
    },
    MuiAutocompletePopper: {
      root: {
        backgroundColor: defaultTheme.palette.common.black,
      },
    },
    MuiIconButton: {
      root: {
        cursor: pointer,
        borderRadius: '50%',
        '&:focus': {
          outline: none,
        },
        '&:hover': {
          backgroundColor: '#f5d80e',
        },
        '& svg': {
          fill: defaultTheme.palette.common.white,
        },
        '&$disabled': {
          backgroundColor: none,
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [WonderUnit, WonderUnitBold, Montserrat, MontserratSemiBold, MontserratBold, MontserratExtraBold],
      },
    },
    MuiDialogActions: {
      root: {
        paddingLeft: defaultTheme.spacing(3),
        paddingRight: defaultTheme.spacing(3),
        paddingBottom: defaultTheme.spacing(2),
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          marginLeft: 0,
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: 640,
      },
      paperWidthXs: {
        maxWidth: 380,
      },
      paper: {
        borderRadius: 37,
        backgroundColor: defaultTheme.palette.common.black,
      },
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: `rgba(0, 0, 0, 0.75)`,
        },
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: defaultTheme.palette.common.white,
        '& .MuiPickersCalendarHeader-iconButton': {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: visible,
        paddingTop: defaultTheme.spacing(3),
        paddingBottom: defaultTheme.spacing(3),
        paddingLeft: defaultTheme.spacing(6),
        paddingRight: defaultTheme.spacing(6),
      },
    },
    MuiTablePagination: {
      toolbar: {
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2),
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: defaultTheme.palette.common.black,
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: defaultTheme.palette.common.white,
        },
        '&.Mui-focused': {
          color: defaultTheme.palette.common.white,
        },
      },
    },
    MuiTextField: {
      root: {
        borderRadius: textFieldRadius,
        backgroundColor: 'transparent',
        color: defaultTheme.palette.common.white,
        '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus,': {
          caretColor: defaultTheme.palette.common.white,
          '-webkit-text-fill-color': defaultTheme.palette.common.white,
          '-webkit-box-shadow': `0 0 0px 1000px ${defaultTheme.palette.common.black} inset`,
          background: 'content-box',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: textFieldRadius,
        border: '1px solid',
        color: defaultTheme.palette.common.white,
        borderColor: 'transparent',
      },
    },
    MuiInputLabel: {
      outlined: {
        backgroundColor: 'rgba(0,0,0,0)',
        paddingLeft: `${defaultTheme.spacing(1)}px!important`,
        paddingRight: `${defaultTheme.spacing(1)}px!important`,
        color: defaultTheme.palette.common.white,
      },
      formControl: {
        '& + .MuiInput-root': {
          marginTop: defaultTheme.spacing(2),
        },
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      primary: {
        fontSize: 12,
      },
    },
    MuiFormControl: {
      root: {
        backgroundColor: defaultTheme.palette.common.white,
        borderRadius: 4,
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: flexStart,
      },
      label: {
        lineHeight: 1.1,
      },
    },
    MuiDrawer: {
      root: {
        top: '60px !important',
        '& .MuiBackdrop-root': {
          top: 60,
        },
        maxHeight: '100%',
        overflowY: auto,
      },
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1140px !important',
      },
    },
    MuiList: {
      root: {
        '&:focus': {
          outline: none,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: '#FF5C6C',
        },
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        top: 'calc(50% - 17px)',
        lineHeight: '1.1875em',
      },
      inputRoot: {
        lineHeight: 2,
        // '&&[class*="MuiOutlinedInput-root"]': {
        //   paddingTop: 0,
        //   paddingBottom: 0,
        // },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: transparent,
        },
      },
    },
  },
  palette: {
    default: {
      main: '#fff',
      dark: '#000000',
      hover: '#1a1a1a',
      contrast: '#fff',
    },
    primary: {
      main: '#f5d80e',
      dark: '#f3bc26',
      hover: '#f3bc26',
      contrast: '#fff',
    },
    secondary: {
      main: '#E43778',
      dark: '#e43780',
      hover: '#e43799',
      contrast: '#fff',
    },
    background: {
      default: '#fff',
      dark: '#000000',
    },
    text: {
      primary: '#fff',
      secondary: '#000',
    },
    red: '#BB0A30',
    black: '#000',
    blackTitle: '#231F20',
    blackOpacity: '#00000042',
    grey1A: '#1a1a1a',
    grey33: '#333333',
    grey4C: '#4c4c4c',
    greyC4: '#c4c4c4',
    grey64: '#646F81',
    grey66: '#666',
    grey70: '#707070',
    grey73: '#737373',
    grey80: '#808080',
    grey81: '#818181',
    grey99: '#999',
    greyA2: '#a2a2a2',
    greyB3: '#b3b3b3',
    greyCC: '#ccc',
    greyD9: '#d9d9d9',
    greyF9: '#f9f9f9',
    greyDD: '#ddd',
    greyD6: '#D6D6D6',
    greyE5: '#e5e5e5',
    greyE9: '#E9EAF3',
    greyEF: '#efefef',
    greyF2: '#f2f2f2',
    greyF5: '#F5F5F5',
    greyF8: '#f8f8f8',
    greyFA: '#fafafa',
    greyF2Opacity: '#f2f2f27d',
    white: '#fff',
    whiteHover: '#fffffffa',
    searchBg: '000',
    checkboxBorder: '#c9c9c9',
    switchActiveTrack: '#E43778',
    switchInactiveThumb: '#E5E7EA',
    switchInactiveTrack: '#a6b1c1',
    greyLoginLine: '#70707057',
    darkPink: '#D45278',
    darkPinkHover: '#BC4769',
    errorColor: '#f44336',
    yellow: '#FCC480',
    yellowBright: '#FECC52',
    importantYellow: '#FF9600',
    bluishGrey: '#B4BBC7',
    cyan: '#45C0BE',
    cyanHover: '#34ADAB',
    greenSuccess: '#B9D988',
    greenSuccessBackground: '#8DCB01',
    green: '#8DCB01',
    greenSecondPlace: '#8DCB01',
    greenHover: '#00D89E',
    focusColor: '#4D90FE',
    facebookColor: '#3B5998',
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 18,
    h1: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 600,
      fontSize: '45px',
    },
    h2: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 900,
      fontSize: '32px',
    },
    h4: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 600,
      fontSize: '28px',
    },
    h5: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 900,
      fontSize: '24px',
    },
    body1: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '18px',
    },
    body2: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '14px',
    },
    button: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '16px',
      textTransform: 'inherit',
      fontWeight: 'inherit',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
  },
  breakpoints: {
    values: {
      ...defaultTheme.breakpoints.values,
      md: 1140,
      sm: 768,
    },
  },
});

const getBasicCommonBackground = (yOffSet = 0) => ({
  backgroundImage:
    `url(${ChannelZesterImg}),` +
    `url(${LemonAndLimeImg}),` +
    `url(${JiggerImg}),` +
    `url(${MuddlerImg}),` +
    `url(${ShakerImg}),` +
    `url(${CherryAndLemonImg})`,
  backgroundPosition:
    `calc(calc(${imageScalingPercent}) - ${commonBackgroundImageOffSet}) calc(0px + ${yOffSet}px),` +
    `calc(calc(${imageScalingPercent}) - ${commonBackgroundImageOffSet}) calc(340px + ${yOffSet}px),` +
    `calc(calc(${imageScalingPercent}) - ${commonBackgroundImageOffSet}) calc(650px + ${yOffSet}px),` +
    `calc(calc(100% - ${imageScalingPercent}) + ${commonBackgroundImageOffSet}) calc(-80px + ${yOffSet}px),` +
    `calc(calc(100% - ${imageScalingPercent}) + ${commonBackgroundImageOffSet}) calc(250px + ${yOffSet}px),` +
    `calc(calc(100% - ${imageScalingPercent}) + ${commonBackgroundImageOffSet}) calc(500px + ${yOffSet}px)`,
  backgroundSize:
    `${commonBackgroundImageSize},` +
    `${commonBackgroundImageSize},` +
    `${commonBackgroundImageSize},` +
    `${commonBackgroundImageSize},` +
    `${commonBackgroundImageSize},` +
    `${commonBackgroundImageSize},` +
    `${commonBackgroundImageSize}`,
  backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
  [theme.breakpoints.up('xl')]: {
    backgroundPosition:
      `-${imageXPositionOnXL} calc(0px + ${yOffSet}px),` +
      `-${imageXPositionOnXL} calc(340px + ${yOffSet}px),` +
      `-${imageXPositionOnXL} calc(650px + ${yOffSet}px),` +
      `calc(calc(100%) + ${imageXPositionOnXL}) calc(-80px + ${yOffSet}px),` +
      `calc(calc(100%) + ${imageXPositionOnXL}) calc(250px + ${yOffSet}px),` +
      `calc(calc(100%) + ${imageXPositionOnXL}) calc(500px + ${yOffSet}px)`,
  },
});

theme.mixins = {
  ...theme.mixins,
  commonBackground: {
    flex: 1,
    ...getBasicCommonBackground(0),
    backgroundAttachment: 'fixed',
  },
  homeBackground: {
    ...getBasicCommonBackground(1100),
  },
};

// Let the theme object be available quickly during development
if (process.env.NODE_ENV === 'development' && isBrowser()) {
  console.log(theme);
}

export default responsiveFontSizes(theme, { breakpoints: ['xs', 'sm', 'md', 'lg'], factor: 4 });

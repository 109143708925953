import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { BookmarkIcon, Indicator, TimeIndicator } from 'components';
import { bold, center, flex, spaceBetween } from 'constants/Jss';
import { LessonIcon, ClockCircularOutlineIcon, VisitedIcon, FailedIcon, SucceededIcon } from 'assets/icons';

export const useSymbolContainerStyles = makeStyles((theme) => ({
  SymbolContainer: {
    display: flex,
    fontFamily: 'WonderUnit',
    justifyContent: spaceBetween,
    alignItems: center,
    fontSize: 12,
    fontWeight: bold,
  },
  LessonCount: {
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(6),
    },
  },
  IconsBox: {
    display: flex,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: spaceBetween,
    },
  },
  CourseDetailsBox: {
    display: flex,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  XpIndicator: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(6),
      marginRight: 0,
    },
  },
  StatusIndicator: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  Text: {
    fontSize: 14,
  },
  BookmarkIcon: { marginLeft: theme.spacing(3) },
  BookmarkIconColor: {
    fill: theme.palette.secondary.main,
  },
  ImportantIconColor: {
    fill: theme.palette.importantYellow,
  },
}));

const SymbolContainer = ({ course = {}, showDetails, hideBookmark }) => {
  const classes = useSymbolContainerStyles();
  const { t } = useTranslation();

  const color = 'secondary';

  return (
    <Box className={classes.SymbolContainer}>
      <Box className={classes.CourseDetailsBox}>
        <Box className={classes.IconsBox}>
          <Box mr={showDetails ? 8 : 2} className={classNames({ [classes.LessonCount]: showDetails })}>
            <Indicator
              icon={LessonIcon}
              greyIcon
              text={`${course.lessonsDone || course.lessonCount || 0} ${t('course_card_lesson')}`}
              textClassName={classes.Text}
            />
          </Box>
          <Box>
            <TimeIndicator
              smallIcon={!showDetails}
              icon={ClockCircularOutlineIcon}
              greyIcon
              minutes={course.examMinutes}
              textClassName={classes.Text}
            />
          </Box>
          {showDetails && (
            <Box className={classes.XpIndicator}>
              <Indicator color={color} text={course.earnedXp} label={t('common_xp')} textClassName={classes.Text} />
            </Box>
          )}
        </Box>
        {showDetails ? (
          <Box className={classes.IconsBox}>
            <Box className={classes.StatusIndicator} mr={4}>
              <Indicator icon={VisitedIcon} useOriginalColors text={course.visited || 0} textClassName={classes.Text} />
            </Box>
            <Box className={classes.StatusIndicator} mr={4}>
              <Indicator icon={FailedIcon} useOriginalColors text={course.failed || 0} textClassName={classes.Text} />
            </Box>
            <Box className={classes.StatusIndicator}>
              <Indicator
                icon={SucceededIcon}
                useOriginalColors
                text={course.success || 0}
                textClassName={classes.Text}
              />
            </Box>
          </Box>
        ) : null}
      </Box>
      {!hideBookmark && course.isImportant ? (
        <BookmarkIcon
          useOriginalColors
          className={classNames(classes.BookmarkIcon, { [classes.ImportantIconColor]: course.isImportant })}
          filled={course.isImportant}
        />
      ) : null}
    </Box>
  );
};

SymbolContainer.propTypes = {
  course: PropTypes.object,
  showDetails: PropTypes.bool,
  hideBookmark: PropTypes.bool,
};

export default SymbolContainer;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import logo from 'assets/images/slt.png';
import noImage from 'assets/images/no-image.png';
import { MenuItem } from './MenuItem';
import { useTranslation } from 'react-i18next';
import { Search } from './Search';
import { useMobileHeaderStyles } from './styles';
import { Hamburger } from './Hamburger';
import { Profile } from 'types/types';
import { getUrl } from 'utils/urlFromImagePath';
import { searchIcon } from 'utils/icons';
import { MinDesktopWidth } from 'constants/Constants';
import { isBrowser } from 'utils/browser';
import { AdminLoginButton } from './AdminLoginButton';
import { ReactComponent as SLTHeaderLogo } from '../../assets/icons/slt_header_logo.svg';

export const MobileHeader = ({ onLogoClick, getPath, isLoggedIn, search, profile, logout: logoutAction }) => {
  const classes = useMobileHeaderStyles();
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [searchOpen, setSearchOpen] = React.useState(false);

  const handleResize = () => {
    setDrawerOpen(false);
    if (window.innerWidth > MinDesktopWidth) {
      setSearchOpen(false);
    }
  };

  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSearchOpen(false);
    setDrawerOpen(open);
  };

  const toggleSearch = (open) => async () => {
    setDrawerOpen(false);
    await setSearchOpen(open);
    if (open) {
      const searchInput = document.getElementsByClassName('MobileSearchField')[0].getElementsByTagName('input')[0];
      searchInput.focus();
    }
  };

  const logout = () => {
    logoutAction();
    setDrawerOpen(false);
    setSearchOpen(false);
  };

  return (
    <Box className={classes.MobileHeader}>
      <Box className={classes.MobileLogoContainer}>
        <Avatar
          className={classes.MobileLogo}
          tabIndex={0}
          role="link"
          alt={t('alt_link_to_home_page')}
          aria-label={t('alt_link_to_home_page')}
          id="page-logo-mobile"
        >
          <SLTHeaderLogo />
        </Avatar>
      </Box>
    </Box>
  );
};

MobileHeader.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  getPath: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  profile: Profile,
};

import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import SLTCard from './SLTCard';
import getSLTCardData from 'utils/getSLTCardData';

import { useSLTCardsContainerStyles } from './styles';

const SLTCardsContainer = () => {
  const { t } = useTranslation();
  const classes = useSLTCardsContainerStyles();
  const SLTCardData = getSLTCardData();
  return (
    <Container className={classNames(classes.SLTCardsContainer)}>
      <Grid container justify="space-evenly" alignItems="flex-start">
        {SLTCardData.map(({ title, description, image }) => (
          <Grid key={uuidv4()} item sm={12} md={6} lg={4}>
            <SLTCard title={t(title)} description={t(description)} image={image} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SLTCardsContainer;

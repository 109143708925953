import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

import { Switch } from 'components';
import { TAG_STRING } from 'constants/Constants';
import { MobileFilterHeader } from './MobileFilterHeader';

import { useTagFilterStyles } from '../styles';
import { useMobileFilterDialogStyles } from './styles';

export const MobileTagFilter = ({
  openFilter,
  setOpenFilter,
  filterValues,
  checked,
  onTagsChange,
  clearAllTags,
  showMyInterest,
  tags,
  profileTags,
}) => {
  const { t } = useTranslation();
  const classes = useMobileFilterDialogStyles();
  const tagFilterClasses = useTagFilterStyles();

  return (
    <Box className={classes.Filter}>
      <MobileFilterHeader
        openType={TAG_STRING}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        title={t('filter_tag')}
        altTitle={t('alt_tag_filter')}
      />
      <Box
        className={classNames(classes.Body, {
          Open: openFilter === TAG_STRING,
        })}
      >
        <Box mb={1} className={classes.MobileInterestContainer}>
          <Box className={classes.ClearBox}>
            <div
              className={classes.ActionText}
              onClick={clearAllTags}
              tabIndex={openFilter === TAG_STRING ? 0 : -1}
              role="button"
            >
              {t('topics_page_clear_filter')}
            </div>
          </Box>
          {profileTags && profileTags.length ? (
            <Box className={classes.InterestBox}>
              <div className={classes.ActionText} onClick={showMyInterest}>
                {t('topics_page_my_interest')}
              </div>
              <Box ml={2}>
                <Switch
                  mini
                  checked={checked}
                  onClick={showMyInterest}
                  tabIndex={openFilter === TAG_STRING ? 0 : -1}
                  ariaLabel={t('alt_show_my_interest')}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className={classes.MobileTagsContainer}>
          {tags.map((tag) => (
            <FormControlLabel
              key={tag.id}
              control={
                <Checkbox
                  color="primary"
                  className={classNames(tagFilterClasses.Tag, classes.Checkbox, {
                    Checked: filterValues.tags ? filterValues.tags.includes(tag.id) : false,
                  })}
                  checked={filterValues.tags ? filterValues.tags.includes(tag.id) : false}
                  onChange={() => onTagsChange(tag.id)}
                  tabIndex={-1}
                  aria-hidden={true}
                />
              }
              label={tag.title}
              aria-label={tag.title}
              role="checkbox"
              tabIndex={openFilter === TAG_STRING ? 0 : -1}
              aria-checked={filterValues.tags ? filterValues.tags.includes(tag.id) : false}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

MobileTagFilter.propTypes = {
  openFilter: PropTypes.string,
  setOpenFilter: PropTypes.func,
  filterValues: PropTypes.object,
  checked: PropTypes.bool,
  onTagsChange: PropTypes.func,
  clearAllTags: PropTypes.func,
  showMyInterest: PropTypes.func,
  tags: PropTypes.array,
  profileTags: PropTypes.array,
};

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { Header } from './Header';
import { forgotPassword, login, register, resetPassword } from 'store/auth/actions';
import { getProfileDetails } from 'store/profile/actions';
import { groupSearch } from 'store/groups/actions';
import { removeNotification, showNotification } from 'store/common/actions';
import { logout } from 'store/profile/actions';
import { getProvinces } from 'store/provinces/actions';
import { setFilters } from 'store/courses/actions';
import { checkDocuments, getDocuments, agreeDocuments } from 'store/documents/actions';

const mapStateToProps = (state) => {
  return {
    topics: state.topics.topics,
    profile: state.profile.details,
    groups: state.groups.groups,
    provinces: state.provinces.provinces,
    isLoadingProvinces: state.provinces.loading,
    isLoadingAuth: state.auth.loading,
    documents: state.documents.documents,
    newDocuments: state.documents.newDocuments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
      logout,
      register,
      groupSearch,
      showNotification,
      removeNotification,
      forgotPassword,
      resetPassword,
      getProvinces,
      setFilters,
      checkDocuments,
      getDocuments,
      getProfileDetails,
      agreeDocuments,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, flex, spaceBetween } from 'constants/Jss';

export const useIndicatorStyles = makeStyles((theme) => ({
  IndicatorBox: {
    display: flex,
    justifyContent: spaceBetween,
    alignItems: center,
    fontSize: 12,
  },
  WithBackground: {
    backgroundColor: theme.palette.greyF8,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: '20px',
  },
  GreyIcon: {
    color: theme.palette.grey70,
  },
  TextBox: {
    color: theme.palette.grey73,
    marginLeft: theme.spacing(1),
  },
  LabelTextBox: {
    fontSize: 15,
    fontWeight: 500,
    color: theme.palette.grey80,
    marginLeft: theme.spacing(1),
  },
}));

const Indicator = ({
  label,
  icon: Icon,
  greyIcon,
  iconClassName,
  textClassName,
  labelClassName,
  text,
  withBackground,
  ...props
}) => {
  const classes = useIndicatorStyles();
  return (
    <Box
      className={classNames(classes.IndicatorBox, {
        [classes.WithBackground]: !!withBackground,
      })}
    >
      {label ? (
        <Box className={classNames(classes.LabelTextBox, labelClassName)}>{label}</Box>
      ) : Icon ? (
        <Icon {...props} className={classNames(iconClassName, { [classes.GreyIcon]: greyIcon })} />
      ) : null}

      <Box className={classNames(classes.TextBox, textClassName)}>{text}</Box>
    </Box>
  );
};

Indicator.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Indicator;

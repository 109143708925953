import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MuiSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';

import { styles } from './styles';

const useStyles = makeStyles(styles);

export const Switch = ({
  thumbColorClass,
  mini,
  label,
  onChange,
  onClick,
  inlineOnLabel,
  inlineOffLabel,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const hasInlineLabel = Boolean(inlineOnLabel || inlineOffLabel);

  return (
    <FormControlLabel
      label={label}
      labelPlacement="start"
      onChange={onChange}
      onClick={onClick}
      classes={{
        labelPlacementStart: classes.formControlLabelPlacementStart,
        label: classes.formControlLabel,
      }}
      disabled={disabled}
      control={
        <div className={classes.container}>
          <MuiSwitch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            disabled={disabled}
            classes={{
              root: classNames(classes.root, {
                [classes.miniRoot]: mini,
                [classes.rootWithInlineLabel]: hasInlineLabel,
              }),
              switchBase: classNames(classes.switch, mini ? classes.miniSwitchBase : classes.switchBase, {
                [classes.secondarySwitch]: props.color === 'secondary',
              }),
              thumb: classNames(classes.thumb, { [classes.miniThumb]: mini }),
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
          {hasInlineLabel ? (
            <div
              className={classNames(classes.inlineLabel, {
                [classes.inlineLabelOn]: props.checked,
                [classes.inlineLabelOff]: !props.checked,
                [classes.inlineLabelDisabled]: disabled,
              })}
            >
              {props.checked ? inlineOnLabel : inlineOffLabel}
            </div>
          ) : null}
        </div>
      }
    />
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography } from 'components';
import { useHomeDescriptionStyles } from './styles';

const HomeDescription = ({ isLoggedIn }) => {
  const classes = useHomeDescriptionStyles();
  const { t } = useTranslation();
  return (
    <Typography className={classnames(classes.Description)} variant="body1" align="center" lineHeight="medium">
      {isLoggedIn ? t('home_page_logged_in_description') : t('home_page_not_logged_in_description')}
    </Typography>
  );
};

HomeDescription.propTypes = {
  isLoggedIn: PropTypes.bool,
};

export default HomeDescription;

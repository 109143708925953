import HomePage from 'modules/HomePage';
import Page from 'components/Page/Page';
import withSSR from 'utils/withSSR';

const routes = [
  {
    path: '/hu',
    component: withSSR(Page(HomePage, 'HomePage', null, false)),
    linkName: 'Főoldal',
    exact: true,
  },
  {
    redirect: true,
    to: '/hu',
  },
];

export default routes;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { PlusIcon } from 'assets/icons';
import { CardWrapper } from 'components';
import { column, flex, pointer, relative } from 'constants/Jss';

import { useCardStyles } from 'components/BasicCard/styles';

const useAddCardStyles = makeStyles((theme) => ({
  AddCard: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: 170,
    [theme.breakpoints.up('md')]: {
      minHeight: 250,
    },
  },
  Plus: {
    color: theme.palette.primary.main,
    fontSize: 40,
    marginBottom: 10,
  },
  Title: {
    color: theme.palette.black,
    fontSize: 18,
    fontWeight: 'bold',
  },
}));

const AddCard = ({ title, onClick }) => {
  const classes = useAddCardStyles();
  const basicCard = useCardStyles();
  return (
    <CardWrapper key="addCard">
      <div onClick={onClick} className={classNames(basicCard.BasicCard, classes.AddCard)}>
        <PlusIcon className={classes.Plus} />
        <div className={classes.Title}>{title}</div>
      </div>
    </CardWrapper>
  );
};

AddCard.propType = {
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default AddCard;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

import { Typography } from 'components';

import { center, flex } from 'constants/Jss';
import { Box } from '@material-ui/core';

export const useNoItemsFoundStyles = makeStyles((theme) => ({
  NoItemsFoundBox: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    opacity: 0.3,
    position: 'relative',
    height: theme.spacing(25),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(18),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(13),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  Icon: {
    color: theme.palette.grey70,
    opacity: 0.2,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  Message: { color: theme.palette.grey70 },
}));

const NoItemsFound = ({ message }) => {
  const { t } = useTranslation();
  const classes = useNoItemsFoundStyles();

  return (
    <Box className={classes.NoItemsFoundBox}>
      <NotInterestedIcon className={classes.Icon} />
      <Typography color="textSecondary" variant="h5" align="center">
        {message ? message : t('common_default_not_found_message')}
      </Typography>
    </Box>
  );
};

NoItemsFound.propTypes = {
  message: PropTypes.string,
};

export default NoItemsFound;

import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/tags';

export const getTags = async ({ searchText, page } = {}) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}?${searchText ? '&searchText=' + searchText : ''}${page ? '&page=' + page : ''}`,
  });
};

export const getProfileTags = async () => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/user-interests`,
  };
  return await request(requestData);
};

export const addProfileTags = async (tagIdList = []) => {
  const requestData = {
    method: Methods.POST,
    resource: `${BASE_URL}/user-interests`,
    data: { interestIds: tagIdList },
  };
  return await request(requestData);
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Typography, Skeleton } from 'components';
import { bold, center, cover, ellipsis, hidden, left, noRepeat, vertical } from 'constants/Jss';

export const useTitleContainerStyles = makeStyles((theme) => ({
  TitleContainer: { display: 'flex', textAlign: left },
  TitleGrid: {
    overflow: hidden,
    textOverflow: ellipsis,
    display: '-webkit-box',
    '-webkit-box-orient': vertical,
    '-webkit-line-clamp': 3,
  },
  Title: {
    fontSize: 18,
    fontWeight: bold,
  },
  ImageContainer: {
    borderRadius: '10px',
    minHeight: 75,
    backgroundPosition: center,
    backgroundSize: cover,
    backgroundRepeat: noRepeat,
  },
}));

const TitleContainer = ({ imageUrl, title, isRegular }) => {
  const classes = useTitleContainerStyles();
  return (
    <Grid container className={classes.TitleContainer}>
      {imageUrl ? (
        <Grid item xs={4}>
          {isRegular ? (
            <div
              className={classes.ImageContainer}
              style={{
                backgroundImage: `url("${imageUrl}")`,
              }}
              aria-hidden
            />
          ) : (
            <div className={classNames(classes.ImageContainer, { [classes.TitleNextToImage]: imageUrl })}>
              <Skeleton width="100%" height="100%" variant="rect" />
            </div>
          )}
        </Grid>
      ) : null}
      <Grid item xs={8} md={12} className={classes.TitleGrid}>
        <Box ml={imageUrl ? 2 : 0}>
          {isRegular ? (
            <Typography className={classes.Title} color="textSecondary" variant="body1">
              {title.trim()}
            </Typography>
          ) : (
            <Skeleton height={25} width={200} maxWidth="100%" />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

TitleContainer.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  isRegular: PropTypes.bool,
};
export default TitleContainer;

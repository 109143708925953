import makeStyles from '@material-ui/core/styles/makeStyles';

export const useCardStyles = makeStyles((theme) => ({
  BasicCard: {
    position: 'relative',
    borderRadius: 10,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    cursor: 'grab',
    backgroundColor: theme.palette.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  ImageContainer: {
    display: 'flex',
    position: 'relative',
    width: 60,
    height: 60,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    textAlign: 'center',
    justifyContent: 'center',
    marginRight: 15,
    flexShrink: 0,
  },
  Image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  InfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
  },
  SymbolContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'WonderUnit',
    fontSize: 10,
    fontWeight: 'bold',
    flexWrap: 'wrap',
    marginTop: 5,
  },
  Hidden: {
    visibility: 'hidden',
  },
  Text: {
    fontSize: 14,
  },
  BookmarkIcon: {
    fill: theme.palette.secondary.main,
  },
  Title: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: 3,
    marginBottom: 6,
  },
  Disabled: {
    backgroundColor: theme.palette.greyEF,
    cursor: 'default',
  },
  IsDragging: {
    opacity: 0.2,
  },
}));

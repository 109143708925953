import makeStyles from '@material-ui/core/styles/makeStyles';
import { center, flex } from 'constants/Jss';

export const useCommonStyles = makeStyles((theme) => ({
  SocialLoginButton: {
    fontSize: 14,
    backgroundColor: theme.palette.facebookColor,
    color: theme.palette.white,
    alignItems: center,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px',
    padding: '12px 10px',
    borderRadius: '2px',
    border: '1px solid transparent',
    justifyContent: 'left',
    display: flex,
    '& svg': {
      marginLeft: 2,
      marginRight: 18,
    },
  },
}));

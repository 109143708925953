import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';

import { withNoItems } from 'components';

import { useCourseDetailPageStyles } from '../../modules/CourseDetailPage/styles';

export const SectionWithNoData = withNoItems(({ section }) => {
  const classes = useCourseDetailPageStyles();

  return (
    <Box className={classes.NarrowBox} tabIndex={0}>
      {section}
    </Box>
  );
});

SectionWithNoData.propTypes = {
  section: PropTypes.node,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Box, Container } from '@material-ui/core';

import { MobileFilterDialog } from './MobileFilterDialog';
import { getNumberOfActiveFilters } from 'utils/filters';
import { FiltersType, Tag } from 'types/types';
import { Indicator, Skeleton } from 'components';
import { LessonIcon } from 'assets/icons';
import { auto } from 'constants/Jss';

import { useFilterStyles } from '../styles';
import { useMobileFilterStyles } from './styles';

export const MobileFilter = ({
  tags,
  filters,
  setFilters,
  numberOfItems,
  alignVertical,
  profileTags,
  title,
  showFilters = ['tag', 'duration', 'exams'],
  isDataLoading,
}) => {
  const classes = useMobileFilterStyles();
  const filterIconClasses = useFilterStyles();
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = React.useState(false);

  return (
    <Container className={classes.MobileFilter}>
      <Box mr={1} mb={2} className={classNames(classes.FilterContainer, { Vertical: alignVertical })}>
        {!isDataLoading ? (
          <Indicator
            iconClassName={classes.CountIcon}
            textClassName={classes.CountText}
            icon={LessonIcon}
            greyIcon
            text={`${numberOfItems || t('not_provided_value')} ${title}`}
          />
        ) : (
          <Skeleton height={20} width={60} />
        )}

        <Box
          className={classes.MobileFilterIcon}
          onClick={() => setOpenFilter(true)}
          role="button"
          tabIndex={0}
          aria-label={
            getNumberOfActiveFilters(filters) > 0
              ? t('alt_open_filter_dialog_with_active_filters', { numberOfFilters: getNumberOfActiveFilters(filters) })
              : t('alt_open_filter_dialog')
          }
          aria-haspopup={true}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setOpenFilter(true);
            }
          }}
        >
          {getNumberOfActiveFilters(filters) > 0 ? (
            <div className={classNames(filterIconClasses.ActiveFiltersBox, 'Mobile')}>
              <Box className={classNames(filterIconClasses.ActiveFilters, 'Mobile')}>
                {getNumberOfActiveFilters(filters)}
              </Box>
            </div>
          ) : null}
          <Box mb={0.375} ml={auto} mr={auto} className={classNames(classes.FilterStrip, classes.TopStrip)} />
          <Box mb={0.375} ml={auto} mr={auto} className={classNames(classes.FilterStrip, classes.MiddleStrip)} />
          <Box ml={auto} mr={auto} className={classNames(classes.FilterStrip, classes.BottomStrip)} />
        </Box>
      </Box>
      <MobileFilterDialog
        open={openFilter}
        closeDialog={() => setOpenFilter(false)}
        tags={tags}
        filters={filters}
        setFilters={setFilters}
        profileTags={profileTags}
        showFilters={showFilters}
      />
    </Container>
  );
};

MobileFilter.propTypes = {
  tags: PropTypes.arrayOf(Tag),
  filters: FiltersType,
  setFilters: PropTypes.func,
  filterBoxClass: PropTypes.string,
  profileTags: PropTypes.arrayOf(Tag),
  numberOfItems: PropTypes.number,
  isDataLoading: PropTypes.bool,
  alignVertical: PropTypes.bool,
  title: PropTypes.string,
  showFilters: PropTypes.arrayOf(PropTypes.oneOf(['tag', 'duration', 'exams'])),
};

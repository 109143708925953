import React from 'react';
import PropTypes from 'prop-types';

import { isExamProcess, isTestProcess, isKnowledgeTestProcess } from 'utils/testExam';

export default (WrappedComponent) => {
  return class WithTestAndExam extends React.Component {
    static propTypes = {
      location: PropTypes.object,
      history: PropTypes.object,
      match: PropTypes.object,
    };

    state = {
      isExam: isExamProcess(this.props.location),
      isTest: isTestProcess(this.props.location),
      isKnowledgeTest: isKnowledgeTestProcess(this.props.location),
    };

    redirectTo = (from, to) => {
      const { history, location } = this.props;

      history.replace(location.pathname.replace(from, to));
    };

    actionByProcessType = ({ examFunction, testFunction, kmowledgeTestFunction }) => {
      const { isExam, isTest } = this.state;

      if (isExam) {
        return examFunction();
      } else if (isTest) {
        return testFunction();
      } else {
        return kmowledgeTestFunction();
      }
    };

    render() {
      const { isExam, isTest, isKnowledgeTest } = this.state;

      return (
        <WrappedComponent
          redirectTo={this.redirectTo}
          isExam={isExam}
          isTest={isTest}
          isKnowledgeTest={isKnowledgeTest}
          actionByProcessType={this.actionByProcessType}
          {...this.props}
        />
      );
    }
  };
};

import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { auto } from 'constants/Jss';
import { Typography } from 'components';
import { PuzzleWord } from './PuzzleWord';
import { usePuzzleFormStyles } from '../styles';

export const PuzzleForm = ({ answers, setAnswer, answered }) => {
  const classes = usePuzzleFormStyles();

  const [answerText, setAnswerText] = React.useState('');
  const [changed, setChanged] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState([]);
  const [value, setValue] = React.useState([]);
  const [goodAnswer, setGoodAnswer] = React.useState({});
  const [puzzleWord, setPuzzleWord] = React.useState([]);
  const [emptyIndex, setEmptyIndex] = React.useState(0);

  const correctAnswer = answerText === goodAnswer.answer && answered;
  const wrongAnswer = !correctAnswer && answered;

  useEffect(() => {
    if (changed) {
      setChanged(false);
    }
  }, [changed]);

  const shuffle = (word) =>
    word
      .split('')
      .sort(() => 0.5 - Math.random())
      .map((word) => ({ word: word.toUpperCase(), id: uuidv4() }));

  useMemo(() => {
    if (!puzzleWord.length) {
      setPuzzleWord(shuffle(answers.reduce((accumulator, currentValue) => accumulator + currentValue.answer, '')));
      const findGoodAnswer = answers.find(({ isGood }) => isGood);
      setGoodAnswer({ ...findGoodAnswer, answer: findGoodAnswer.answer.toUpperCase() });

      const goodAnswerValue = [];
      for (let index = 0; index < findGoodAnswer.answer.length; index++) {
        goodAnswerValue.push({ id: uuidv4(), word: null });
      }
      setValue(goodAnswerValue);
      setDefaultValue(goodAnswerValue);
    }
  }, [answers]);

  const handleChange = (valueWord, removeIndex) => {
    let newValue = null;
    if (typeof removeIndex !== 'undefined') {
      newValue = value.map((valueItem, index) =>
        index !== removeIndex ? valueItem : { id: uuidv4(), word: null, lastWord: valueItem.word }
      );
    } else {
      const firstEmptyIndex = value.findIndex(({ word }) => !word);
      setEmptyIndex(firstEmptyIndex);

      newValue = value.map((valueItem, index) =>
        index !== firstEmptyIndex ? valueItem : { id: valueWord.id, word: valueWord.word }
      );
    }
    const newAnswerText = newValue.map(({ word }) => word || '').join('');

    setValue(newValue);
    setAnswerText(newAnswerText);
    setAnswer([newAnswerText === goodAnswer.answer ? goodAnswer.id : -1]);
    setChanged(true);
  };

  return (
    <Box className={classes.FormBox} ml={auto} mr={auto}>
      <Box className={classes.PuzzleWordContainer}>
        {defaultValue
          ? defaultValue.map((valueWord, index) => (
              <PuzzleWord
                key={valueWord.id}
                id={valueWord.id}
                word={value[index].word || value[index].lastWord}
                correct={correctAnswer}
                wrong={wrongAnswer}
                onClick={() => handleChange(value[index], index)}
                empty={!value[index].word}
                disabled={answered}
                changed={changed}
                targetId={value[index].id}
                answer
              />
            ))
          : null}
      </Box>

      <Divider className={classes.PuzzleWordDivider} />
      <Box mb={5} className={classes.PuzzleWordCorrectAnswer}>
        <Typography variant="h5" color="textSecondary" align="center">
          {wrongAnswer ? goodAnswer.answer : ''}
        </Typography>
      </Box>

      <Box className={classes.PuzzleWordContainer}>
        {puzzleWord
          ? puzzleWord.map((valueWord) => (
              <PuzzleWord
                key={valueWord.id}
                id={valueWord.id}
                word={valueWord.word}
                onClick={() => handleChange(valueWord)}
                empty={!!value.find(({ id }) => valueWord.id === id)}
                disabled={answered}
                changed={changed}
                targetId={emptyIndex > -1 ? defaultValue[emptyIndex].id : null}
              />
            ))
          : null}
      </Box>
    </Box>
  );
};

PuzzleForm.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string,
      isGood: PropTypes.bool,
    })
  ),
  setAnswer: PropTypes.func,
  answered: PropTypes.bool,
};

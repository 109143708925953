import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { Skeleton, Image } from 'components';
import { center, cover, flex, relative } from 'constants/Jss';

export const useImageContainerStyles = makeStyles((theme) => ({
  ImageContainer: {
    display: flex,
    position: relative,
    width: '100%',
    height: 150,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    textAlign: center,
    justifyContent: center,
  },
  Image: {
    objectFit: cover,
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
}));

const ImageContainer = ({ isRegular, imageUrl }) => {
  const classes = useImageContainerStyles();
  return (
    <Box className={classNames(classes.ImageContainer)}>
      {isRegular ? (
        <Image src={imageUrl} className={classNames(classes.Image)} square alt="" aria-label="" aria-hidden={true} />
      ) : (
        <Skeleton width="100%" height="100%" variant="rect" />
      )}
    </Box>
  );
};

ImageContainer.propTypes = {
  isRegular: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default ImageContainer;

import * as QuoteService from 'services/quote';
import { GET_QUOTE, GET_QUOTE_FAILED, GET_QUOTE_SUCCESS } from './types';

export const getRandomQuote = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_QUOTE });

      const quote = await QuoteService.getRandomQuote();

      dispatch({
        type: GET_QUOTE_SUCCESS,
        payload: quote,
      });

      return quote;
    } catch (error) {
      dispatch({ type: GET_QUOTE_FAILED });
      throw error;
    }
  };
};

import {
  GET_LESSONS,
  GET_LESSONS_SUCCESS,
  GET_LESSONS_FAILED,
  GET_LESSONS_BY_FILTER,
  GET_LESSONS_BY_FILTER_SUCCESS,
  GET_LESSONS_BY_FILTER_FAILED,
  GET_LESSONS_WITHOUT_COURSES,
  GET_LESSONS_WITHOUT_COURSES_SUCCESS,
  GET_LESSONS_WITHOUT_COURSES_FAILED,
  GET_LESSON_BY_ID,
  GET_LESSON_BY_ID_SUCCESS,
  GET_LESSON_BY_ID_FAILED,
  LOAD_MORE_LESSONS,
  LOAD_MORE_LESSONS_SUCCESS,
  LOAD_MORE_LESSONS_FAILED,
  LOAD_MORE_LESSONS_BY_FILTER,
  LOAD_MORE_LESSONS_BY_FILTER_SUCCESS,
  LOAD_MORE_LESSONS_BY_FILTER_FAILED,
} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  lessons: {
    count: 0,
    data: null,
  },
  filteredLessons: {
    count: 0,
    data: null,
  },
  lessonsWithoutCourses: { count: 0, data: null },
  lesson: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LESSONS:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSONS_SUCCESS:
      return {
        ...state,
        loading: false,
        lessons: action.payload,
      };
    case GET_LESSONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LESSONS_BY_FILTER:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSONS_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredLessons: action.payload,
      };
    case GET_LESSONS_BY_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LESSONS_WITHOUT_COURSES:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSONS_WITHOUT_COURSES_SUCCESS:
      return {
        ...state,
        loading: false,
        lessonsWithoutCourses: action.payload,
      };
    case GET_LESSONS_WITHOUT_COURSES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LOAD_MORE_LESSONS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_LESSONS_SUCCESS:
      return {
        ...state,
        loading: false,
        lessons: {
          count: action.payload.count,
          data: [...state.lessons.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_LESSONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOAD_MORE_LESSONS_BY_FILTER:
      return {
        ...state,
        loading: true,
      };
    case LOAD_MORE_LESSONS_BY_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredLessons: {
          count: action.payload.count,
          data: [...state.filteredLessons.data, ...action.payload.data],
        },
      };
    case LOAD_MORE_LESSONS_BY_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LESSON_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_LESSON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        lesson: action.payload,
      };
    case GET_LESSON_BY_ID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

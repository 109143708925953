import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { get } from 'lodash';

import { Box } from '@material-ui/core';

import { Country } from 'types/types';
import { AutoCompleteField } from 'components';

const CountryAndStateChooser = ({
  isLoadingCountries,
  values,
  countryFieldName = 'country',
  stateFieldName = 'state',
  setFieldValue,
  countryLabel,
  stateLabel,
  countries = [],
  alwaysShowStateChooser,
}) => {
  const chosenCountry = get(values, countryFieldName);
  const [states, setStates] = useState([]);
  const { t } = useTranslation();
  const determinedCountryLabel = countryLabel ? countryLabel : t('form_province');
  const determinedStateLabel = stateLabel ? stateLabel : t('form_state');

  const clearSelectedState = () => {
    setFieldValue(stateFieldName, null);
  };

  useEffect(() => {
    setStates(chosenCountry && chosenCountry.states ? chosenCountry.states : []);
  }, [chosenCountry]);

  return (
    <>
      <Box mb={3}>
        <Field
          loading={isLoadingCountries}
          component={AutoCompleteField}
          fullWidth
          name={countryFieldName}
          label={determinedCountryLabel}
          options={countries}
          getOptionLabel={(option) => option.country}
          renderOption={(option) => option.country}
          onChange={clearSelectedState}
        />
      </Box>
      {alwaysShowStateChooser || get(values, stateFieldName) || (states && states.length > 0) ? (
        <Box mb={3}>
          <Field
            component={AutoCompleteField}
            fullWidth
            name={stateFieldName}
            label={determinedStateLabel}
            options={states}
            getOptionLabel={(option) => option.state}
            renderOption={(option) => option.state}
          />
        </Box>
      ) : null}
    </>
  );
};

CountryAndStateChooser.propTypes = {
  isLoadingCountries: PropTypes.bool,
  values: PropTypes.object.isRequired,
  countries: PropTypes.arrayOf(Country),
  countryFieldName: PropTypes.string,
  stateFieldName: PropTypes.string,
  countryLabel: PropTypes.string,
  stateLabel: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  alwaysShowStateChooser: PropTypes.bool,
};

export default CountryAndStateChooser;

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  bold,
  breakWord,
  center,
  columnReverse,
  ellipsis,
  flex,
  flexEnd,
  grid,
  hidden,
  none,
  normal,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  transparent,
  underline,
  uppercase,
  vertical,
  visible,
} from 'constants/Jss';
import { TagFilterSize } from 'constants/Constants';

export const useFilterStyles = makeStyles((theme) => ({
  FilterButton: {
    display: flex,
    alignItems: center,
    height: 34,
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: uppercase,
    fontSize: 16,
  },
  FilterIcon: {
    display: flex,
    alignItems: center,
    cursor: pointer,
    '& svg': {
      width: 20,
      height: 18,
    },
  },
  FilterDialogContainer: {
    position: relative,
    '& ul': {
      backgroundColor: theme.palette.common.black,
      paddingTop: theme.spacing(4),
      borderRadius: 4,
      marginTop: theme.spacing(1.25),
      position: relative,
      maxWidth: TagFilterSize.Narrow,
      outline: none,
    },
    '&.Wide ul': {
      maxWidth: TagFilterSize.Wide,
    },
    '& ul.Top': {
      marginTop: 0,
      marginBottom: theme.spacing(1.25),
    },
    '&.Small': {
      '& ul': {
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
      },
    },
    '&:not(.Numbered) > div:nth-child(2)': {
      zIndex: 10,
    },
    '&.Numbered > div:nth-child(3)': {
      zIndex: 10,
    },
    '& .MuiIconButton-root svg': {
      fill: theme.palette.primary.main,
    },
  },
  ActiveFiltersBox: {
    position: absolute,
    top: theme.spacing(-1.5),
    right: theme.spacing(-1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: 13,
    display: flex,
    alignItems: center,
    justifyContent: center,
    zIndex: 1,
    minWidth: 20,
    '&.Mobile': {
      minWidth: 18,
    },
  },
  ActiveFilters: {
    color: theme.palette.black,
    fontSize: 14,
    fontWeight: bold,
    lineHeight: 1,
    padding: '4px 6px 2px',
    '&.Mobile': {
      fontSize: 12,
    },
  },
  FilterContent: {
    position: absolute,
    bottom: 0,
  },
  CloseButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
    width: '100%',
  },
  CloseButton: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    '&:hover': {
      backgroundColor: theme.palette.grey,
    },
  },
  CloseIconContainer: {
    position: absolute,
    top: 0,
    right: 0,
  },
  CloseIconButton: {
    padding: 0,
    color: transparent,
    backgroundColor: transparent,
    '&:hover': {
      color: transparent,
      backgroundColor: transparent,
    },
    '&:focus': {
      color: transparent,
      backgroundColor: transparent,
    },
  },
  CloseIcon: {
    width: 20,
    height: 20,
  },
  Triangle: {
    position: absolute,
    left: 'calc(50% - 3px)',
    width: 0,
    height: 0,
    borderLeft: '6px solid transparent',
    borderRight: '6px solid transparent',
    borderBottom: `10px solid ${theme.palette.primary.main}`,
    '&:not(.Top)': {
      top: -10,
    },
    '&.Top': {
      bottom: -10,
      transform: 'rotate(180deg)',
    },
  },
}));

export const useCheckboxFilterStyles = makeStyles((theme) => ({
  CheckboxFilterContainer: {
    '& .MuiCheckbox-root': { color: theme.palette.common.black },
    color: theme.palette.primary.main,
    '& .MuiFormControlLabel-root': {
      alignItems: center,
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      whiteSpace: nowrap,
    },
  },
}));

export const useTagFilterStyles = makeStyles((theme) => ({
  TagFilterContainer: {
    display: grid,
    maxHeight: 300,
    overflowY: auto,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(3),
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    '& .MuiFormControlLabel-label': {
      color: theme.palette.primary.main,
      maxWidth: 160,
      fontSize: 14,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
    '& .MuiFormControlLabel-root': { alignItems: center },
  },
  TagFilterWrapper: {
    position: relative,
    overflowY: visible,
  },
  QuickFilterContainer: {
    position: absolute,
    top: theme.spacing(-5),
    left: theme.spacing(2),
    display: flex,
    '& > div': {
      display: flex,
      alignItems: center,
    },
  },
  ClearBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  InterestBox: {
    borderLeft: `1px solid ${theme.palette.white}`,
    paddingLeft: theme.spacing(3),
  },
  ActionText: {
    cursor: pointer,
    color: theme.palette.common.white,
    textDecoration: underline,
    fontSize: 14,
    lineHeight: 1,
  },
}));

export const useSortingDialogStyles = makeStyles((theme) => ({
  SortingContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: pointer,
    '&:not(.Mobile)': {
      textTransform: uppercase,
      '&:hover': {
        backgroundColor: theme.palette.whiteHover,
      },
    },
    '& .SortingBox': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '&:not(:last-child) .SortingBox': {
      borderBottom: `2px solid ${theme.palette.whiteHover}`,
      '&.Mobile': {
        borderWidth: 1,
        borderColor: theme.palette.grey70,
      },
    },
  },
  SortingButton: {
    color: theme.palette.grey70,
    '&:hover': {
      color: theme.palette.black,
    },
  },
}));

import { flex, flexStart, grid, none, wrap } from 'constants/Jss';

export const topicsPageStyles = (theme) => ({
  TopicsPageContainer: {
    width: '100%',
  },
  IntroText: {
    width: '100%',
    backgroundColor: theme.palette.greyF2,
    color: theme.palette.grey70,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    columnCount: 2,
    columnGap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileIntroText: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  MobileIntroTextBox: {
    backgroundColor: theme.palette.greyF2,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  CoursesContainer: {
    display: flex,
    justifyContent: flexStart,
    flexWrap: wrap,
    minHeight: 448,
    [theme.breakpoints.down('sm')]: {
      display: grid,
      'grid-template-columns': 'repeat(auto-fill, minmax(270px, 1fr))',
    },
  },
  NoResult: {
    marginLeft: 0,
    marginTop: theme.spacing(3),
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import { absolute, bold, center, flex, flexEnd, pointer, spaceBetween } from 'constants/Jss';

export const useCloseButtonContainerStyles = makeStyles((theme) => ({
  CloseButtonContainer: {
    backgroundColor: theme.palette.common.black,
    borderRadius: '23px',
    display: flex,
    alignItems: center,
    '&.WithText': { paddingLeft: theme.spacing(2) },
    '&.White': {
      backgroundColor: theme.palette.common.white,
    },
  },
  CloseTextBox: {
    textAlign: center,
    fontWeight: bold,
    color: theme.palette.common.white,
    '&:hover': {
      cursor: pointer,
    },
    '&.White': {
      color: theme.palette.common.black,
    },
  },
  CloseIcon: {
    fontSize: '35px',
    '&:hover': {
      cursor: pointer,
    },
    '&.WithText': {
      margin: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
  },
}));

const CloseIcon = ({ closeLabel, onClick, white, withText }) => {
  const { t } = useTranslation();
  const classes = useCloseButtonContainerStyles();
  return (
    <Box className={classNames(classes.CloseButtonContainer, { White: white, WithText: withText })} tabIndex={0}>
      {withText ? (
        <Box
          className={classNames(classes.CloseTextBox, { White: white })}
          onClick={onClick}
          role="button"
          aria-label={closeLabel ? closeLabel : t('common_close')}
        >
          {closeLabel ? closeLabel : t('common_close')}
        </Box>
      ) : null}
      <CancelRoundedIcon
        className={classNames(classes.CloseIcon, { WithText: withText })}
        fontSize="large"
        color="primary"
        onClick={onClick}
      />
    </Box>
  );
};

CloseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  closeLabel: PropTypes.string,
  white: PropTypes.bool,
  withText: PropTypes.bool,
};

export default CloseIcon;

import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';

import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';

import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => {
  return {
    CheckboxField: {
      verticalAlign: 'top',
    },
    fullWidth: {
      width: '100%',
    },
    error: {
      '&.Mui-error': {
        paddingTop: theme.spacing(1),
        color: theme.palette.white,
      },
    },
  };
});

const TextField = ({
  field,
  form,
  fullWidth,
  showError = true,
  readOnly,
  label,
  variant = 'outlined',
  InputProps,
  ...props
}) => {
  const classes = useStyles();
  const hasError = Boolean(form && field && get(form.errors, field.name) && get(form.touched, field.name));
  return (
    <div className={classNames(classes.CheckboxField, { [classes.fullWidth]: fullWidth, [classes.error]: hasError })}>
      <MuiFormControlLabel
        control={
          <MuiCheckbox
            inputProps={{
              ...(InputProps || {}),
              readOnly: (InputProps || {}).readOnly || readOnly || false,
            }}
            {...(field || {})}
            {...props}
            variant={variant}
            onChange={async () => {
              await form.setFieldValue(field.name, !field.value);
              form.setFieldTouched(field.name, true);
            }}
            value={field && field.value && !!field.value}
            checked={field && field.value && !!field.value}
            name={field.name}
          />
        }
        label={label}
      />
      {showError && form && field ? (
        <ErrorMessage field={field} form={form} variant={variant} className={classNames(classes.error)} />
      ) : null}
    </div>
  );
};

export default TextField;

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Main from './Main';

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    topics: state.topics.topics,
    termsAndConditions: state.documents.documents.termsAndConditions,
    privacyPolicy: state.documents.documents.privacyPolicy,
  };
};

const mapDispatchToProps = {};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Main);

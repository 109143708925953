import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/citations';

export const getRandomQuote = async () => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}`,
  };
  return await request(requestData);
};

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { Typography } from 'components';
import { useSLTTitleStyles } from './styles';
import sltLogo from 'assets/images/slt_logo.png';

const SLTTitle = ({ id, children, withLogo, lowTopPadding, center, titleClass }) => {
  const classes = useSLTTitleStyles();

  return (
    <Container
      className={classNames(classes.SLTTitleContainer, titleClass, {
        [classes.SLTTitleContainerLowPadding]: lowTopPadding,
        [classes.SLTTitleContainerCenter]: center,
      })}
      maxWidth="lg"
      id={id}
    >
      {withLogo ? (
        <Box mr={center ? 0 : 2}>
          <img src={sltLogo} className={classes.SLTTitleLogo} />
        </Box>
      ) : null}
      <Box className={classNames({ [classes.SectionTitleContentCenter]: center })}>
        <Typography variant="h2" color="textSecondary" className={classNames({ [classes.SLTTitleCenter]: center })}>
          {children}
        </Typography>
        <Box className={classes.Line} />
      </Box>
    </Container>
  );
};

SLTTitle.propTypes = {
  children: PropTypes.node,
  withLogo: PropTypes.bool,
  lowTopPadding: PropTypes.bool,
  center: PropTypes.bool,
  id: PropTypes.string,
  titleClass: PropTypes.string,
};

export default SLTTitle;

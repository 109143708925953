import React from 'react';
import PropTypes from 'prop-types';

import { Box, Container } from '@material-ui/core';

import HomeTitle from './HomeTitle';
import HomeDescription from './HomeDescription';
import HomeSearch from './HomeSearch';
import HomeLearningButtons from './HomeLearningButtons';

import { useHomeJumbotronStyles } from './styles';

const HomeJumbotron = ({ search, isLoggedIn }) => {
  const classes = useHomeJumbotronStyles();

  return (
    <Box className={classes.JumbotronContainer}>
      <Container>
        <HomeTitle isLoggedIn={isLoggedIn} />
        <HomeDescription isLoggedIn={isLoggedIn} />
        <HomeLearningButtons />
      </Container>
    </Box>
  );
};

HomeJumbotron.propTypes = {
  search: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
};

export default HomeJumbotron;

import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, inlineBlock, capitalize, bold, pointer, wrap, block } from 'constants/Jss';

export const useTabContainerStyles = makeStyles((theme) => ({
  TabContainer: {
    width: '100%',
    display: block,
    alignItems: center,
    textAlign: center,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  TabTitlesContainer: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(8),
    },
  },
  Tab: {
    display: inlineBlock,
    fontSize: 21,
    color: theme.palette.black,
    textTransform: capitalize,
    '&.Active': {
      fontFamily: 'Montserrat',
      fontWeight: bold,
    },
    '&:not(.Active)': {
      cursor: pointer,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      width: '33%',
      textAlign: center,
      margin: 0,
      marginTop: theme.spacing(2),
      whiteSpace: wrap,
      paddingBottom: theme.spacing(0.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
  },
  UnderlineBox: {
    border: `4px solid ${theme.palette.greyLoginLine}`,
    borderRadius: 10,
    '&.Active': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

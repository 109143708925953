import { createSvgIcon } from './SvgIcon/createSvgIcon';

import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as FacebookWhite } from './facebook-white.svg';
import { ReactComponent as Apple } from './apple.svg';
import { ReactComponent as Google } from './google.svg';
import { ReactComponent as BookmarkFilled } from './bookmark-filled.svg';
import { ReactComponent as BookmarkOutline } from './bookmark-outline.svg';
import { ReactComponent as Lesson } from './lesson.svg';
import { ReactComponent as ClockCircularOutline } from './clock-circular-outline.svg';
import { ReactComponent as Visited } from './visited.svg';
import { ReactComponent as Succeeded } from './succeeded.svg';
import { ReactComponent as Failed } from './failed.svg';
import { ReactComponent as Level } from './level.svg';
import { ReactComponent as Money } from './money.svg';
import { ReactComponent as LessCoin } from './less-coin.svg';
import { ReactComponent as MoreCoin } from './more-coin.svg';
import { ReactComponent as X } from './x-icon.svg';
import { ReactComponent as Filter } from './filter.svg';
import { ReactComponent as SLTLogo } from './SLT_Logo.svg';
import { ReactComponent as CorrectSymbol } from './correct-symbol.svg';
import { ReactComponent as TickWithCircle } from './tick_with_circle.svg';
import { ReactComponent as Plus } from './plus-yellow.svg';

export const FacebookIcon = createSvgIcon(Facebook);
export const InstagramIcon = createSvgIcon(Instagram);
export const TwitterIcon = createSvgIcon(Twitter);
export const FacebookWhiteIcon = createSvgIcon(FacebookWhite);
export const BookmarkFilledIcon = createSvgIcon(BookmarkFilled);
export const BookmarkOutlineIcon = createSvgIcon(BookmarkOutline);
export const GoogleIcon = createSvgIcon(Google);
export const AppleIcon = createSvgIcon(Apple);
export const LessCoinIcon = createSvgIcon(LessCoin);
export const MoreCoinIcon = createSvgIcon(MoreCoin);
export const LessonIcon = createSvgIcon(Lesson);
export const ClockCircularOutlineIcon = createSvgIcon(ClockCircularOutline);
export const VisitedIcon = createSvgIcon(Visited);
export const FailedIcon = createSvgIcon(Failed);
export const SucceededIcon = createSvgIcon(Succeeded);
export const LevelIcon = createSvgIcon(Level);
export const MoneyIcon = createSvgIcon(Money);
export const XIcon = createSvgIcon(X);
export const FilterIcon = createSvgIcon(Filter);
export const ArrowDownIcon = createSvgIcon(ArrowDown);
export const SLTLogoIcon = createSvgIcon(SLTLogo);
export const CorrectSymbolIcon = createSvgIcon(CorrectSymbol);
export const TickWithCircleIcon = createSvgIcon(TickWithCircle);
export const PlusIcon = createSvgIcon(Plus);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { Button, Dialog, Typography } from 'components';
import { auto } from 'constants/Jss';

import { useSuccessRegistrationDialogStyles } from './styles';

const SuccessRegistrationDialog = ({ open, closeDialog }) => {
  const { t } = useTranslation();
  const classes = useSuccessRegistrationDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={(e) => {
        closeDialog(e);
      }}
      title={t('registration_confirm')}
      contentClass={classes.Dialog}
      aria-modal={true}
      role="dialog"
      aria-labelledby="CloseButton"
      maxWidth="sm"
      primaryColor
      closeWithText
      scroll="paper"
      content={
        <Box className={classes.Container} ml={auto} mr={auto}>
          <Box className={classes.Description} mb={8}>
            <Typography>{t('registration_confirm_description')}</Typography>
          </Box>
          <Box mb={1}>
            <Button
              className={classes.CloseButtonContainer}
              buttonClassName="Pink"
              onClick={closeDialog}
              id="CloseButton"
              role="button"
              aria-label={t('close')}
              large
            >
              {t('close')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

SuccessRegistrationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default SuccessRegistrationDialog;

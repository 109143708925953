import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/auth';

export const login = async (credentials) => {
  return await request({
    headers: {
      platform: 'web',
    },
    method: Methods.POST,
    resource: `${BASE_URL}/login/email`,
    data: credentials,
  });
};

export const socialLogin = async (provider, data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/login/social/${provider}`,
    data,
  });
};

export const register = async (data) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/registration`,
    data,
  });
};

export const verifyEmail = async ({ language, token }) => {
  return await request({
    headers: {
      'Accept-Language': language,
    },
    method: Methods.POST,
    resource: `${BASE_URL}/verify`,
    data: { token },
  });
};

export const forgotPassword = async (email) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/forgotten-password-email`,
    headers: {
      platform: 'web',
    },
    data: {
      email,
    },
  });
};

export const resetPassword = async ({ token, password }) => {
  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/password/reset-by-token`,
    data: {
      token,
      password,
    },
  });
};

export const logout = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/logout`,
  });
};

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NotFound from './NotFound';
import { removeCommonLoader } from 'store/loader/actions';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      removeCommonLoader,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(NotFound);

import { GET_DASHBOARD, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILED } from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  dashboard: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboard: action.payload || [],
      };
    case GET_DASHBOARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, TextField, LottieAnimation } from 'components';
import { auto } from 'constants/Jss.js';
import { useLoginDialogStyles } from './styles';
import forgotAnimationData from 'assets/animation/forgotAnimation.json';

const ForgotPasswordDialog = ({ open, closeDialog, submit, t, history }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('form_invalid_email')).required(t('form_required')),
  });

  const classes = useLoginDialogStyles();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-modal={true}
      role="dialog"
      closeWithText
      content={
        <Formik initialValues={{ email: '' }} validationSchema={validationSchema} onSubmit={submit}>
          {({ handleSubmit }) => (
            <Form>
              <Box className={classes.FormBox} ml={auto} mr={auto}>
                <Box mb={3} mt={1} className={classes.AlignCenter}>
                  <LottieAnimation size="sm" animationData={forgotAnimationData} />
                </Box>
                <Box mb={5}>
                  <Typography variant="h2" color="textPrimary" className={classes.TextAlignCenter}>
                    {t('form_forgot_password_title')}
                  </Typography>
                </Box>
                <Box mb={5}>
                  <Typography variant="body1" color="textPrimary" className={classes.TextAlignCenter}>
                    {t('form_forgot_password_sub_title')}
                  </Typography>
                </Box>

                <Box mb={7}>
                  <Field
                    component={TextField}
                    name="email"
                    label={t('form_email')}
                    autoComplete="off"
                    fullWidth
                    InputProps={{ 'aria-label': t('form_email') }}
                    variant="standard"
                  />
                </Box>
                <Box className={classes.NewPasswordBox} mb={1}>
                  <Button
                    className={classes.LoginButtonContainer}
                    buttonClassName="Pink"
                    onClick={handleSubmit}
                    type="submit"
                    id="AskForNewPasswordButton"
                    role="button"
                    aria-label={t('form_ask_for_new_password')}
                    fullWidth
                    large
                  >
                    {t('form_ask_for_new_password')}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      }
    />
  );
};

ForgotPasswordDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ForgotPasswordDialog);

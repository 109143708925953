import React from 'react';
import MuiSkeleton from '@material-ui/lab/Skeleton';

export const Skeleton = ({ maxWidth, style, ...props }) => {
  const appliedStyle = { ...(style || {}) };

  if (maxWidth) {
    appliedStyle.maxWidth = maxWidth;
  }

  return <MuiSkeleton {...props} style={appliedStyle} />;
};

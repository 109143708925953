import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

import { Button, TextField } from 'components';

import { bold, center, column, flex, none, pointer, spaceBetween } from 'constants/Jss';
import makeStyles from '@material-ui/core/styles/makeStyles';

const FORM_RADIUS = 4;
const BASE_HEIGHT = 66;
export const useSearchFromStyles = makeStyles((theme) => ({
  FormBox: {
    borderRadius: FORM_RADIUS,
  },
  Form: {
    display: flex,
    width: '100%',
    justifyContent: spaceBetween,
    alignItems: center,
    borderRadius: FORM_RADIUS,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
    },
  },
  SearchButton: {
    fontWeight: bold,
    textTransform: none,
    [theme.breakpoints.up('md')]: {
      height: BASE_HEIGHT,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: FORM_RADIUS,
      borderTopRightRadius: FORM_RADIUS,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  InputWrapper: {
    display: flex,
    justifyContent: spaceBetween,
    width: '100%',
    height: BASE_HEIGHT,
    alignItems: center,
    backgroundColor: theme.palette.white,
    borderBottomLeftRadius: FORM_RADIUS,
    borderTopLeftRadius: FORM_RADIUS,
    '&.WithBorder': {
      border: `1px solid ${theme.palette.greyC4}`,
      [theme.breakpoints.up('md')]: {
        borderRightStyle: none,
      },
    },
    [theme.breakpoints.down('sm')]: {
      borderBottomRightRadius: FORM_RADIUS,
      borderTopRightRadius: FORM_RADIUS,
    },
  },
  SearchTextField: { border: none },
  NoBorderNotchedOutline: {
    border: none,
  },
  CloseSearch: {
    alignSelf: center,
    paddingRight: theme.spacing(1),
    cursor: pointer,
    color: theme.palette.grey70,
  },
}));

const SearchFrom = ({
  isLoading,
  search,
  initialSearchString,
  searchClassName,
  onClearInput,
  buttonLabel,
  inputLabel,
  withBorder,
}) => {
  const classes = useSearchFromStyles();
  const { t } = useTranslation();

  return (
    <Box className={classNames(classes.FormBox, searchClassName)}>
      <Formik initialValues={{ searchString: initialSearchString || '' }} onSubmit={search} enableReinitialize>
        {({ values, handleSubmit, setFieldValue }) => (
          <Form className={classNames(classes.Form)} onSubmit={() => {}}>
            <Box className={classNames(classes.InputWrapper, { WithBorder: withBorder })}>
              <Field
                white
                edgy
                component={TextField}
                name="searchString"
                label={inputLabel ? inputLabel : t('common_search')}
                autoComplete="off"
                fullWidth
                InputProps={{
                  classes: {
                    root: classes.SearchTextField,
                    notchedOutline: classes.NoBorderNotchedOutline,
                    outlined: classes.Label,
                  },
                  'aria-label': inputLabel ? inputLabel : t('common_search'),
                }}
              />
              <CloseIcon
                className={classNames(classes.CloseSearch, {
                  Hidden: !values.searchString,
                })}
                onClick={() => {
                  setFieldValue('searchString', '');
                  if (onClearInput) {
                    onClearInput();
                  }
                }}
              />
            </Box>
            <Button
              loading={isLoading}
              noTextTransform
              buttonClassName={classNames(classes.SearchButton)}
              onClick={handleSubmit}
              type="submit"
            >
              {buttonLabel ? buttonLabel : t('common_search_button_label')}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

SearchFrom.propTypes = {
  search: PropTypes.func.isRequired,
  onClearInput: PropTypes.func,
  isLoading: PropTypes.bool,
  withBorder: PropTypes.bool,
  buttonLabel: PropTypes.string,
  inputLabel: PropTypes.string,
  initialSearchString: PropTypes.string,
};

export default SearchFrom;

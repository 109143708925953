import PropTypes from 'prop-types';

import { ITEM_TYPES } from 'constants/Constants';

const createItem = (item, itemIndex, deleteItem) => {
  return { item, deleteItem: () => deleteItem(itemIndex) };
};

const { MARKER, COURSE, LESSON } = ITEM_TYPES;

const PathItemList = ({ items, renderLesson, renderCourse, renderSectionMarker, deleteItem }) => {
  const selectType = {
    [LESSON]: (item, itemIndex) => {
      return renderLesson(createItem(item, itemIndex, deleteItem));
    },
    [COURSE]: (item, itemIndex) => {
      return renderCourse(createItem(item, itemIndex, deleteItem));
    },
    [MARKER]: (item, itemIndex) => {
      return renderSectionMarker(createItem(item, itemIndex, deleteItem));
    },
  };

  return items.map((item, itemIndex) => {
    return selectType[item.itemType.toLowerCase()](item, itemIndex);
  });
};

PathItemList.propTypes = {
  items: PropTypes.array,
  renderLesson: PropTypes.func,
  renderCourse: PropTypes.func,
  renderSectionMarker: PropTypes.func,
  deleteItem: PropTypes.func,
};

export default PathItemList;

import makeStyles from '@material-ui/core/styles/makeStyles';

import { bold, center, column, flex, left } from 'constants/Jss';

export const useQuoteStyles = makeStyles((theme) => ({
  QuoteBox: {
    backgroundColor: theme.palette.black,
  },
  QuoteContainer: {
    textAlign: center,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
    },
  },
  QuoteTextBox: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(6),
    },
  },
  SLTLogoIconBox: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    textAlign: left,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      textAlign: center,
    },
  },
  SLTLogoIcon: { fontSize: 120 },
  QuoteText: {
    width: '100%',
    fontFamily: 'WonderUnit',
    fontWeight: bold,
  },
  QuoteAuthor: { paddingTop: theme.spacing(1) },
}));

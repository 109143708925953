import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Container from '@material-ui/core/Container';

import noImage from 'assets/images/no-image.png';
import { Search } from './Search';
import { MenuItem } from './MenuItem';
import { DropdownMenu } from 'components/DropdownMenu/DropdownMenu';
import { useDesktopHeaderStyles } from './styles';
import { Profile } from 'types/types';
import { AdminLoginButton } from './AdminLoginButton';
import { SLTHeaderLogoIcon, SLTLogoIcon } from 'assets/icons';
import { ReactComponent as SLTHeaderLogo } from '../../assets/icons/slt_header_logo.svg';

export const DesktopHeader = ({ onLogoClick, getPath, isLoggedIn, search, profile, logout: logoutAction }) => {
  const classes = useDesktopHeaderStyles();
  const { t } = useTranslation();

  const [topicsOpen, setTopicsOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [aboutOpen, setAboutOpen] = React.useState(false);

  const topicsAnchorRef = React.useRef(null);
  const profileAnchorRef = React.useRef(null);
  const aboutAnchorRef = React.useRef(null);

  const handleTopicsClose = (event) => {
    if (topicsAnchorRef.current && topicsAnchorRef.current.contains(event.target)) {
      return;
    }
    setTopicsOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevTopicsOpen = React.useRef(topicsOpen);
  React.useEffect(() => {
    if (prevTopicsOpen.current === true && topicsOpen === false) {
      topicsAnchorRef.current.focus();
    }

    prevTopicsOpen.current = topicsOpen;
  }, [topicsOpen]);

  const handleProfileToggle = () => {
    setProfileOpen((prevProfileOpen) => !prevProfileOpen);
  };

  const handleProfileClose = (event) => {
    if (profileAnchorRef.current && profileAnchorRef.current.contains(event.target)) {
      return;
    }
    setProfileOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevProfileOpen = React.useRef(profileOpen);
  React.useEffect(() => {
    if (prevProfileOpen.current === true && profileOpen === false) {
      profileAnchorRef && profileAnchorRef.current && profileAnchorRef.current.focus();
    }

    prevProfileOpen.current = profileOpen;
  }, [profileOpen]);

  const handleAboutClose = (event) => {
    if (aboutAnchorRef.current && aboutAnchorRef.current.contains(event.target)) {
      return;
    }
    setAboutOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevAboutOpen = React.useRef(aboutOpen);
  React.useEffect(() => {
    if (prevAboutOpen.current === true && aboutOpen === false) {
      aboutAnchorRef.current.focus();
    }

    prevAboutOpen.current = aboutOpen;
  }, [aboutOpen]);

  const closeSubmenus = (event) => {
    handleTopicsClose(event);
    handleProfileClose(event);
    handleAboutClose(event);
  };

  const logout = (event) => {
    logoutAction();
    handleProfileClose(event);
  };

  return (
    <Box className={classes.Header}>
      <Container maxWidth="lg">
        <Box ml={-2} mr={-2} className={classes.HeaderGrid}>
          <Box className={classes.LogoContainer} marginRight={2}>
            <Avatar
              className={classes.Logo}
              tabIndex={0}
              role="link"
              alt={t('alt_link_to_home_page')}
              aria-label={t('alt_link_to_home_page')}
              id="page-logo"
            >
              <SLTHeaderLogo />
            </Avatar>
          </Box>
          <AdminLoginButton />
        </Box>
      </Container>
    </Box>
  );
};

DesktopHeader.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  getPath: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
  profile: Profile,
};

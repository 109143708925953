import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';

import { useMandatoryLinksStyles } from './styles';
import { NotificationTypes } from 'constants/Constants';
import { Switch } from 'components';

export const MandatoryLinks = ({
  fieldName,
  fieldValue,
  onChange,
  setFieldTouched,
  parts,
  showNotification,
  required,
}) => {
  const classes = useMandatoryLinksStyles();
  const { t } = useTranslation();

  const [links, setLinks] = React.useState([]);

  React.useEffect(() => {
    setLinks(parts.filter((part) => !!part.link).map((part) => ({ link: part.link, opened: false })));
  }, []);

  const markOpened = (link) => {
    setLinks(links.map((linkObj) => (linkObj.link === link ? { link, opened: true } : linkObj)));
  };

  const disabled = () => links.filter((link) => link.opened).length !== links.length;

  return (
    <Box className={classes.MandatoryLinkBox}>
      <Box tabIndex={0}>
        {parts.map((part) => {
          return part.link ? (
            <a
              href={part.link}
              target="_blank"
              className={classes.Link}
              onClick={() => markOpened(part.link)}
              key={parts.indexOf(part)}
            >
              {part.text}
            </a>
          ) : (
            <span key={parts.indexOf(part)}>{part.text}</span>
          );
        })}
      </Box>
      <FormControlLabel
        control={
          <Switch
            color="secondary"
            name={fieldName}
            checked={fieldValue}
            onChange={onChange}
            disabled={required ? disabled() : false}
            mini
          />
        }
        label=""
        onBlur={() => setFieldTouched(fieldName, true)}
        onClick={() =>
          required &&
          disabled() &&
          showNotification({ translateKey: 'errors_open_documents', type: NotificationTypes.error })
        }
        tabIndex={0}
        aria-label={t('alt_accept_terms_and_conditions')}
      />
    </Box>
  );
};

MandatoryLinks.propTypes = {
  fieldName: PropTypes.string,
  fieldValue: PropTypes.bool,
  onChange: PropTypes.func,
  setFieldTouched: PropTypes.func,
  parts: PropTypes.array,
  showNotification: PropTypes.func,
  required: PropTypes.bool,
};

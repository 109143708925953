import * as ProfileService from 'services/profile';
import * as AuthService from 'services/auth';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  DELETE_PROFILE,
  DELETE_PROFILE_FAILED,
  DELETE_PROFILE_SUCCESS,
  GET_BADGES,
  GET_BADGES_FAILED,
  GET_BADGES_SUCCESS,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_FAILED,
  GET_PROFILE_DETAILS_SUCCESS,
  LOGIN,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_PROFILE_DATA,
  UPDATE_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS_FAILED,
  UPDATE_PROFILE_DETAILS_SUCCESS,
} from './types';
import { removeToken, setToken } from 'utils/refreshToken';
import * as DocumentService from 'services/documents';
import { CHECK_DOCUMENTS, CHECK_DOCUMENTS_FAILED, CHECK_DOCUMENTS_SUCCESS } from '../documents/types';
import { detectLanguage } from 'utils/language';

export const setProfileData = ({ id, name, email, avatar, tags, firstLoginDate }) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROFILE_DATA,
      payload: {
        id,
        name,
        email,
        avatar,
        tags,
        firstLoginDate,
      },
    });
  };
};

export const logout = (getAuthorizedRoutes) => {
  return async (dispatch) => {
    AuthService.logout();

    removeToken();

    getAuthorizedRoutes();

    await dispatch({
      type: LOGOUT,
    });
  };
};

export const refreshToken = (token) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN });

      const { access_token, expires_in } = await ProfileService.refreshToken(token);

      setToken({ token: access_token, expiresAt: expires_in });

      dispatch({
        type: LOGIN_SUCCESS,
      });

      // TODO: Uncomment if needed in future
      // dispatch({
      //   type: CHECK_DOCUMENTS,
      // });
      // try {
      //   const { isThereNew } = await DocumentService.checkDocuments();

      //   dispatch({
      //     type: CHECK_DOCUMENTS_SUCCESS,
      //     payload: isThereNew,
      //   });
      // } catch (e) {
      //   dispatch({ type: CHECK_DOCUMENTS_FAILED });
      // }
    } catch (error) {
      removeToken();
      if (window) {
        window.location.href = window.location.origin + '/' + detectLanguage(window.location.pathname).toLowerCase();
      }
      dispatch({ type: LOGIN_FAILED });
      throw error;
    }
  };
};

export const getProfileDetails = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROFILE_DETAILS });

      const profile = await ProfileService.getProfileDetails();

      dispatch({
        type: GET_PROFILE_DETAILS_SUCCESS,
        payload: profile,
      });

      return profile;
    } catch (error) {
      dispatch({ type: GET_PROFILE_DETAILS_FAILED });
      throw error;
    }
  };
};

// TODO: Removed if not needed for reference
// export const updateProfileDetails = ({ name, groupId, groupName, groupType, email, avatar, settlementId, tags }) => {
//   return async (dispatch) => {
//     try {
//       dispatch({ type: UPDATE_PROFILE_DETAILS });

//       const data = await ProfileService.updateProfileDetails({
//         name,
//         groupId,
//         groupName,
//         groupType,
//         email,
//         avatar,
//         settlementId,
//         tags,
//       });

//       dispatch({
//         type: UPDATE_PROFILE_DETAILS_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({ type: UPDATE_PROFILE_DETAILS_FAILED });
//       throw error;
//     }
//   };
// };

export const updateProfile = (userId, userData) => {
  return async (dispatch) => {
    try {
      await ProfileService.updateProfile(userId, userData);
      dispatch(getProfileDetails({}));
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILE_DETAILS_FAILED,
        payload: error,
      });
      throw error;
    }
  };
};

export const changePassword = (userId, oldPassword, newPassword) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHANGE_PASSWORD });

      const { success } = await ProfileService.changePassword(userId, { oldPassword, newPassword });

      dispatch({
        type: CHANGE_PASSWORD_SUCCESS,
      });

      return success;
    } catch (error) {
      dispatch({ type: CHANGE_PASSWORD_FAILED });
      throw error;
    }
  };
};

export const deleteProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PROFILE });

      await ProfileService.deleteProfile();

      dispatch({
        type: DELETE_PROFILE_SUCCESS,
      });

      return true;
    } catch (error) {
      dispatch({ type: DELETE_PROFILE_FAILED });
      throw error;
    }
  };
};

export const getBadges = ({ language, token }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_BADGES });

      const { data } = await ProfileService.getBadges(language, token);
      dispatch({
        type: GET_BADGES_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_BADGES_FAILED });
      throw error;
    }
  };
};

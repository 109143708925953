import React from 'react';
import PropTypes from 'prop-types';

import { Pagination } from 'components';

const withPagination = (Component) => {
  const wrappedWithPagination = ({
    count,
    itemsPerPage,
    currentPage,
    setCurrentPage,
    visiblePageLinks,
    paginationClassName,
    ...props
  }) => {
    return (
      <>
        <Component {...props} />
        <Pagination
          itemCount={count}
          itemPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          visiblePageLinks={visiblePageLinks}
          className={paginationClassName}
        />
      </>
    );
  };

  wrappedWithPagination.propTypes = {
    count: PropTypes.number,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    itemsPerPage: PropTypes.number,
    visiblePageLinks: PropTypes.number,
    className: PropTypes.string,
    paginationClassName: PropTypes.string,
  };

  return wrappedWithPagination;
};

export default withPagination;

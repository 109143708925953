import makeStyles from '@material-ui/core/styles/makeStyles';

import { grid, wrap } from 'constants/Jss';

export const usePopularLearningStyles = makeStyles((theme) => ({
  CourseCardListBox: {
    justifyContent: 'spaceAround',
    flexWrap: wrap,
    minHeight: 448,
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(270px, 1fr))',
  },
}));

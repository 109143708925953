import queryString from 'query-string';

import request from 'utils/request';
import { Methods } from 'utils/http';
import { SearchTypes } from 'constants/Constants';

import { getRemovedUnusedParams } from 'utils/getRemovedUnusedParams';
import { getTagIdArrayAsQueryParam } from 'utils/getTagIdArrayAsQueryParam';

const BASE_URL = '/courses';
import { COURSE_ID_FOR_LESSON_WITHOUT_COURSES } from 'constants/Constants';

export const getCourses = async ({
  page = 1,
  sortBy = 'title',
  order = 'asc',
  searchText = null,
  subsidiaryId = null,
}) => {
  const params = {
    page,
    searchText,
    sortBy,
    order,
    subsidiaryId,
  };

  const removedUnusedParams = getRemovedUnusedParams(params);

  let query = queryString.stringify(removedUnusedParams);
  query += getTagIdArrayAsQueryParam(tags);

  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}?${query}`,
    language,
  });
};

export const getCoursesByFilter = async ({
  page = 1,
  searchType = SearchTypes.none,
  filterString = null,
  tagIds = null,
  examDone = null,
  minutesMin = null,
  minutesMax = null,
}) => {
  const params = {
    page,
    filterString,
    examDone,
    minutesMin,
    minutesMax,
    tagIds,
    searchType,
  };

  let query = queryString.stringify({ page });

  return await request({
    method: Methods.POST,
    resource: `${BASE_URL}/mobile?${query}`,
    data: { ...params },
  });
};

export const getImportantAndBookmarkCourses = async ({ page = 1 }) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/mobile/important-and-bookmark?page=${page}`,
  });
};

export const getSurpriseMeCourses = async ({ page = 1 }) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/mobile/surprise-me?page=${page}`,
  });
};

export const getCoursesForTopic = async ({
  page = 1,
  searchText = null,
  moduleId,
  tags = null,
  examDone = null,
  minutesMin = null,
  minutesMax = null,
}) => {
  const params = {
    page,
    searchText,
    moduleId,
    examDone,
    minutesMin,
    minutesMax,
  };

  const removedUnusedParams = getRemovedUnusedParams(params);

  let query = queryString.stringify(removedUnusedParams);
  query += getTagIdArrayAsQueryParam(tags);

  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}?${query}`,
  });
};

export const getCourseById = async (id, language) => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${id}/mobile`,
    language,
  });
};

export const getCoursesWithExams = async (page, language, token) => {
  const requestData = {
    method: Methods.POST,
    resource: `${BASE_URL}/mobile?page=${page}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const getOngoingCourses = async (page) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/mobile/visited-courses?page=${page}`,
  };
  return await request(requestData);
};

export const getCompanyCourses = async (page) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/mobile/company-courses?page=${page}`,
  };
  return await request(requestData);
};

export const getCompletedCourses = async (page) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/mobile/completed-courses?page=${page}`,
  };
  return await request(requestData);
};

export const getBookmarkedCourses = async (page, language, token) => {
  const requestData = {
    method: Methods.GET,
    resource: `${BASE_URL}/bookmarked?page=${page}`,
    language,
  };
  if (token) {
    requestData.headers = { Authorization: token };
  }
  return await request(requestData);
};

export const bookmarkCourse = async (id, isBookmarked) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/${id}/bookmark`,
    data: {
      bookmarkCourse: isBookmarked,
    },
  });
};

export const getCourseForSingleLessons = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/${COURSE_ID_FOR_LESSON_WITHOUT_COURSES}/mobile`,
  });
};

import makeStyles from '@material-ui/core/styles/makeStyles';

export const usePathDnDContainerFieldStyles = makeStyles((theme) => ({
  AbstractPathDnDContainerWrapper: {
    flexGrow: 1,
  },
  ModalElements: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
}));

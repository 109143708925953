import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import firebase from 'firebase/app';

import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import withStyles from '@material-ui/core/styles/withStyles';

import { getToken } from 'utils/refreshToken';
import AppLoader from './AppLoader';
import Main from 'modules/Main';
import { SnackbarContent } from 'components';
import { appStyles } from './styles';
import { Notification } from 'types/types';
import { SnackTime } from 'constants/Constants';
import Settings from 'env';

firebase.initializeApp(Settings.FIREBASE_CONFIG);

class App extends Component {
  static propTypes = {
    getProfileDetails: PropTypes.func.isRequired,
    profileDetails: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    notification: Notification,
    tokenExpiresAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    routes: PropTypes.arrayOf(PropTypes.object),
    getAuthorizedRoutes: PropTypes.func,
    removeNotification: PropTypes.func,
  };

  state = {
    snackOpen: false,
    loading: false,
    globalLoading: true,
  };

  clearNotificationTimeout = null;
  loaderTimeout = null;

  async componentDidMount() {
    document.addEventListener('keydown', this.handleTab, false);
    document.addEventListener('mousedown', this.handleClick, false);
    document.body.classList.add('FocusHidden');

    await this.fetchIntialAppData();
  }

  async fetchDataWithGlobalLoading(request = async () => ({})) {
    try {
      this.setState({ globalLoading: true });
      return await request();
    } catch (e) {
    } finally {
      this.setState({ globalLoading: false });
    }
  }

  async fetchIntialAppData() {
    const { profileDetails, getProfileDetails } = this.props;
    const { token } = getToken();
    await this.fetchDataWithGlobalLoading(async () => {
      if (token && !profileDetails.email) {
        await getProfileDetails();
      }
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { notification, removeNotification, loading, t } = this.props;
    const { globalLoading } = this.state;

    if (notification) {
      const content = notification.content || t(notification.translateKey);
      const prevContent = prevProps.notification
        ? prevProps.notification.content || t(prevProps.notification.translateKey)
        : null;
      if (this.state.snackOpen && content !== prevContent) {
        this.clearNotificationTimeout && clearTimeout(this.clearNotificationTimeout);
        this.clearNotificationTimeout = setTimeout(removeNotification, SnackTime);
      } else if (!this.state.snackOpen && !prevState.snackOpen) {
        this.setState({ snackOpen: true });
        this.clearNotificationTimeout = setTimeout(removeNotification, SnackTime);
      }
    }

    if (((!prevProps.loading && loading) || globalLoading) && !this.loaderTimeout) {
      this.loaderTimeout = setTimeout(() => this.setLoading(true), 20);
    }

    if (!loading && !globalLoading) {
      this.clearLoaderTimeout();
      if (this.state.loading) {
        this.setLoading(false);
      }
    }
  }

  componentWillUnmount() {
    this.clearLoaderTimeout();
    document.removeEventListener('keydown', this.handleTab, false);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  setLoading = (loading) => {
    this.setState({ loading });
  };

  clearLoaderTimeout = () => {
    if (this.loaderTimeout) {
      clearTimeout(this.loaderTimeout);
      this.loaderTimeout = null;
    }
  };

  setSnackOpen = (snackOpen) => {
    this.setState({ snackOpen });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setSnackOpen(false);
    this.props.removeNotification();
  };

  handleTab = (e) => {
    if (e.key === 'Tab') {
      document.body.classList.add('FocusShown');
      document.body.classList.remove('FocusHidden');
    }
  };

  handleClick = () => {
    document.body.classList.add('FocusHidden');
    document.body.classList.remove('FocusShown');
  };

  render() {
    const { notification, routes, t, getAuthorizedRoutes, classes } = this.props;
    const props = { ...this.props };
    const { globalLoading } = this.state;
    delete props['classes'];

    return (
      <div>
        <CssBaseline />
        {this.state.loading ? <AppLoader show /> : null}
        {notification ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            onClose={this.handleClose}
            TransitionComponent={Slide}
          >
            <SnackbarContent
              variant={notification.type}
              message={notification.content || t(notification.translateKey)}
              onClose={this.handleClose}
            />
          </Snackbar>
        ) : null}
        {!globalLoading && <Main routes={routes} {...props} getAuthorizedRoutes={getAuthorizedRoutes} />}

        <CookieConsent
          location="bottom"
          buttonText={t('cookie_button_text')}
          style={{ backgroundColor: '#000000BB' }}
          buttonClasses={classes.CookieButton}
        >
          {t('cookie_text')}
        </CookieConsent>
      </div>
    );
  }
}

export default withTranslation()(withStyles(appStyles)(App));

import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, left } from 'constants/Jss';

export const useLearningStyles = makeStyles((theme) => ({
  LearningContainer: { paddingTop: theme.spacing(3.5) },
  LearningTitle: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      textAlign: left,
    },
  },
  LearningButtonBox: {
    textAlign: left,
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      textAlign: center,
    },
  },
}));

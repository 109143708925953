import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

import { Skeleton, Tag as TagComponent } from 'components';
import { Tag } from 'types/types';

import { flex, flexEnd, wrap } from 'constants/Jss';

const CARD_RIGHT_MARGIN_SPACING = 1;
const MAX_TAGS = 3;
const MAX_MOBILE_TAGS = 3;
export const useTagContainerStyles = makeStyles((theme) => ({
  BasicTagContainer: { flexGrow: 1, display: flex, flexWrap: wrap, alignItems: flexEnd, alignContent: flexEnd },
  MobileTagContainer: {
    width: '100%',
  },
  TagBox: {
    width: `calc(100%/${MAX_TAGS} - ${theme.spacing(CARD_RIGHT_MARGIN_SPACING)}px)`,
  },
}));

const TagContainer = ({ tags, skeleton, isRegular, mobile }) => {
  const getMaxTags = () => (mobile ? MAX_MOBILE_TAGS : MAX_TAGS);
  const classes = useTagContainerStyles();
  return (
    <Box className={classNames(classes.BasicTagContainer, { [classes.MobileTagContainer]: mobile })}>
      {isRegular
        ? tags.slice(0, getMaxTags()).map((tag) => (
            <Box key={tag.id} className={classNames(classes.TagBox)} mr={CARD_RIGHT_MARGIN_SPACING} mt={1}>
              <TagComponent tagTitle={tag.title} />
            </Box>
          ))
        : null}
      {skeleton
        ? Array.from({ length: getMaxTags() }).map((_, i) => (
            <Box key={`skeleton_${i}`} mr={CARD_RIGHT_MARGIN_SPACING} mt={1}>
              <Skeleton width={150} maxWidth="100%" height={23} />
            </Box>
          ))
        : null}
    </Box>
  );
};

TagContainer.propTypes = {
  tags: PropTypes.arrayOf(Tag),
  skeleton: PropTypes.bool,
  isRegular: PropTypes.bool,
  mobile: PropTypes.bool,
};

export default TagContainer;

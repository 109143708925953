import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';

import { topicsPageStyles } from './styles';
import { ExpandableBox } from 'components/ExpandableBox/ExpandableBox';
import { getProfileTags, getTags } from 'store/tags/actions';
import { getTopics } from 'store/topics/actions';
import { getCoursesForTopic, resetCourses, setFilters } from 'store/courses/actions';
import { Course, FiltersType, Tag, Topic } from 'types/types';
import { MobileFilter, CardContainer } from 'components';
import { ItemTypes } from 'constants/Constants';
import { getNumberOfActiveFilters } from 'utils/filters';
import { getProfileDetails } from 'store/profile/actions';
import { CourseCardList, DesktopFilter } from 'components';
import { isBrowser } from 'utils/browser';

class TopicsPage extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(Tag),
    filters: FiltersType,
    setFilters: PropTypes.func,
    topics: PropTypes.arrayOf(Topic),
    courses: PropTypes.arrayOf(Course),
    numberOfCourses: PropTypes.number,
    getCoursesForTopic: PropTypes.func,
    loadMoreCourses: PropTypes.func,
    history: PropTypes.object,
    profileTags: PropTypes.arrayOf(Tag),
    isLoggedIn: PropTypes.func,
    getProfileDetails: PropTypes.func,
    addCommonLoader: PropTypes.func,
    removeCommonLoader: PropTypes.func,
  };

  static async getInitialData({ dispatch, language, token, pathname, search, firstLoad }) {
    if (!firstLoad) {
      dispatch(resetCourses());
    }

    try {
      const topics = await dispatch(getTopics(language));
      const moduleId = topics.find((topic) => topic.pathname === pathname).id;
      const params = queryString.parse(search);

      const [profile] = await Promise.all([
        token && dispatch(getProfileDetails({ token })),
        dispatch(getTags(language)),
        dispatch(getCoursesForTopic({ moduleId }, language)),
        token && dispatch(getProfileTags({ language, token })),
      ]);

      await dispatch(
        setFilters({
          tags: params && params.filter && profile && profile.tags && token ? profile.tags.map((tag) => tag.id) : [],
          examDone: null,
          duration: null,
          searchString: null,
        })
      );

      if (isBrowser()) {
        const titles = document.getElementsByTagName('h1');
        titles && titles.length && titles[0].focus();
      }
    } catch (err) {
      console.log(err);
    }
  }

  state = {
    page: 1,
  };

  async componentDidUpdate(prevProps) {
    const { filters, getCoursesForTopic } = this.props;

    if (!isEqual(filters, prevProps.filters)) {
      const module = this.getTopic();

      if (module) {
        this.setState({ page: 1 });

        await getCoursesForTopic({
          moduleId: module.id,
          searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
          tags: filters.tags.length ? filters.tags : null,
          examDone: filters.examDone === true || filters.examDone === false ? filters.examDone : null,
          minutesMin: (filters.duration && filters.duration.minutesMin) || null,
          minutesMax: (filters.duration && filters.duration.minutesMax) || null,
          page: 1,
        });
      }
    }
  }

  getTopic = () => {
    const { topics, history } = this.props;
    return topics.find((topic) => topic.pathname === history.location.pathname);
  };

  search = ({ searchString }) => {
    const { setFilters } = this.props;
    setFilters({ searchString: searchString.length ? searchString : null });
  };

  loadMore = async (page) => {
    const { filters, loadMoreCoursesForTopic } = this.props;
    const module = this.getTopic();

    this.setState({ page });

    await loadMoreCoursesForTopic({
      moduleId: module.id,
      searchText: filters.searchString && filters.searchString.length ? filters.searchString : null,
      tags: filters.tags.length ? filters.tags : null,
      examDone: filters.examDone === true || filters.examDone === false ? filters.examDone : null,
      minutesMin: (filters.duration && filters.duration.minutesMin) || null,
      minutesMax: (filters.duration && filters.duration.minutesMax) || null,
      page,
    });
  };

  render() {
    const {
      classes,
      tags,
      filters,
      setFilters,
      courses,
      numberOfCourses,
      profileTags,
      isLoggedIn,
      isLoading,
      shouldShowLoading,
      t,
    } = this.props;
    const { page } = this.state;
    const topic = this.getTopic();

    return !topic ? null : (
      <div className={classes.TopicsPageContainer}>
        <Box mb={6} className={classes.IntroText} tabIndex={0}>
          {topic.description}
        </Box>
        <Box mb={4} className={classes.MobileIntroText}>
          <ExpandableBox
            text={topic.description}
            minHeight={170}
            textBoxClass={classes.MobileIntroTextBox}
            alwaysExpanded
          />
        </Box>
        <Box>
          <DesktopFilter
            search={this.search}
            tags={tags}
            filters={filters}
            setFilters={setFilters}
            profileTags={profileTags}
            isLoggedIn={isLoggedIn}
            itemType={ItemTypes.Course}
          />
          <MobileFilter
            search={this.search}
            tags={tags}
            filters={filters}
            setFilters={setFilters}
            numberOfItems={numberOfCourses}
            profileTags={profileTags}
            isLoggedIn={isLoggedIn}
            itemType={ItemTypes.Course}
            isDataLoading={shouldShowLoading}
          />
        </Box>
        <CardContainer
          items={courses || []}
          numberOfItems={numberOfCourses}
          loadMoreItems={this.loadMore}
          title={t('common_course')}
          numberOfActiveFilters={getNumberOfActiveFilters(filters)}
          noResultClass={classes.NoResult}
          isLoading={isLoading}
          skeleton={shouldShowLoading}
          page={page}
        >
          <Box mt={2} className={classes.CoursesContainer}>
            <CourseCardList courses={courses} skeleton={shouldShowLoading} />
          </Box>
        </CardContainer>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(withStyles(topicsPageStyles)(TopicsPage)), TopicsPage);

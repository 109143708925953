import makeStyles from '@material-ui/core/styles/makeStyles';
import { none, pointer, transparent } from 'constants/Jss';

export const useDropdownMenuStyles = makeStyles((theme) => ({
  DropdownButton: {
    textTransform: none,
    color: theme.palette.white,
    fontSize: 14,
    paddingBottom: 5,
    '&:hover': {
      color: theme.palette.whiteHover,
    },
    '& svg': {
      width: 20,
      height: 14,
    },
  },
  AvatarButton: {
    backgroundColor: transparent,
    marginLeft: theme.spacing(2),
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
  },
  MenuListContainer: {
    top: '14px !important',
    zIndex: 10,
    padding: 2,
  },
  MenuList: {
    maxWidth: 244,
    padding: 0,
  },
  Avatar: {
    cursor: pointer,
  },
}));

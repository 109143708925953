import { GET_QUOTE, GET_QUOTE_FAILED, GET_QUOTE_SUCCESS } from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  quote: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_QUOTE:
      return {
        ...state,
        loading: true,
      };
    case GET_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: action.payload || null,
      };
    case GET_QUOTE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

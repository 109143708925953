import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';

const useEditStyles = makeStyles((theme) => ({
  Button: {
    '&.MuiIconButton-root svg': {
      fill: theme.palette.primary.main,
      fontSize: 28,
    },
    '&.MuiIconButton-root': {
      backgroundColor: theme.palette.black,
      minHeight: 43,
      minWidth: 43,
      padding: 0,
    },
  },
}));

const EditIconButton = ({ onClick }) => {
  const classes = useEditStyles();
  return (
    <IconButton className={classes.Button} onClick={onClick}>
      <EditIcon />
    </IconButton>
  );
};

EditIconButton.propTypes = {
  onClick: PropTypes.func,
};

export default EditIconButton;

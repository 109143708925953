import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { detectLanguage } from 'utils/language';
import { isArrayNullOrEmpty } from 'utils/arrayUtil';
import { useIsMounted } from 'hooks/useIsMounted';
import { SLTSection, withNoItems } from 'components';
import CoursesContainer from './CoursesContainer';
const CoursesContainerWithHandleEmpty = withNoItems(CoursesContainer);

const RollableCoursesContainer = ({ title, getData, shouldShowLoading, lowTopPadding, skeleton = false }) => {
  const [courseData, setCourseData] = useState({ data: [], count: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const history = useHistory();
  const isRegular = !skeleton;

  useEffect(() => {
    const getCourseData = async () => {
      try {
        const courseData = await getData({ page });
        if (isMounted.current) {
          setCourseData(courseData);
        }
      } catch (e) {}
    };
    getCourseData();
  }, []);

  const redirectToCourses = () => {
    if (isRegular) {
      const language = detectLanguage(history.location.pathname).toLowerCase();
      history.push(`/${language}/${t('url_courses')}`);
    }
  };
  const { data: courseList, count } = courseData;

  return (
    <SLTSection title={title} lowTopPadding={lowTopPadding}>
      <CoursesContainerWithHandleEmpty
        isLoading={isLoading}
        emptyMessage={t('no_courses_found')}
        isEmpty={isArrayNullOrEmpty(courseList)}
        courseList={courseList}
        shouldShowLoading={shouldShowLoading}
        onMoreClick={redirectToCourses}
      />
    </SLTSection>
  );
};

RollableCoursesContainer.propTypes = {
  title: PropTypes.string,
  getData: PropTypes.func.isRequired,
  lowTopPadding: PropTypes.bool,
  shouldShowLoading: PropTypes.bool,
  skeleton: PropTypes.bool,
};

export default RollableCoursesContainer;

import makeStyles from '@material-ui/core/styles/makeStyles';

import { auto, center } from 'constants/Jss';

export const useSLTCardsContainerStyles = makeStyles((theme) => ({
  SLTCardsContainer: {
    paddingTop: theme.spacing(3),
    marginLeft: auto,
    marginRight: auto,
    textAlign: center,
  },
}));

export const useSLTCardStyles = makeStyles((theme) => ({
  SLTCardBox: {
    [theme.breakpoints.only('md')]: {
      paddingTop: theme.spacing(3),
    },
  },
  Media: {
    maxWidth: auto,
    height: theme.spacing(17),
  },
  Description: {
    paddingTop: theme.spacing(2),
  },
}));

export const provinceMapper = (provinces = []) =>
  provinces.map((country) => ({
    ...country,
    country: country.country.trim(),
    ageLimit: Number(country.ageLimit),
    ...(country.states && {
      states: country.states.map((state) => ({
        ...state,
        state: state.state.trim(),
        ageLimit: Number(state.ageLimit),
      })),
    }),
  }));

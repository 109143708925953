import makeStyles from '@material-ui/core/styles/makeStyles';

import { center } from 'constants/Jss';

export const useLetsTalkStyles = makeStyles((theme) => ({
  LetsTalkBox: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(6.5),
    background: 'transparent radial-gradient(at 0% 0%, #F3EC13 0%, #F4813E 25%, #E96159 75%, #E43778 100%)',
    backgroundPosition: '0% 0%',
    backgroundRepeat: 'no-repeat',
  },
  LetsTalkDescription: {
    paddingTop: theme.spacing(3),
  },
  DescriptionBox: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(10),
    },
    [theme.breakpoints.down('md')]: { paddingBottom: theme.spacing(5) },
  },
  LetsTalkButtonBox: {
    [theme.breakpoints.down('md')]: { textAlign: center },
  },
}));

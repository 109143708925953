import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import { Skeleton, Button } from 'components';
import { ItemTypes } from 'constants/Constants';
import { Course, Lesson } from 'types/types';

import { useCardContainerStyles } from './styles';

export const CardContainer = ({
  children,
  numberOfItems,
  items,
  loadMoreItems,
  numberOfActiveFilters,
  noResultClass,
  title,
  resultClass,
  isLoading,
  skeleton,
  page: initialPage,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = React.useState(initialPage || 1);
  const classes = useCardContainerStyles();

  useEffect(() => {
    if (initialPage) {
      setPage(initialPage);
    }
  }, [initialPage]);

  const loadMore = async () => {
    const newPage = page + 1;
    if (!initialPage) {
      setPage(newPage);
    }
    loadMoreItems(newPage);
  };

  return (
    <>
      {numberOfItems || skeleton ? (
        <Box
          mt={2}
          mb={5}
          mr={3}
          className={classNames(classes.NumberOfItems, 'page-animation-exit-hidden')}
          tabIndex={0}
        >
          {numberOfItems ? (
            <span>
              {numberOfItems} {title}
            </span>
          ) : (
            <Skeleton height={20} width={60} />
          )}
        </Box>
      ) : numberOfActiveFilters > 0 && !isLoading ? (
        <Box
          ml={1.5}
          className={classNames(classes.NoResult, noResultClass, 'page-animation-exit-hidden')}
          tabIndex={0}
        >
          {t('no_result_with_filters')}
        </Box>
      ) : !isLoading ? (
        <Box
          ml={1.5}
          className={classNames(classes.NoResult, noResultClass, 'page-animation-exit-hidden')}
          tabIndex={0}
        >
          {t('no_result_without_filters')}
        </Box>
      ) : null}
      <Box className={resultClass}>{children}</Box>
      {numberOfItems <= items.length ? null : (
        <Box mb={8} className={classes.ShowMoreBox}>
          <Button
            loading={isLoading}
            color="secondary"
            className={classes.ShowMoreButton}
            onClick={loadMore}
            aria-label={t('common_more')}
          >
            {t('common_more')}
          </Button>
        </Box>
      )}
    </>
  );
};

CardContainer.propTypes = {
  children: PropTypes.node,
  numberOfItems: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.oneOfType([Course, Lesson])),
  loadMoreItems: PropTypes.func,
  title: PropTypes.string,
  resultClass: PropTypes.string,
  numberOfActiveFilters: PropTypes.number,
  noResultClass: PropTypes.string,
  skeleton: PropTypes.bool,
  page: PropTypes.number,
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import MDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { CloseIcon } from 'components';

import { absolute, auto, bold, center, pointer } from 'constants/Jss';

const useStyles = makeStyles((theme) => ({
  DialogWhiteBg: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.white,
    },
  },
  CloseButtonContainer: {
    position: absolute,
    top: theme.spacing(3),
    right: theme.spacing(3),
    color: theme.palette.black,
    backgroundColor: 'transparent',
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      top: 26,
      right: 26,
    },
  },
  CloseButton: {
    color: theme.palette.black,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 17,
    width: 34,
    height: 34,
    display: 'flex',
    alignItems: center,
    justifyContent: center,
    cursor: pointer,
    [theme.breakpoints.down('sm')]: {
      top: 26,
      right: 26,
      '& svg': {
        width: 20,
        height: 20,
      },
    },
  },
  DialogHeader: {
    margin: 0,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(2),
    textAlign: center,
  },
  DialogTitle: {
    fontFamily: 'Montserrat',
    fontWeight: bold,
    fontSize: 32,
    paddingBottom: theme.spacing(1),
  },
  DialogPrimaryColor: {
    '& input:valid ~ fieldset, & input:valid:focus ~ fieldset, & input:valid:hover ~ fieldset, & .MuiInputAdornment-root:hover ~ fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: 2,
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiIconButton-root svg': {
      fill: theme.palette.primary.main,
    },
  },
  NoContentPadding: {
    padding: 0,
  },
  MaxHeight: {
    height: '100%',
  },
  Scroll: {
    '& .MuiPaper-root': {
      '&::-webkit-scrollbar': {
        width: 5,
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.black,
        borderRadius: 20,
        marginTop: 50,
        marginBottom: 50,
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: 20,
        backgroundColor: theme.palette.primary.main,
      },
    },
  }
}));

const Dialog = React.forwardRef(
  (
    {
      open,
      onClose,
      actions,
      title,
      content,
      contentClass,
      titleClass,
      titleBoxClass,
      maxWidth,
      alwaysFullScreen,
      noClose,
      whiteBg,
      primaryColor,
      noContentPadding,
      maxHeight,
      closeWithText,
      scroll = 'body',
    },
    ref
  ) => {
    const theme = useTheme();
    const fullScreen = alwaysFullScreen || useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const { t } = useTranslation();

    return (
      <MDialog
        ref={ref}
        fullScreen={fullScreen}
        open={!!open}
        onClose={onClose}
        maxWidth={maxWidth || 'xs'}
        scroll={scroll}
        fullWidth
        className={classNames({[classes.Scroll]: scroll === 'paper', [classes.DialogWhiteBg]: whiteBg, [classes.DialogPrimaryColor]: primaryColor })}
      >
        {title ? (
          <Box
            marginTop={10}
            marginBottom={5}
            marginLeft={auto}
            marginRight={auto}
            className={classNames(titleBoxClass, classes.DialogHeader)}
          >
            <h1 className={classNames(classes.DialogTitle, titleClass)} tabIndex={0}>
              {title}
            </h1>
          </Box>
        ) : null}
        {!actions && !noClose ? (
          <div
            className={classes.CloseButtonContainer}
            tabIndex={0}
            aria-label={t('alt_close_dialog')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onClose(e);
              }
            }}
          >
            <CloseIcon onClick={onClose} withText={closeWithText} />
          </div>
        ) : null}
        <DialogContent
          className={classNames(contentClass, {
            [classes.NoContentPadding]: noContentPadding,
            [classes.MaxHeight]: maxHeight,
          })}
        >
          {content}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </MDialog>
    );
  }
);

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  contentClass: PropTypes.string,
  titleClass: PropTypes.string,
  titleBoxClass: PropTypes.string,
  isFullScreen: PropTypes.bool,
  closeButton: PropTypes.node,
  whiteBg: PropTypes.bool,
  primaryColor: PropTypes.bool,
  noContentPadding: PropTypes.bool,
  maxHeight: PropTypes.bool,
  closeWithText: PropTypes.bool,
  scroll: PropTypes.string,
};

export default Dialog;

import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { SizeProps } from './lottieSizeProps';

const useStyles = makeStyles((theme) => ({
  LogoAnimationContent: {
    '#lottie': {
      backgroundColor: theme.palette.black,
      width: '100%',
      height: '100%',
      display: 'block',
      overflow: 'hidden',
      transform: 'translate3d(0, 0, 0)',
      textAlign: 'center',
      opacity: 1,
    },
  },
}));

const LottieAnimation = ({ size, loop, autoplay, animationData, ...props }) => {
  const classes = useStyles();
  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    renderer: 'svg',
  };

  const getSize = () => {
    switch (size) {
      case 'lg':
        return { height: 565, width: 302 };
      case 'md':
        return { height: 300, width: 300 };
      case 'sm':
        return { height: 200, width: 165 };
      case 'xs':
        return { height: 100, width: 87 };
      case 'xxs':
        return { height: 60, width: 52 };
      default:
        return { height: 365, width: 302 };
    }
  };

  const { height, width } = getSize();

  return (
    <div className={classes.LogoAnimationContent} {...props}>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  );
};

LottieAnimation.propTypes = {
  size: SizeProps,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  animationData: PropTypes.object,
};

LottieAnimation.defaultProps = {
  size: 'lg',
  loop: false,
  autoplay: true,
};

export default LottieAnimation;

import React from 'react';
import { connect } from 'react-redux';

import { Box, Container, Grid } from '@material-ui/core';

import { Typography } from 'components';
import { SLTLogoIcon } from 'assets/icons';

import { useQuoteStyles } from './styles';

const Quote = ({ quote }) => {
  const classes = useQuoteStyles();

  return (
    <Box className={classes.QuoteBox}>
      <Container>
        <Grid container className={classes.QuoteContainer}>
          <Grid item className={classes.SLTLogoIconBox} xs={12} md={2}>
            <SLTLogoIcon useOriginalColors className={classes.SLTLogoIcon} />
          </Grid>
          <Grid item className={classes.QuoteTextBox} xs={12} md={10}>
            <Typography className={classes.QuoteText} variant="h5">
              {quote && quote.text ? quote.text : ''}
            </Typography>
            <Typography color="primary" className={classes.QuoteAuthor} variant="body1">
              {quote && quote.author ? quote.author : ''}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    quote: state.quote.quote,
  };
};
export default connect(mapStateToProps)(Quote);

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { MaxSkeletonCard } from 'constants/Constants';
import { CourseCard } from 'components/CourseCard/CourseCard';

export const CourseCardList = ({ courses, skeleton, itemContainerClass, onCourseCardClick }) => {
  const history = useHistory();

  return (
    <>
      {!skeleton
        ? (courses || []).map((course) => (
            <Box mb={6} ml={1.5} mr={1.5} key={course.id} className={itemContainerClass}>
              <CourseCard course={course} history={history} onClick={onCourseCardClick} />
            </Box>
          ))
        : null}
      {skeleton
        ? Array.from({ length: MaxSkeletonCard }).map((_, i) => (
            <Box mb={6} ml={1.5} mr={1.5} key={`skeleton_${i}`}>
              <CourseCard skeleton />
            </Box>
          ))
        : null}
    </>
  );
};

CourseCardList.propTypes = {
  courses: PropTypes.array.isRequired,
  itemContainerClass: PropTypes.string,
  skeleton: PropTypes.bool,
  onCourseCardClick: PropTypes.func,
};

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';

import { Typography, LoginAnimation } from 'components';
import { auto, bold, center, column, flex, noRepeat, normal } from 'constants/Jss';

const BAR_HEIGHT = 100;
const MOBILE_BAR_HEIGHT = 350;
const useStyles = makeStyles((theme) => ({
  root: {
    height: 3 * BAR_HEIGHT,
    marginLeft: auto,
    marginRight: auto,
    display: flex,
    alignItems: center,
    justifyContent: center,
    background:
      `url("data:image/svg+xml,<svg viewBox='0 0 600 ${BAR_HEIGHT}' xmlns='http://www.w3.org/2000/svg'><rect width='600' height='${BAR_HEIGHT}' rx='${
        BAR_HEIGHT / 2
      }'/></svg>"),` +
      `url("data:image/svg+xml,<svg viewBox='0 0 660 ${BAR_HEIGHT}' xmlns='http://www.w3.org/2000/svg'><rect width='660' height='${BAR_HEIGHT}' rx='${
        BAR_HEIGHT / 2
      }'/></svg>"),` +
      `url("data:image/svg+xml,<svg viewBox='0 0 570 ${BAR_HEIGHT}' xmlns='http://www.w3.org/2000/svg'><rect width='570' height='${BAR_HEIGHT}' rx='${
        BAR_HEIGHT / 2
      }'/></svg>")`,
    backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
    backgroundPosition: '48% 0px, 50% 100px, 50% 200px',
    backgroundSize: `601px ${BAR_HEIGHT}px, 675px ${BAR_HEIGHT}px, 570px ${BAR_HEIGHT}px`,
    [theme.breakpoints.down('sm')]: {
      height: MOBILE_BAR_HEIGHT,
      flexDirection: column,
      background: `url("data:image/svg+xml,<svg viewBox='0 0 100% 100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' rx='50'/></svg>")`,
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
      backgroundPosition: center,
    },
  },
  LabelBox: {
    textAlign: center,
  },
  Title: {
    '&.Title': {
      fontSize: 32,
      lineHeight: '50px',
      fontFamily: 'WonderUnit',
      fontWeight: normal,
      paddingBottom: 0,
      textAlign: center,
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
      },
    },
  },
  Description: {
    fontSize: 18,
    lineHeight: '24px',
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  FourOFour: {
    fontFamily: 'Montserrat',
    fontSize: 116,
    fontWeight: bold,
    color: theme.palette.primary.main,
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      marginLeft: auto,
      marginRight: auto,
      fontSize: 110,
    },
  },
}));

const NotFound = ({ removeCommonLoader }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    removeCommonLoader();
  }, []);

  return (
    <Container>
      <Box pt={isSmall ? 8 : 12} pb={17}>
        <Box className={classes.root}>
          <Box mr={isSmall ? 0 : 3}>
            <LoginAnimation size={isSmall ? 'xs' : 'sm'} />
          </Box>
          <Box className={classes.LabelBox}>
            <Typography color="primary" fontWeight="ExtraBold" className={classes.Oops} variant="h1">
              {t('not_found_oops')}
            </Typography>
            <Typography color="primary" variant="body1">
              {t('not_found_page_not_found')}
            </Typography>
            <Typography className={classes.FourOFour}>404</Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;

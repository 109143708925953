import * as ProfileService from 'services/profile';
import { provinceMapper } from 'utils/mappers/province';
import { GET_PROVINCES, GET_PROVINCES_FAILED, GET_PROVINCES_SUCCESS } from './types';

export const getProvinces = (searchText) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PROVINCES });

      const data = await ProfileService.getProvinces(searchText);

      const mappedData = provinceMapper(data);

      dispatch({
        type: GET_PROVINCES_SUCCESS,
        payload: mappedData,
      });

      return data;
    } catch (error) {
      dispatch({ type: GET_PROVINCES_FAILED });
      throw error;
    }
  };
};

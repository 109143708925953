import WonderUnitFont from 'assets/fonts/WonderUnitSans-Regular.woff';
import WonderUnitBoldFont from 'assets/fonts/WonderUnitSans-Bold.woff';
import MontserratExtraBoldFont from 'assets/fonts/Montserrat-ExtraBold.woff';
import MontserratBoldFont from 'assets/fonts/Montserrat-ExtraBold.woff';
import MontserratSemiBoldFont from 'assets/fonts/Montserrat-SemiBold.woff';
import MontserratFont from 'assets/fonts/Montserrat-Regular.woff';

export const WonderUnit = {
  fontFamily: 'WonderUnit',
  fontWeight: 400,
  src: `
    local('WonderUnit'),
    local('WonderUnit-Regular'),
    url(${WonderUnitFont}) format('woff')
  `,
};

export const WonderUnitBold = {
  fontFamily: 'WonderUnit',
  fontWeight: 700,
  src: `
    local('WonderUnit'),
    local('WonderUnit-Bold'),
    url(${WonderUnitBoldFont}) format('woff')
  `,
};

export const MontserratExtraBold = {
  fontFamily: 'Montserrat',
  fontWeight: 900,
  src: `
    local('Montserrat'),
    local('Montserrat-ExtraBold'),
    url(${MontserratExtraBoldFont}) format('woff')
  `,
};

export const MontserratBold = {
  fontFamily: 'Montserrat',
  fontWeight: 900,
  src: `
    local('Montserrat'),
    local('Montserrat-Bold'),
    url(${MontserratBoldFont}) format('woff')
  `,
};

export const MontserratSemiBold = {
  fontFamily: 'Montserrat',
  fontWeight: 600,
  src: `
    local('Montserrat'),
    local('Montserrat-SemiBold'),
    url(${MontserratSemiBoldFont}) format('woff')
  `,
};

export const Montserrat = {
  fontFamily: 'Montserrat',
  fontWeight: 400,
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${MontserratFont}) format('woff')
  `,
};

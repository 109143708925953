import React, { forwardRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiButton from '@material-ui/core/Button';

import { absolute, inlineBlock, relative, uppercase, none, bold } from 'constants/Jss';

const styles = (theme) => ({
  root: {
    position: relative,
    display: inlineBlock,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: absolute,
    top: 'calc(50% - 12px)',
    right: 'calc(50% - 12px)',
  },
  fullWidth: {
    width: '100%',
  },
  wide: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  button: {
    height: '100%',
    borderRadius: 20,
    textTransform: uppercase,
    '&.NoTextTransform': {
      textTransform: none,
    },
    '&.Bold': {
      fontWeight: bold,
    },
    '&.Large': {
      fontSize: 16,
      lineHeight: 1,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontWeight: bold,
      borderRadius: 28,
    },
    '&.Pink': {
      color: theme.palette.white,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.hover,
      },
    },
    '&.Secondary': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.secondary.main}`,
      '&:hover': {
        color: theme.palette.white,
        backgroundColor: theme.palette.secondary.hover,
      },
    },
    '&.Cyan': {
      color: theme.palette.white,
      backgroundColor: theme.palette.cyan,
      '&:hover': {
        backgroundColor: theme.palette.cyanHover,
      },
    },
    '&.Blue': {
      color: theme.palette.white,
      backgroundColor: theme.palette.default.dark,
      '&:hover': {
        backgroundColor: theme.palette.default.darkHover,
      },
    },
    '&.White': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.greyE9,
      },
    },
  },
});

const Button = forwardRef(
  (
    {
      classes,
      loading,
      disabled,
      className,
      fullWidth,
      noTextTransform,
      buttonClassName,
      variant = 'contained',
      color = 'primary',
      large,
      wide,
      bold,
      ...props
    },
    ref
  ) => {
    return (
      <div ref={ref} className={classNames(classes.root, className, { [classes.fullWidth]: fullWidth })}>
        <MuiButton
          disabled={loading || disabled}
          className={classNames(
            classes.button,
            { Large: large, NoTextTransform: noTextTransform, [classes.wide]: wide, Bold: bold },
            buttonClassName
          )}
          fullWidth={fullWidth}
          variant={variant}
          color={color}
          {...props}
        />
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    );
  }
);

Button.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  large: PropTypes.bool,
  wide: PropTypes.bool,
  bold: PropTypes.bool,
};

export default withStyles(styles)(Button);

import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  absolute,
  auto,
  block,
  bold,
  breakWord,
  center,
  column,
  contain,
  ellipsis,
  flex,
  flexEnd,
  flexStart,
  grid,
  hidden,
  none,
  noRepeat,
  normal,
  pointer,
  relative,
  right,
  spaceBetween,
  vertical,
  visible,
  wrap,
} from 'constants/Jss';
import ProfileBg from 'assets/images/profileBg.png';

export const profilePageStyles = (theme) => ({
  ProfilePageContainer: {
    width: '100%',
  },

  SectionTitleContainer: {
    fontSize: 42,
    fontWeight: bold,
    '& .SectionTitle': {
      paddingBottom: theme.spacing(1),
    },
  },
  PersonalDataContainer: {},
  ChangePasswordContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
  DeleteProfileContainer: {
    width: '100%',
    height: 56,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.greyCC}`,
    position: relative,
    [theme.breakpoints.down('sm')]: {
      justifyContent: center,
      marginBottom: theme.spacing(4),
    },
  },
  DeleteActionContainer: {
    position: absolute,
    right: 0,
    height: 31,
    display: flex,
    alignItems: center,
    cursor: pointer,
  },
  DeleteProfileText: {
    fontSize: 16,
    color: theme.palette.grey70,
  },
  DeleteProfileIcon: {
    fill: theme.palette.secondary.main,
  },
  ProfileTagsBox: {
    display: block,
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileProfileTagsBox: {
    display: none,
    [theme.breakpoints.down('sm')]: {
      display: block,
    },
  },
  MobilePatchContainer: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
    height: 240,
    '& .Patch': {
      position: 'absolute',
      width: 142,
      height: auto,
      '&.LeftPatch': {
        left: 0,
      },
      '&.RightPatch': {
        right: 0,
      },
    },
  },
});

export const useCommonStyles = makeStyles((theme) => ({
  SubmitNewPasswordContainer: {
    display: flex,
    justifyContent: center,
  },
}));

export const useProfileDataFormStyles = makeStyles((theme) => ({
  ClearIndicator: {
    '&:hover': {
      backgroundColor: theme.palette.white,
    },
    '&.Filled': {
      visibility: visible,
    },
    '& svg': {
      fill: theme.palette.grey70,
    },
  },
  FileUploadBox: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingBottom: theme.spacing(6),
    },
  },
  FormBox: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  GridBox: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  KKVBox: {
    display: flex,
    alignItems: center,
    color: theme.palette.greyB3,
    fontSize: 16,
    fontWeight: normal,
  },
}));

export const useTagsDialogStyles = makeStyles((theme) => ({
  TagsDialogContainer: {
    position: relative,
    '& ul': {
      paddingTop: theme.spacing(4),
      borderRadius: 4,
      color: theme.palette.white,
      backgroundColor: theme.palette.secondary.main,
      marginTop: theme.spacing(1.25),
      position: relative,
      outline: none,
      maxWidth: 1108,
    },
    '& ul.Top': {
      marginTop: 0,
      marginBottom: theme.spacing(1.25),
    },
    '& > div': {
      zIndex: 10,
    },
  },
  AddTagContainer: {
    width: '100%',
    display: flex,
    justifyContent: spaceBetween,
    borderBottom: `1px solid ${theme.palette.grey70}`,
    paddingBottom: theme.spacing(1),
  },
  AddTagLabel: {
    color: theme.palette.grey70,
    fontSize: 18,
    fontWeight: bold,
    lineHeight: '36px',
  },
  AddTagButton: {
    borderRadius: '50%',
    width: 36,
    height: 36,
    minWidth: 36,
    padding: 0,
  },
  ProfileTagsContainer: {
    display: flex,
    flexWrap: wrap,
  },
  ProfileTag: {
    fontSize: 18,
    fontWeight: bold,
    lineHeight: 1,
    position: relative,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(7),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.cyan,
    borderRadius: 36,
    color: theme.palette.white,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  ClearTag: {
    position: absolute,
    top: 'calc(50% - 6px)',
    right: 11,
    fill: theme.palette.white,
    padding: 0,
    '& svg': {
      width: 12,
      height: 12,
    },
  },
}));

export const useProfileTagStyles = makeStyles((theme) => ({
  TagContainer: {
    display: grid,
    maxHeight: 300,
    maxWidth: 'calc(100% - 32px)',
    overflowY: auto,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 14,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
  },
}));

export const useDeleteProfileDialogStyles = makeStyles((theme) => ({
  PinkPatch: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    backgroundPosition: '-286px -250px',
    backgroundSize: '500px 445.066px',
    backgroundRepeat: `${noRepeat}`,
    '& .TitleBorder': {
      backgroundColor: theme.palette.secondary.main,
    },
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
      backgroundPosition: '-57px -95px',
      backgroundSize: '360px 365px',
      backgroundRepeat: noRepeat,
    },
  },
  DialogContent: {
    display: flex,
    flexDirection: column,
    justifyContent: spaceBetween,
    alignItems: center,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    textAlign: center,
    maxWidth: 700,
    marginLeft: auto,
    marginRight: auto,
  },
  ActionContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  Center: {
    textAlign: center,
  },
}));

import makeStyles from '@material-ui/core/styles/makeStyles';

import { flex } from 'constants/Jss';

export const useSLTTitleStyles = makeStyles((theme) => ({
  SLTTitleContainer: { paddingTop: theme.spacing(10), display: flex },
  SLTTitleContainerLowPadding: {
    paddingTop: theme.spacing(4),
  },
  SLTTitleContainerCenter: {
    flexFlow: 'column',
    alignItems: 'center',
  },
  SLTTitleCenter: {
    textAlign: 'center',
  },
  SLTTitleLogo: { width: theme.spacing(6) },
  Line: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(17),
    height: 5,
    borderRadius: 2.5,
  },
  SectionTitleContentCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

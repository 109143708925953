import Settings from 'env';
import Cookies from 'js-cookie';

export const isRefreshNeeded = () => {
  const expiresAt = Cookies.get(Settings.API_TOKEN_EXPIRES_AT);
  // we refresh the token if it will expire in 5 minutes
  return expiresAt && new Date().getTime() > new Date(expiresAt * 1000).getTime() - 1000 * 60 * 5;
};

export const getToken = () => {
  return {
    token: Cookies.get(Settings.API_TOKEN),
    expiresAt: Cookies.get(Settings.API_TOKEN_EXPIRES_AT),
  };
};

export const setToken = (token) => {
  Cookies.set(Settings.API_TOKEN, token.token, { expires: 365 });
  Cookies.set(Settings.API_TOKEN_EXPIRES_AT, token.expiresAt, { expires: 365 });
};

export const removeToken = () => {
  Cookies.remove(Settings.API_TOKEN);
  Cookies.remove(Settings.API_TOKEN_EXPIRES_AT);
};

export const setRefreshInProgress = (isInProgress) => {
  isInProgress
    ? Cookies.set(Settings.REFRESH_TOKEN_IN_PROGRESS, 'true')
    : Cookies.remove(Settings.REFRESH_TOKEN_IN_PROGRESS);
};

export const isRefreshInProgress = () => {
  return Cookies.get(Settings.REFRESH_TOKEN_IN_PROGRESS) === 'true';
};

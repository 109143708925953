import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

import { Typography } from 'components';
import { auto } from 'constants/Jss';

export const useDescriptionStyles = makeStyles((theme) => ({
  NarrowBox: {
    width: '100%',
    maxWidth: 960,
    lineHeight: '30px',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    backgroundColor: theme.palette.greyF8,
    marginLeft: auto,
    marginRight: auto,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  FullWidth: { maxWidth: '100%' },
}));

const Description = ({ description, fullWidth }) => {
  const classes = useDescriptionStyles();
  return (
    <Box className={classNames(classes.NarrowBox, { [classes.FullWidth]: fullWidth })} tabIndex={0}>
      <Typography variant="body1" color="textSecondary">
        {description}
      </Typography>
    </Box>
  );
};

Description.propTypes = { description: PropTypes.string, fullWidth: PropTypes.bool };

export default Description;

import makeStyles from '@material-ui/core/styles/makeStyles';

export const usePathEditorCardStyles = makeStyles((theme) => ({
  Container: {
    borderRadius: 10,
    boxShadow: '0px 5px 20px #00000019',
    '&:hover': {
      boxShadow: '0px 5px 20px #00000041',
    },
    backgroundColor: theme.palette.greyEF,
    display: 'flex',
    flexDirection: 'row',
    padding: 5,
    alignItems: 'center',
    marginBottom: 10,
  },
  DeleteIcon: {
    cursor: 'pointer',
    color: theme.palette.greyCC,
    fontSize: 40,
    marginLeft: 'auto',
  },
  Title: {
    color: theme.palette.black,
    fontSize: 18,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: 1,
  },
  Drag: {
    color: theme.palette.black,
    fontSize: 35,
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
  Hidden: {
    visibility: 'hidden',
  },
}));

export const usePathEditorCourseCardStyles = makeStyles((theme) => ({
  LessonsContainer: {
    marginTop: 5,
  },
}));

export const usePathEditorLessonCardStyles = makeStyles((theme) => ({
  LessonContainer: {
    marginLeft: 40,
    backgroundColor: theme.palette.white,
  },
}));

export const usePathEditorAddMarkerCardStyles = makeStyles((theme) => ({
  AddMarkerContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    opacity: 0.2,
    '&:hover': {
      opacity: 1,
    },
  },
  Plus: {
    cursor: 'pointer',
    color: theme.palette.white,
    fontSize: 28,
    marginLeft: 'auto',
  },
}));

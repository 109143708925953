import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import { Course } from 'types/types';
import { Button, CourseCardList } from 'components';
import { grid, right, wrap } from 'constants/Jss';

export const useRollableCoursesContainerStyles = makeStyles((theme) => ({
  CourseCardListBox: {
    justifyContent: 'spaceAround',
    flexWrap: wrap,
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(270px, 1fr))',
  },
  MoreButtonBox: {
    textAlign: right,
  },
}));

const MAX_ITEMS = 4;

const CoursesContainer = ({ courseList = [], shouldShowLoading, onMoreClick }) => {
  const classes = useRollableCoursesContainerStyles();
  const { t } = useTranslation();

  return (
    <>
      {courseList.length >= MAX_ITEMS ? (
        <Box pt={2} pr={2} className={classes.MoreButtonBox}>
          <Button color="secondary" noTextTransform onClick={onMoreClick}>
            {t('more_courses')}
          </Button>
        </Box>
      ) : null}
      <Box pt={1} className={classes.CourseCardListBox}>
        <CourseCardList
          courses={courseList.filter((_, index) => index < MAX_ITEMS)}
          skeleton={shouldShowLoading}
          itemContainerClass={classes.CourseCard}
        />
      </Box>
    </>
  );
};

CoursesContainer.propTypes = {
  shouldShowLoading: PropTypes.bool,
  courseList: PropTypes.arrayOf(Course),
  onMoreClick: PropTypes.func.isRequired,
};

export default CoursesContainer;

import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  absolute,
  auto,
  bold,
  center,
  column,
  cover,
  contain,
  ellipsis,
  flex,
  flexEnd,
  flexStart,
  hidden,
  none,
  noRepeat,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  uppercase,
  vertical,
  wrap,
} from 'constants/Jss';

import { BookmarkOutlineIcon, BookmarkFilledIcon } from 'assets/icons';

export const useBookmarkIconStyles = makeStyles((theme) => ({
  BookMarkIcon: {
    color: theme.palette.primary.main,
  },
}));

const BookmarkIcon = ({ filled, className, ...props }) => {
  const classes = useBookmarkIconStyles();
  return filled ? (
    <BookmarkFilledIcon className={className} {...props} />
  ) : (
    <BookmarkOutlineIcon className={className} {...props} />
  );
};
export default BookmarkIcon;

import {
  absolute,
  auto,
  bold,
  center,
  cover,
  flex,
  none,
  noRepeat,
  pointer,
  relative,
  emptyString,
  spaceBetween,
} from 'constants/Jss';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Background from 'assets/images/coffe-and-spices.jpg';

export const useHomeJumbotronStyles = makeStyles((theme) => ({
  JumbotronContainer: {
    color: theme.palette.white,
    position: relative,
    paddingTop: theme.spacing(6.5),

    background: `url(${Background})`,
    backgroundRepeat: noRepeat,
    backgroundSize: cover,
    backgroundPosition: `${center} ${center}`,
    zIndex: 1,
    '&::before': {
      width: '100%',
      content: emptyString,
      position: absolute,
      zIndex: -1,
      background: 'rgba(0,0,0,0.3)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
}));

export const useHomeTitleStyles = makeStyles((theme) => ({
  BasicHomeTitle: {
    textAlign: center,
    marginLeft: auto,
    marginRight: auto,
  },
}));

export const useHomeDescriptionStyles = makeStyles((theme) => ({
  Description: {
    marginLeft: auto,
    marginRight: auto,
    paddingTop: 14,
  },
}));

export const useHomeLearningButtonStyles = makeStyles((theme) => ({
  LearningButtonsBox: {
    paddingTop: theme.spacing(1),
    textAlign: center,
    paddingBottom: theme.spacing(14),
  },
  LearnButton: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(2.5),
  },
}));

export const useHomeSearchStyles = makeStyles((theme) => ({
  Search: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
    maxWidth: '45%',
    marginLeft: auto,
    marginRight: auto,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}));

import makeStyles from '@material-ui/core/styles/makeStyles';

import { center, flex, flexEnd, none } from 'constants/Jss';

export const useCardContainerStyles = makeStyles((theme) => ({
  NumberOfItems: {
    display: flex,
    justifyContent: flexEnd,
    alignItems: center,
    color: theme.palette.grey70,
    fontSize: 14,
    width: '100%',
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  ShowMoreBox: {
    width: '100%',
    display: flex,
    justifyContent: center,
  },
  ShowMoreButton: {
    height: 37,
  },
  NoResult: {},
}));

import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  absolute,
  auto,
  bold,
  center,
  column,
  flex,
  flexEnd,
  flexStart,
  none,
  noRepeat,
  nowrap,
  pointer,
  relative,
  spaceBetween,
  uppercase,
  wrap,
  transparent,
  lowercase,
  inherit,
  normal,
} from 'constants/Jss';

export const courseDetailPageStyles = (theme) => ({
  CourseDetailContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  TopContent: {
    color: theme.palette.grey70,
    fontSize: 14,
    fontWeight: bold,
    display: flex,
    justifyContent: flexEnd,
    alignItems: flexStart,
    [theme.breakpoints.up('md')]: {
      position: absolute,
      top: theme.spacing(1),
      right: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      alignItems: flexEnd,
      justifyContent: spaceBetween,
      '&.WithResult': {
        marginTop: theme.spacing(6),
      },
    },
  },
  CourseDetailPageTitle: {
    position: relative,
    [theme.breakpoints.down('sm')]: {
      '&.LoggedIn': {
        marginTop: theme.spacing(10),
        '&.WithResult': {
          marginTop: -theme.spacing(3.5),
        },
      },
    },
  },
  AddToFavourites: {
    display: flex,
    alignItems: center,
    cursor: pointer,
    '& svg': {
      width: 22.454,
      height: 19.584,
    },
    position: absolute,
    top: -theme.spacing(6),
    right: 0,
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2.5),
      top: -theme.spacing(7.75),
      '&.WithResult': {
        top: -theme.spacing(15.5),
      },
    },
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(1),
    },
  },
  HeartBox: {
    display: flex,
    alignItems: center,
  },
  ExamResultBox: {
    backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    '&.Success': {
      backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
    },
    backgroundPosition: 'calc(100% + 95px) -18px',
    backgroundSize: '212px 216px',
    backgroundRepeat: noRepeat,
    color: theme.palette.black,
    width: 103,
    height: 194,
    display: flex,
    flexDirection: column,
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.down('sm')]: {
      marginRight: -theme.spacing(2),
      marginTop: -theme.spacing(4),
      backgroundPosition: 'calc(100% + 75px) -18px',
      backgroundSize: '169px 172px',
      width: 82,
      height: 154,
    },
  },
  ExamDoneText: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    fontWeight: bold,
    textTransform: uppercase,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  ExamDoneValue: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: bold,
    lineHeight: 1.15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  WhiteWrapper: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    backgroundColor: theme.palette.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const useCourseDetailPageStyles = makeStyles((theme) => ({
  DescriptionContainer: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.greyF2,
    color: theme.palette.grey70,
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(8),
    },
  },
  Time: {
    display: flex,
    width: '100%',
    alignItems: center,
    justifyContent: center,
    [theme.breakpoints.up('md')]: {
      '&.Mobile': {
        display: none,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(.Mobile)': {
        display: none,
      },
    },
  },
  WhatToLearnContainer: {
    width: '100%',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.grey70}`,
    [theme.breakpoints.down('sm')]: {
      border: 0,
      padding: 0,
    },
  },
  WhatToLearnListItem: { paddingRight: theme.spacing(2) },
  WhatToLearnListItemIcon: { marginTop: 0 },
  NarrowBox: {
    lineHeight: '30px',
    color: theme.palette.grey70,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
  },
  IndicatorWrapper: {
    width: '100%',
    display: flex,
    flexWrap: wrap,
    justifyContent: center,
  },
  IndicatorContainer: {
    width: '100%',
    display: flex,
    justifyContent: center,
    fontSize: 21,
    fontWeight: bold,
    flexWrap: wrap,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  IconizedIndicator: {
    display: flex,
    alignItems: center,
    color: theme.palette.grey70,
  },
  TimeIndicator: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  XP: {
    color: theme.palette.cyan,
    whiteSpace: nowrap,
    [theme.breakpoints.up('sm')]: {
      '&.Xs': {
        display: none,
      },
    },
    [theme.breakpoints.down('xs')]: {
      '&.Xs': {
        fontSize: 21,
        fontWeight: bold,
      },
      '&:not(.Xs)': {
        display: none,
      },
    },
  },
  Patch: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  TopPatch: {
    marginTop: 58,
  },
  BottomPatch: {
    marginTop: 715,
  },
  LeftPatch: {
    left: 0,
  },
  RightPatch: {
    right: 0,
  },
  DesktopLessons: {
    [theme.breakpoints.down('sm')]: {
      display: none,
    },
  },
  MobileLessons: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  Triplet: {
    maxWidth: 950,
    marginLeft: auto,
    marginRight: auto,
  },
  SingleLessonContainer: {
    width: '100%',
    display: flex,
    justifyContent: center,
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% + ${theme.spacing(4)}px)`,
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  },
  DoubleLessonContainer: {
    width: '100%',
    display: flex,
    justifyContent: spaceBetween,
  },
  SingleBoxInDoubleContainer: {
    justifyContent: center,
  },
}));

export const useTagListStyles = makeStyles((theme) => ({
  TagListBox: {
    display: flex,
    alignItems: center,
    [theme.breakpoints.down('sm')]: {
      flexDirection: column,
      marginBottom: theme.spacing(6),
    },
  },
  TagListLabel: {
    fontSize: 16,
    fontWeight: bold,
    whiteSpace: nowrap,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
  TagsContainer: {
    display: flex,
    flexWrap: wrap,
    alignItems: center,
    lineHeight: normal,
  },
  Title: {
    width: 300,
  },
}));

import {
  absolute,
  auto,
  block,
  bold,
  center,
  fixed,
  flex,
  flexEnd,
  hidden,
  none,
  noRepeat,
  pointer,
  relative,
  transparent,
  underline,
  uppercase,
} from 'constants/Jss';

export const appStyles = (theme) => ({
  '@global': {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, input:-internal-autofill-selected': {
      '-webkit-box-shadow': '0 0 0 1000px white inset',
    },
    '@keyframes lds-dual-ring': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    '.fade': {
      '&.enter': {
        opacity: 0,
        position: relative,
        '&.enter-active': {
          position: relative,
          opacity: 1,
          transition: 'opacity 300ms ease-in-out',
        },
      },
      '&.exit': {
        opacity: 1,
        position: relative,
        '&.exit-active': {
          opacity: 0,
          position: absolute,
          top: 0,
          left: 0,
          transition: 'opacity 300ms ease-in-out',
        },
        '& .page-animation-exit-hidden': {
          visibility: hidden,
        },
      },
    },
    '.Page': {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.mixins.mobileHeader.height,
      },
      '& > div': {
        [theme.breakpoints.down('sm')]: {
          marginLeft: 0,
          marginRight: 0,
          width: '100%',
        },
      },
    },
    '.PageTitleContainer': {
      display: block,
      width: '100%',
    },
    '.TitleBorder': {
      width: 75,
      height: 4,
      backgroundColor: theme.palette.green,
      marginLeft: auto,
      marginRight: auto,
      '&.Cyan': {
        backgroundColor: theme.palette.cyan,
      },
    },
    '.Title': {
      fontFamily: 'Montserrat',
      fontSize: 32,
      fontWeight: 900,
      textAlign: center,
      paddingBottom: theme.spacing(1),
      maxWidth: 700,
      marginLeft: auto,
      marginRight: auto,
      color: theme.palette.blackTitle,
      [theme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    '.H1Style': {
      marginTop: 0,
      marginBottom: 0,
      lineHeight: 1.43,
      color: theme.palette.blackTitle,
    },
    '.Description': {
      color: theme.palette.grey70,
      lineHeight: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        lineHeight: '18px',
      },
    },
    '.Hidden': {
      display: none,
    },
    '.Bold': {
      fontWeight: bold,
    },
    '.CloseText': {
      fontFamily: 'Montserrat',
      fontWeight: bold,
      fontSize: 16,
      color: theme.palette.grey70,
      cursor: pointer,
    },
    '.Link': {
      color: theme.palette.cyan,
      fontWeight: bold,
      textDecoration: underline,
      cursor: pointer,
      '&:hover, &.active': {
        color: theme.palette.cyanHover,
      },
    },
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      fontSize: 45,
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
    a: {
      color: theme.palette.cyan,
      '&:hover': {
        color: theme.palette.cyanHover,
      },
    },
    '*': {
      '-webkit-tap-highlight-color': transparent,
      '-ms-touch-action': 'manipulation',
      'touch-action': 'manipulation',
    },
    '.FocusShown *:focus': {
      outlineColor: theme.palette.focusColor,
      outlineStyle: auto,
      outlineWidth: 1,
    },
    '.FocusHidden *:focus': {
      outline: none,
    },
    body: {
      margin: 0,
      padding: 0,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        height: `calc(100% - ${theme.mixins.mobileHeader.height}px)`,
      },
    },
  },
  CookieButton: {
    color: `${theme.palette.white} !important`,
    fontSize: '14px !important',
    borderRadius: '22px !important',
    padding: '12px 24px !important',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    textTransform: `${uppercase} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.hover} !important`,
    },
    '&:focus': {
      outline: 'none !important',
    },
  },
});

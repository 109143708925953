import request from 'utils/request';
import { Methods } from 'utils/http';

const BASE_URL = '/documents';

export const getDocuments = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}`,
  });

  return Promise.resolve({ termsAndConditions: {}, privacyPolicy: {} });
};

export const checkDocuments = async () => {
  return await request({
    method: Methods.GET,
    resource: `${BASE_URL}/check-new`,
  });
};

export const agreeDocuments = async (termsAndConditionsId, privacyPolicyId) => {
  return await request({
    method: Methods.PUT,
    resource: `${BASE_URL}/agree`,
    data: {
      termsAndConditionId: termsAndConditionsId,
      privacyId: privacyPolicyId,
    },
  });
};

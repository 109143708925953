import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';

import { Button } from 'components';
import { useHomeLearningButtonStyles } from './styles';

const HomeLearningButtons = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useHomeLearningButtonStyles();

  return (
    <Box className={classNames(classes.LearningButtonsBox)}>
      <Link to={`${history.location.pathname}?dialog=${t('url_register')}`}>
        <Button noTextTransform buttonClassName={classNames(classes.LearnButton, 'Large')}>
          {t('home_page_registration')}
        </Button>
      </Link>
    </Box>
  );
};
export default HomeLearningButtons;

import createStyles from '@material-ui/core/styles/createStyles';

import { noRepeat, scroll } from 'constants/Jss';
import Temple from 'assets/images/temple.png';
import AboutLeftPatch from 'assets/images/about-left-patch.png';
import AboutRightPatch from 'assets/images/about-right-patch.png';
import PlayPatch from 'assets/images/play-patch.png';

export const pageStyles = (theme) =>
  createStyles({
    MainContainer: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        '& > div > div': {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          maxWidth: '100%',
        },
      },
    },
    NoContainer: {
      padding: theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    HomePage: {
      ...theme.mixins.homeBackground,
      backgroundAttachment: scroll,
    },
    TopicsPage: {
      backgroundImage:
        `url(${Temple}),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(156,219,248)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(124,81,160)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>")`,
      backgroundPosition:
        '-220px max(100%, 984px), -281px -250px, calc(100% + 221px) 136px, -281px 518px, calc(100% + 221px) 950px',
      backgroundSize: '440px 440px, 500px 445.066px, 500px 445.066px, 500px 445.066px, 500px 445.066px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down('sm')]: {
        backgroundImage:
          `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>"), ` +
          `url(${Temple})`,
        backgroundPosition: `-57px -95px, -120px calc(100% - 125px)`,
        backgroundSize: '360px 365px, 240px 240px',
        backgroundRepeat: `${noRepeat}, ${noRepeat}`,
      },
    },
    CoursesListPage: {
      ...theme.mixins.commonBackground,
    },
    PathListPage: {
      ...theme.mixins.commonBackground,
    },
    PathDetailPage: {
      ...theme.mixins.commonBackground,
    },
    MyStudies: {
      ...theme.mixins.commonBackground,
    },
    PathEditorPage: {
      ...theme.mixins.commonBackground,
      display: 'flex',
      height: '100%',
      flexGrow: 1,
      flexDirection: 'column',
      '& .Page': {
        display: 'flex',
        height: '100%',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
    },
    CourseDetailPage: { ...theme.mixins.commonBackground },
    LearningPathDetailPage: { ...theme.mixins.commonBackground },
    ProfilePage: {
      ...theme.mixins.commonBackground,
    },
    LessonDetailPage: {
      ...theme.mixins.commonBackground,
    },
    SearchPage: {
      ...theme.mixins.commonBackground,
    },
    TopListPage: {
      ...theme.mixins.commonBackground,
    },
    NotFoundPage: {
      ...theme.mixins.commonBackground,
    },
    ExamsPage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 471.667 353.777'><g fill='rgb(135,206,220)' transform='translate(-708.661 -417.54)'><path d='M751.159,615.694A117.483,117.483,0,0,1,810.1,631.477,117.333,117.333,0,0,1,869.119,615.7,117.927,117.927,0,0,0,987.043,497.764a58.98,58.98,0,0,0-117.96,0,58.992,58.992,0,0,1-59,58.967,58.932,58.932,0,0,0-58.929,58.962' transform='translate(75.365 37.691)'/><path d='M772.413,631.464a117.333,117.333,0,0,1,59.014-15.778A117.924,117.924,0,0,0,949.351,497.757a58.961,58.961,0,0,1,117.921.006A117.928,117.928,0,0,1,949.351,615.69,117.919,117.919,0,0,0,831.428,733.613a117.856,117.856,0,0,0-59.014-102.149' transform='translate(113.056 37.704)'/></g></svg>")`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, -236px 458px',
      backgroundSize: '500px 445.066px, 500px 445.066px, 471.667px 353.777px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}`,
    },
    MyCoursesPage: {
      ...theme.mixins.commonBackground,
    },
    KnowledgeStorePage: {
      backgroundImage:
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(242,125,131)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(252,196,128)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 439.275 445.066'><path fill='rgb(185,217,136)' d='M382.327,376.595c0-61.448,49.2-111.273,109.838-111.273s109.812-49.806,109.812-111.26c0,61.454,49.167,111.26,109.819,111.26S821.6,315.147,821.6,376.588H821.5l.106.007c0,61.448-49.2,111.273-109.845,111.273s-109.805,49.8-109.805,111.26c0-61.461-49.174-111.26-109.819-111.26S382.327,438.042,382.327,376.6l.112-.007Z' transform='translate(-352.906 -136.942)'/></svg>"),` +
        `url(${PlayPatch})`,
      backgroundPosition: '-281px -250px, calc(100% + 221px) 136px, calc(100% + 221px) calc(100% - 20px), -221px 100%',
      backgroundSize: '500px 445.066px, 500px 445.066px, 500px 445.066px, 442px 442px',
      backgroundRepeat: `${noRepeat}, ${noRepeat}, ${noRepeat}, ${noRepeat}`,
      [theme.breakpoints.down('sm')]: {
        backgroundImage: `url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 360 365'><path fill='rgb(242,125,131)' d='M204.2,0c0,0,18,89.5-23,115s-81.6,2.2-111,61c-25.6,51.2-25,87.3-70,94.7C-0.2,163.6,0.1,0,0.1,0H204.2z'/></svg>")`,
        backgroundPosition: '-57px -95px',
        backgroundSize: '360px 365px',
        backgroundRepeat: noRepeat,
      },
    },
    AboutPage: {
      ...theme.mixins.commonBackground,
    },
  });

import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

import { center, ellipsis, hidden, lowercase, nowrap, uppercase } from 'constants/Jss';

export const useTagStyles = makeStyles((theme) => ({
  BasicTag: {
    textTransform: lowercase,
    color: theme.palette.grey70,
    fontSize: 12,
    fontFamily: 'WonderUnit',
    backgroundColor: theme.palette.greyF5,
    borderRadius: '11px',
    padding: '3px 10px',
    whiteSpace: nowrap,
    overflowX: hidden,
    textOverflow: ellipsis,
    textAlign: center,
    '&::first-letter': {
      textTransform: uppercase,
    },
  },
}));

const Tag = ({ tagTitle }) => {
  const classes = useTagStyles();
  return <Box className={classes.BasicTag}>{tagTitle}</Box>;
};

Tag.propTypes = {
  tagTitle: PropTypes.string.isRequired,
};

export default Tag;

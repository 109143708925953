import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import classNames from 'classnames';

import { Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Settings from 'env';
import ErrorMessage from './ErrorMessage';

import { useTextFieldStyles } from './styles';

const useStyles = makeStyles((theme) => {
  return {
    DatePicker: {
      '& .MuiPickersBasePicker-pickerView': {
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiIconButton-root': {
        background: 'transparent',
        padding: theme.spacing(1),
        '& svg': {
          fill: theme.palette.primary.main,
        },
      },
    },
    WhiteDatePicker: {
      '& .MuiIconButton-root': {
        '& svg': {
          fill: theme.palette.grey70,
        },
      },
    },
  };
});

const DatePickerField = ({
  form,
  edgy,
  white,
  field,
  fullWidth,
  showError = true,
  maxDate,
  readOnly,
  variant = 'outlined',
  ...props
}) => {
  const classes = useStyles();
  const textFieldClasses = useTextFieldStyles();
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(classes.DatePicker, textFieldClasses.BaseTextField, {
        [classes.WhiteDatePicker]: white,
        [textFieldClasses.WhiteTextField]: white,
        [textFieldClasses.EdgyTextField]: edgy,
      })}
    >
      <KeyboardDatePicker
        {...props}
        invalidDateMessage={t('invalid_date_format')}
        maxDateMessage={t('greater_than_max_date')}
        minDateMessage={t('lesser_than_min_date')}
        maxDate={maxDate}
        classes={{}}
        fullWidth={fullWidth}
        className={classNames(classes.DatePicker)}
        autoOk
        variant="inline"
        inputVariant="outlined"
        format={Settings.DATE_FORMAT}
        InputAdornmentProps={{ position: 'end' }}
        InputProps={{
          ...(props || {}),
          readOnly: (props || {}).readOnly || readOnly || false,
          className: classes.DatePicker,
        }}
        value={field.value}
        onChange={async (date) => {
          await form.setFieldValue(field.name, date);
          form.setFieldTouched(field.name, true);
        }}
        error={Boolean(form && field && get(form.errors, field.name) && get(form.touched, field.name))}
      />
      {showError && form && field ? (
        <ErrorMessage field={field} form={form} variant={variant} className={classes.error} />
      ) : null}
    </Box>
  );
};

DatePickerField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  showError: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default DatePickerField;

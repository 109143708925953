import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  auto,
  bold,
  breakWord,
  center,
  columnReverse,
  ellipsis,
  flex,
  flexEnd,
  grid,
  hidden,
  none,
  normal,
  pointer,
  relative,
  spaceBetween,
  underline,
  vertical,
} from 'constants/Jss';

export const useMobileFilterStyles = makeStyles((theme) => ({
  MobileFilter: {
    [theme.breakpoints.up('md')]: {
      display: none,
    },
  },
  CountIcon: { fontSize: 32 },
  CountText: { fontSize: 22, paddingLeft: theme.spacing(2), fontWeight: bold },
  FilterContainer: {
    display: flex,
    alignItems: center,
    justifyContent: flexEnd,
    [theme.breakpoints.down('sm')]: {
      justifyContent: spaceBetween,
      '&.Vertical': {
        flexDirection: columnReverse,
      },
    },
  },
  MobileFilterIcon: {
    cursor: pointer,
    position: relative,
  },
  FilterStrip: {
    height: 3,
    borderRadius: 2,
    backgroundColor: theme.palette.common.black,
  },
  TopStrip: {
    width: 21,
  },
  MiddleStrip: {
    width: 15,
  },
  BottomStrip: {
    width: 5,
  },
}));
export const useMobileFilterDialogStyles = makeStyles((theme) => ({
  Title: {
    fontSize: 24,
    fontWeight: normal,
    color: theme.palette.primary.main,
    padding: 0,
    lineHeight: `${theme.spacing(5)}px`,
  },
  TitleBox: {
    margin: 0,
  },
  Content: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  Filter: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(2),
    },
    '&:not(:first-child)': {
      paddingTop: theme.spacing(2),
    },
  },
  Header: {
    display: flex,
    alignItems: center,
    justifyContent: spaceBetween,
    cursor: pointer,
    '&.Open': {
      marginBottom: theme.spacing(2),
    },
  },
  Body: {
    maxHeight: 0,
    color: theme.palette.primary.main,
    overflow: hidden,
    transition: 'max-height ease-in-out 0.3s',
    '&.Open': {
      maxHeight: 500,
      overflow: auto,
      paddingLeft: theme.spacing(1.5),
    },
  },
  HeaderTitle: {
    color: theme.palette.primary.main,
  },
  ArrowIcon: {
    color: theme.palette.primary.main,
    '&.Open': {
      transform: 'rotate(180deg)',
    },
  },
  Checkbox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    '& svg': {
      fill: theme.palette.primary.main,
    },
    '&.Checked svg': {
      fill: theme.palette.primary.main,
    },
  },
  FilterButtonContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  MobileTagsContainer: {
    maxHeight: 300,
    overflowY: auto,
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 14,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: center,
    },
  },
  MobileInterestContainer: {
    display: flex,
    alignItems: center,
    justifyContent: center,
  },
  MobileFilterLabel: {
    paddingRight: theme.spacing(1.5),
    alignItems: center,
  },
  MobileRadioFilter: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
    '& .MuiFormControlLabel-root': {
      alignItems: center,
    },
  },
  MobileJobsTagContainer: {
    display: grid,
    'grid-template-columns': 'repeat(auto-fill, minmax(183px, 1fr))',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiFormControlLabel-label': {
      maxWidth: 160,
      fontSize: 14,
      wordBreak: breakWord,
      overflow: hidden,
      textOverflow: ellipsis,
      display: '-webkit-box',
      '-webkit-box-orient': vertical,
      '-webkit-line-clamp': 2,
    },
  },

  ClearBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
  InterestBox: {
    borderLeft: `1px solid ${theme.palette.grey64}`,
    paddingLeft: theme.spacing(3),
    display: flex,
    alignItems: center,
  },
  ActionText: {
    cursor: pointer,
    color: theme.palette.grey64,
    textDecoration: underline,
    fontSize: 14,
    lineHeight: 1,
  },
}));
